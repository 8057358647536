import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from '@mui/material'

import FormsName from '../../../components/imputs/forms-user-name'
import { CalendarInput } from '../../../components/imputs/forms-birth-date'
import { CIWithChange } from '../../../components/imputs/forms-citizens-id'
import { FormsPhoneNumber } from '../../../components/imputs/forms-phone-number'

// import { CaretDownIcon } from '../../../components/icons/CaretDown'
import { CaretUpIcon } from '../../../components/icons/CaretUp'
import { InfoCardWarning } from '../../../components/ui/Cards/Info'
// import { isValidPhoneNumber } from 'libphonenumber-js/mobile'

import dayjs from 'dayjs'

import './styles.passengers.css'

const AccordionMain = styled(Accordion)({
  borderRadius: '12px !important',
})

const AccordionSummaryMain = styled(AccordionSummary)({
  // '.MuiButtonBase-root-MuiAccordionSummary-root .Mui-expanded': {
  //   height: '40px',
  //   paddingTop: '12px',
  // },

  '.MuiAccordionSummary-gutters .Mui-expanded': {
    height: '40px',
    paddingTop: '12px',
  },
  '.MuiAccordionSummary-root .Mui-expanded': {
    height: '40px',
    paddingTop: '12px',
  },

  // '.Mui-expanded': {
  //   height: '40px',
  //   paddingTop: '12px',
  // },
})

const AccordionDetailsMain = styled(AccordionDetails)({
  paddingTop: '0px',
})

export const PassengersItem = ({
  watch,
  register,
  setValue,
  errors,
  control,
  setIsDisabled,
  ownerTravel,
  setOwnerTravel,
  passenger,
  localPassengers,
  setLocalPassengers,
  setTouchableId,
}) => {
  // const [showContent, setShowContent] = useState(false)
  const [isSeatCheck, setSeatCheck] = useState()
  const [isCheck, setCheck] = useState('me')

  const [isAfterTwelve, setIsAfterTwelve] = useState()
  const [isAfterEightTeen, setIsAfterEightTeen] = useState()

  const [expanded, setExpanded] = useState(() =>
    passenger.id === 1 ? true : false,
  )

  // const firstname = watch(`name${passenger.id}`)
  // const lastname = watch(`lastname${passenger.id}`)
  // const phone = watch(`phone${passenger.id}`)
  // const codePhone = watch(`code${passenger.id}`)
  // const document = watch(`ci${passenger.id}`)
  const typeDocument = watch(`ciCode${passenger.id}`)
  const dob = watch(`date${passenger.id}`)

  // TRIGGER AFTER THE INPUT CHANGE AND IT LOOKS IF ANY OF THEM ARE DIRTY
  useEffect(() => {
    const passengers = localPassengers
      .map((pass) => {
        const document =
          pass.type === 'child' ||
          (pass.type === 'general' && pass.isTwelve === false)
            ? undefined
            : pass.document

        const phone =
          pass.type === 'child' ||
          (pass.type === 'general' && pass.isEightTeen === false)
            ? undefined
            : pass.phone

        const dobs = pass.dob === '' ? dob : pass.dob

        return { ...pass, document, phone, dob: dobs }
      })
      .slice(ownerTravel ? 1 : 0)

    const isName = passengers.some(({ firstname }) => firstname === '')
    const isLastname = passengers.some(({ lastname }) => lastname === '')
    const isPhoneCode = passengers.some(({ codePhone }) => codePhone === '')
    const isCi = passengers.some(({ document }) => document === '')
    const isCiCode = passengers.some(({ typeDocument }) => typeDocument === '')
    const isDate = passengers.some(({ dob }) => dob === '')
    const isPhone = passengers.some(({ phone }) => phone === '')

    setIsDisabled(
      isLastname ||
        isName ||
        isDate ||
        isCi ||
        isCiCode ||
        isPhoneCode ||
        isPhone,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localPassengers,
    isSeatCheck,
    isAfterTwelve,
    isAfterEightTeen,
    dob,
    ownerTravel,
  ])

  // CHECK THE USE AGE
  useEffect(() => {
    if (dob) {
      // console.log({ dob })
      const eightTeenYearsOld = dayjs().subtract(18, 'years')
      const twelveYearsOld = dayjs().subtract(12, 'years')

      setIsAfterTwelve(twelveYearsOld?.isAfter(dob))
      setIsAfterEightTeen(eightTeenYearsOld?.isAfter(dob))
    }
  }, [dob])

  // SET VALUE WHEN YOU CON BACK
  useEffect(() => {
    let currentPass = localPassengers.map((pass) => {
      const document =
        pass.type === 'child' ||
        (pass.type === 'general' && pass.isTwelve === false)
          ? undefined
          : pass.document

      const phone =
        pass.type === 'child' ||
        (pass.type === 'general' && pass.isEightTeen === false)
          ? undefined
          : pass.phone

      const dobs = pass.dob === '' ? dob : pass.dob

      return { ...pass, document, phone, dob: dobs }
    })

    currentPass = currentPass.find(({ id }) => id === passenger.id)

    if (currentPass.firstname && currentPass.lastname && currentPass.dob) {
      setValue(`name${passenger.id}`, currentPass.firstname)
      setValue(`lastname${passenger.id}`, currentPass.lastname)
      setValue(`date${passenger.id}`, currentPass.dob)

      if (currentPass.isTwelve) {
        setValue(`ci${passenger.id}`, currentPass.document)
      }

      if (currentPass.isEightTeen) {
        setValue(`phone${passenger.id}`, currentPass.phone)
      }
    }
  }, [localPassengers])

  // SET TYPE VALUE
  useEffect(() => {
    setValue(`type${passenger.id}`, passenger.type)
  }, [passenger.type])

  // SET OWNER VALUE
  useEffect(() => {
    if (isCheck === 'me') {
      setOwnerTravel(true)
    } else {
      setOwnerTravel(false)
    }
  }, [isCheck])

  useEffect(() => {
    if (passenger.id === 1 && passenger.type === 'general') {
      const eightTeenYearsOld = dayjs().subtract(18, 'years')

      setValue(`date${passenger.id}`, eightTeenYearsOld)
    }
  }, [passenger])

  const handleInputChange = (value, id, field) => {
    const updatedPassengers = localPassengers.map((passenger) => {
      if ((id > 1 || !ownerTravel) && passenger.id === id) {
        const eightTeenYearsOld = dayjs().subtract(18, 'years')
        const twelveYearsOld = dayjs().subtract(12, 'years')

        const isTwelve = twelveYearsOld?.isAfter(field === 'dob' ? value : dob)

        const isEightTeen = eightTeenYearsOld?.isAfter(
          field === 'dob' ? value : dob,
        )
        // console.log({ id, field, value })

        return {
          ...passenger,
          isTwelve,
          isEightTeen,
          isMain: id === 1 && isEightTeen,
          [field]: value,
        }
      }

      return passenger
    })

    setLocalPassengers(updatedPassengers)
  }

  return (
    <>
      <AccordionMain
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded)
        }}>
        <AccordionSummaryMain
          expandIcon={<CaretUpIcon />}
          aria-controls='passengers-content'
          id='passengers-header'>
          <h3 className='passenger-item_title'>{passenger.title}</h3>
        </AccordionSummaryMain>

        <AccordionDetailsMain>
          {passenger.id === 1 && passenger.type === 'general' && (
            <section className='passager-item_type_container'>
              <div className='item_detail' onClick={() => setCheck('me')}>
                <label className='item_label'>Yo</label>

                <input
                  type='radio'
                  value='me'
                  checked={isCheck === 'me'}
                  defaultChecked={false}
                />
              </div>

              <div className='item_detail' onClick={() => setCheck('other')}>
                <label className='item_label'>Otra persona</label>

                <input
                  type='radio'
                  value='other'
                  checked={isCheck === 'other'}
                  defaultChecked={false}
                />
              </div>
            </section>
          )}

          <section className='passage_type_form_container'>
            {(passenger.id !== 1 ||
              passenger.type !== 'general' ||
              !ownerTravel) && (
              <>
                <FormsName
                  registerName={{
                    ...register(`name${passenger.id}`, {
                      required: 'Este campo es requerido',
                      minLength: {
                        value: 3,
                        message: 'Mínimo 3 carácteres',
                      },
                      pattern: {
                        value:
                          /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
                        message: 'Solo letras',
                      },
                      onChange: (e) =>
                        handleInputChange(
                          e.target.value,
                          passenger.id,
                          'firstname',
                        ),
                    }),
                  }}
                  registerLastname={{
                    ...register(`lastname${passenger.id}`, {
                      required: 'Este campo es requerido',
                      minLength: {
                        value: 3,
                        message: 'Mínimo 3 carácteres',
                      },
                      pattern: {
                        value:
                          /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
                        message: 'Solo letras',
                      },
                      onChange: (e) =>
                        handleInputChange(
                          e.target.value,
                          passenger.id,
                          'lastname',
                        ),
                    }),
                  }}
                  errorName={errors[`name${passenger.id}`]?.message}
                  errorLastname={errors[`lastname${passenger.id}`]?.message}
                />

                <CalendarInput
                  setValue={(value) => {
                    setValue(`date${passenger.id}`, value)
                    handleInputChange(value, passenger.id, 'dob')
                  }}
                  id={`date${passenger.id}`}
                  max={
                    passenger.type === 'child'
                      ? 'today'
                      : // : passenger.type === 'general' && passenger.id === 1
                      // ? '18'
                      passenger.type === 'general'
                      ? '4'
                      : passenger.type === 'elder'
                      ? '60'
                      : null
                  }
                  min={
                    passenger.type === 'child'
                      ? '4'
                      : passenger.type === 'general' && passenger.id !== 1
                      ? '60'
                      : null
                  }
                  error={errors[`date${passenger.id}`]?.message}
                  control={control}
                />

                {passenger.type !== 'child' &&
                  (passenger.type === 'elder' || isAfterTwelve) && (
                    <CIWithChange
                      setValue={setValue}
                      handleChangeId={passenger.id}
                      ciName={`ci${passenger.id}`}
                      codeId={`ciCode${passenger.id}`}
                      control={control}
                      getValue={typeDocument}
                      handleInputChange={handleInputChange}
                    />
                  )}

                {passenger.type !== 'child' &&
                  (passenger.type === 'elder' || isAfterEightTeen) && (
                    <FormsPhoneNumber
                      registerCode={{
                        ...register(`code${passenger.id}`, {
                          required: false,
                          onChange: (e) =>
                            handleInputChange(
                              e.target.value,
                              passenger.id,
                              'codePhone',
                            ),
                        }),
                      }}
                      registerValidation={{
                        ...register(`phone${passenger.id}`, {
                          required: 'Este campo es requerido',
                          // maxLength: {
                          //   value: 7,
                          //   message: 'Deben ser 7 digitos',
                          // },
                          minLength: {
                            value: 7,
                            message: 'Deben ser 7 digitos',
                          },
                          valueAsNumber: true,
                          // validate: (val) => {
                          //   return (
                          //     isValidPhoneNumber(`${codePhoneWatch}${val}`) ||
                          //     'Número no valido'
                          //   )
                          // },
                          onChange: (e) =>
                            handleInputChange(
                              e.target.value,
                              passenger.id,
                              'phone',
                            ),
                        }),
                      }}
                      codeId={`code${passenger.id}`}
                      error={errors[`phone${passenger.id}`]?.message}
                      setValue={setValue}
                    />
                  )}

                {passenger.type === 'child' && (
                  <div className='child-seat_container'>
                    <h3 className='child-seat_title'>
                      ¿Deseas un asiento para este pasajero?
                    </h3>

                    <section className='passager-item_type_container'>
                      <div
                        className='item_detail'
                        onClick={() => {
                          setSeatCheck('si')
                          setValue(`seatChild${passenger.id}`, 'si')
                          setTouchableId(passenger.id)
                        }}>
                        <label className='item_label'>Sí</label>

                        <input
                          {...register(`seatChild${passenger.id}`, {
                            required: 'Este campo es requerido',
                          })}
                          type='radio'
                          value='si'
                          checked={isSeatCheck === 'si'}
                          defaultChecked={false}
                          onChange={() =>
                            setValue(`seatChild${passenger.id}`, 'si')
                          }
                        />
                      </div>

                      <div
                        className='item_detail'
                        onClick={() => {
                          setSeatCheck('no')
                          setValue(`seatChild${passenger.id}`, 'no')
                          setTouchableId(passenger.id)
                        }}>
                        <label className='item_label'>No</label>

                        <input
                          {...register(`seatChild${passenger.id}`, {
                            required: 'Este campo es requerido',
                          })}
                          type='radio'
                          value='no'
                          checked={isSeatCheck === 'no'}
                          defaultChecked={false}
                          onChange={() =>
                            setValue(`seatChild${passenger.id}`, 'no')
                          }
                        />
                      </div>
                    </section>
                    {errors[`seatChild${passenger.id}`]?.message && (
                      <p className='error_message'>
                        {errors[`seatChild${passenger.id}`]?.message}
                      </p>
                    )}

                    {passenger.type === 'child' && (
                      <InfoCardWarning title='La asignación de butaca tiene costo de boleto general' />
                    )}
                  </div>
                )}
              </>
            )}

            <div>
              <p className='docs_present_text'>Documentos a presentar:</p>
              {passenger.type !== 'child' &&
                (isAfterTwelve || passenger.id === 1) && (
                  <h3 className='original_dni'>Cédula de identidad original</h3>
                )}
              {(passenger.type === 'child' || passenger.type === 'general') &&
                !isAfterTwelve && (
                  <>
                    <h4 className='original_dni'>Partida de nacimiento</h4>
                    <h4 className='original_dni'>
                      Documentación y permisos de viaje del CPNNA
                    </h4>
                  </>
                )}
            </div>
          </section>
        </AccordionDetailsMain>
      </AccordionMain>
    </>
  )
}
