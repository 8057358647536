export const SeatsFill = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='13'
      viewBox='0 0 11 14'
      fill='none'>
      <path
        d='M10.708 0.354844C10.4998 0.126052 10.2127 0 9.89955 0H9.03883C8.4045 0 7.7991 0.521106 7.68946 1.16168L7.63518 1.47847C7.58061 1.79724 7.65405 2.09917 7.84202 2.32866C8.02998 2.55815 8.3073 2.6845 8.62299 2.6845H9.73483C9.88909 2.6845 10.0383 2.65291 10.1769 2.59685L10.0588 3.63189C9.94502 3.58288 9.82014 3.55678 9.6889 3.55678H8.42036C7.87692 3.55678 7.34314 4.0002 7.23037 4.5452L6.10881 9.96647L0.817112 9.5766C0.531153 9.55562 0.292427 9.65498 0.144417 9.85009C0.0057855 10.0328 -0.034741 10.278 0.0302265 10.5405L0.645457 13.0241C0.778745 13.5622 1.32776 14 1.86921 14H6.30547H6.37317H8.63135C9.18002 14 9.67355 13.5448 9.73142 12.9854L10.5995 4.59468C10.6044 4.54671 10.605 4.49945 10.6034 4.45266L10.9005 1.85074C10.9424 1.73867 10.9691 1.61956 10.9775 1.49601L10.9971 1.20638C11.0189 0.886091 10.9162 0.583695 10.708 0.354844Z'
        fill='#29348F'
      />
    </svg>
  )
}
export const SeatsOutLine = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 9 10'
      fill='none'>
      <path
        d='M8.28992 3.04416L8.08905 2.96859C8.0426 2.95112 7.98828 2.94055 7.92728 2.94055H6.88938C6.6011 2.94055 6.34815 3.15671 6.30497 3.33881C6.30497 3.33881 6.30497 3.33882 6.30497 3.33882L5.38734 7.21114L5.30946 7.53976L4.97244 7.51808L0.64298 7.23961C0.500605 7.23049 0.439887 7.27537 0.4201 7.29814L8.28992 3.04416ZM8.28992 3.04416L8.27888 3.12859L8.27449 3.16218L8.2758 3.19604C8.27656 3.21546 8.27596 3.22725 8.27507 3.23484C8.27507 3.23489 8.27506 3.23495 8.27505 3.235L7.56485 9.22818L7.56485 9.22819C7.54531 9.39307 7.34376 9.6 7.06201 9.6H5.21441H5.15902H1.52935C1.39896 9.6 1.25442 9.55251 1.13204 9.46731C1.00799 9.38094 0.93666 9.27744 0.91291 9.19374L0.409563 7.41981C0.387725 7.34279 0.407687 7.31242 0.420095 7.29814L8.28992 3.04416ZM8.52196 1.27008L8.5094 1.36615L8.19341 1.47771C8.1204 1.50348 8.04328 1.5175 7.96486 1.5175H7.05517C6.88932 1.5175 6.77455 1.46068 6.70791 1.38965C6.64552 1.32315 6.61867 1.23931 6.63947 1.13309C6.63948 1.13306 6.63948 1.13304 6.63949 1.13301L6.68389 0.906809L6.6839 0.90673C6.73111 0.665932 7.03638 0.4 7.3954 0.4H8.09963C8.26225 0.4 8.39496 0.456512 8.48417 0.542074C8.57101 0.625406 8.60708 0.725247 8.59886 0.830568L8.59885 0.830681L8.58276 1.03756L8.58275 1.03768C8.57947 1.08 8.56893 1.1224 8.55095 1.16433L8.52914 1.2152L8.52196 1.27008Z'
        stroke='#29348F'
        strokeWidth='0.8'
      />
    </svg>
  )
}
