import React from 'react'

export const MobileIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='28'
      viewBox='0 0 22 28'
      fill='none'>
      <path
        d='M18.4526 22.9091H3.63116V5.09091H18.4526M18.4526 0H3.63116C1.98598 0 0.66687 1.13273 0.66687 2.54545V25.4545C0.66687 26.1296 0.979178 26.7771 1.53509 27.2545C2.091 27.7318 2.84498 28 3.63116 28H18.4526C19.2388 28 19.9927 27.7318 20.5486 27.2545C21.1046 26.7771 21.4169 26.1296 21.4169 25.4545V2.54545C21.4169 1.87036 21.1046 1.22291 20.5486 0.745546C19.9927 0.268181 19.2388 0 18.4526 0Z'
        fill='#29348F'
      />
    </svg>
  )
}
