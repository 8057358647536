import { busiAPi } from '../../../utils/urls'

import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'

import { get } from '../../../api/axiosHelper'
import './styles.banks.css'

const animatedComponents = makeAnimated()

export const SelectBanksStyles = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
    boxShadow: 'none',
    outline: 'none',
    zIndex: '20px',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #29348F',
    boxShadow: 'none',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    height: '32px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    margin: 0,
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: '2px',
    color: '#29348F',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: '#29348F',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    zIndex: 66,
    // position: 'relative',
    // display: 'inline',
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    color: '#29348F',
    zIndex: 66,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: '#29348F',
  }),
}

const options = [
  { value: '0102', label: '0102-Banco de Venezuela' },
  // { value: '0114', label: '0114-Bancaribe' },
  // { value: '0169', label: '0169-Mi Banco' },
  { value: '0163', label: '0163-Banco del Tesoro' },
  { value: '0175', label: '0175-Banco Bicentenario' },
  { value: '0168', label: '0168-Bancrecer' },
  { value: '0177', label: '0177-Banfanb' },
  { value: '0172', label: '0172-Bancamiga' },
]

export function BanksSelect({
  registerValidation,
  setValue,
  sendCode = false,
  type = 'mobile',
}) {
  const PromiseData = async () => {
    const info = await get(`${busiAPi}/setting/banks`)

    const data = info.data.map(({ bankName, bankCode }) => {
      return sendCode
        ? { value: bankCode, label: `${bankCode}-${bankName}` }
        : { value: bankName, label: `${bankCode}-${bankName}` }
    })

    if (data.length > 0) {
      localStorage.setItem('banks', JSON.stringify(data))
      return data
    } else {
      let banks = localStorage.getItem('banks')
      banks = banks ? JSON.parse(banks) : []
      console.log({ banks })
      return banks ?? []
    }
  }

  return (
    <>
      {type === 'debito' ? (
        <Select
          {...registerValidation}
          placeholder='Seleccionar Banco'
          options={options}
          components={animatedComponents}
          styles={SelectBanksStyles}
          onChange={(data) => {
            setValue('banks', data.value)
          }}
        />
      ) : (
        <AsyncSelect
          {...registerValidation}
          placeholder='Seleccionar Banco'
          cacheOptions
          defaultOptions
          loadOptions={PromiseData}
          isSearchable={false}
          components={animatedComponents}
          styles={SelectBanksStyles}
          onChange={(data) => {
            setValue('banks', data.value)
          }}
        />
      )}
    </>
  )
}
