import { Box } from '@mui/material'

export const Card = ({ children, style = {} }) => {
  return (
    <Box
      style={{ borderRadius: 12, background: '#FFF', padding: 20, ...style }}>
      {children}
    </Box>
  )
}
