import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ArrowBack } from '../../icons/ArrowBack'
import { ButtonBlue } from '../../buttons'
import { SendPaymentAlert } from '../../bottom-panel/Alert/SendPayment'
import { busiAPi } from '../../../utils/urls'

import {
  MethodPaymentCard,
  GetPaymentDataTerminalCash,
} from '../../ui/Cards/PagoMovil'

import { useStore, useAmountsStorage } from '../../../store'

import axios from 'axios'
import { Box, useMediaQuery } from '@mui/material'
import { Capacitor } from '@capacitor/core'
import { Card } from '../../ui/Cards'
import { ImportantIcon } from '../../icons/ImportantIcon'

// import { Alert } from '../../bottom-panel/Alert'
// import { ContactLine } from '../../buttons/ContactLine'
import './styles.terminal.css'

export const PagoTerminalCash = () => {
  const { unregPassengersData, selectedTrip, ownerTravel } = useStore()
  const { totalUsd } = useAmountsStorage()
  const minW800 = useMediaQuery('(min-width:800px)')

  const navegate = useNavigate()
  const { state } = useLocation()
  // const { paymentType } = useParams()
  const [ticket, setTicket] = useState()

  // const isReserve = useMemo(() => paymentType === 'reserve', [paymentType])

  const [page, setPage] = useState('info')
  const [show, setShow] = useState(false)
  // const [showPayment, setShowPayment] = useState(false)

  const [disabled, setDisabled] = useState(true)
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  //region INPUTS SETTINGS
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const currencyWatch = watch('currency')

  const amountWatch = watch('amount')
  // const codeWatch = watch('ciCode')

  useEffect(() => {
    const isCurrency = currencyWatch !== undefined && currencyWatch !== ''

    const isAmount = amountWatch !== undefined && amountWatch !== ''

    if (isCurrency && isAmount) {
      setDisabled(false)
      return
    }

    setDisabled(true)
  }, [currencyWatch, amountWatch, page])

  useEffect(() => {
    if (state?.inComingPage) {
      setPage(state?.inComingPage)
    }
  }, [state?.inComingPage])

  // region FUNCTIONS
  const goBack = () => {
    if (page === 'info') {
      navegate('/main/payment', {
        state: { previousPath: state?.previousPath },
      })
    }

    // if (page === 'verification') {
    //   navegate(state?.previousPath || '/main/ticket')
    // }

    // if (page === 'code') {
    //   setPage('verification')
    // }
  }

  // region Submit
  const onSubmit = async (data) => {
    const paymentData = {
      amount: data.amount,
      type:
        data?.currency?.value === 'USD'
          ? 'cash_usd'
          : data?.currency?.value === 'COP'
          ? 'cash_cop'
          : 'cash_bs',
    }

    const passengers = unregPassengersData

    const fullBody = {
      routeId: selectedTrip?.routeId,
      subRouteId: selectedTrip?.subRouteId,
      isPassengerTraveling: ownerTravel,
      unregisterPassengers: passengers,
      paymentData,
      date: selectedTrip?.dateServer,
      currency: 'Bs',
      isBooking: false,
      price: totalUsd - selectedTrip?.busStationExitRate * passengers?.length,
      paidAmount: totalUsd,
      platform: Capacitor.getPlatform(),
    }

    try {
      setLoading(true)
      const { data: info } = await axios.post(
        `${busiAPi}/ticket`,
        { ...fullBody },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setLoading(false)

        setTicket(info?.data)

        setShow(true)
      } else {
        setLoading(false)
        setErr('Tenemos un problema, intenta más tarde')
      }
    } catch (error) {
      setLoading(false)
      const errMain = error?.response?.data?.error
      console.log({ errMain })
      if (errMain?.errorCode === 1103) {
        setErr(errMain?.message)
        return
      }
      setErr('Tenemos un problema, intenta más tarde')
    }
  }

  return (
    <section
      className='payment_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }
      }>
      <div
        onClick={goBack}
        className='payment-header_container'
        style={{ maxWidth: '600px', width: '100%' }}>
        <ArrowBack />

        <h2>
          {page === 'info' ? 'Datos para el pago' : 'Datos para verificación'}
        </h2>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: 'calc(100% - 53px)',
          maxWidth: '600px',
          width: '100%',
        }}>
        <section
          className='payment-body_container'
          style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {page === 'info' && (
            <>
              <MethodPaymentCard title='Efectivo en terminal' />

              <Card>
                <h3>Monto a pagar</h3>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <p className='amount_terminal'>USD {totalUsd}</p>
                  <p className='amount_terminal'>
                    COP {selectedTrip?.priceOptions?.exchangeRate?.cop}
                  </p>
                  <p className='amount_terminal'>
                    BS {selectedTrip?.priceOptions?.exchangeRate?.ves}
                  </p>
                </div>
              </Card>

              <Card>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    marginBottom: '6px',
                  }}>
                  <ImportantIcon width={20} height={20} />
                  <h3 style={{ fontSize: 16, fontWeight: '700' }}>
                    ¡Importante!
                  </h3>
                </Box>

                <p style={{ fontSize: 12 }}>
                  Deberás pagar en efectivo a los{' '}
                  <b>
                    promotores BUSI en el terminal de San Cristobal entre las 8
                    AM y las 5 PM
                  </b>{' '}
                  para asegurar tu boleto.
                </p>
              </Card>

              <GetPaymentDataTerminalCash
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
              />
            </>
          )}
        </section>

        <div
          className='action_button_container'
          style={{ flexDirection: 'column', gap: '10px' }}>
          {err && <p className='error_message'>{err}</p>}
          <ButtonBlue type='submit' disabled={loading || disabled}>
            Reservar boleto
          </ButtonBlue>
        </div>
      </form>

      {state?.inComingPage !== 'verification' && (
        <SendPaymentAlert
          isShow={show}
          setShow={setShow}
          type={'terminal_cash'}
          id={ticket?.id}
          keepOpen
        />
      )}
    </section>
  )
}
