// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name {
  display: flex;
  gap: 1rem;
  /* margin-top: 1rem; */
}

.form-fullname_name {
  color: #29348f;
  font-family: Kufam;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.form-fullname_lastname {
  color: #29348f;
  font-family: Kufam;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-user-name/styles.username.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B","sourcesContent":[".name {\n  display: flex;\n  gap: 1rem;\n  /* margin-top: 1rem; */\n}\n\n.form-fullname_name {\n  color: #29348f;\n  font-family: Kufam;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}\n\n.form-fullname_lastname {\n  color: #29348f;\n  font-family: Kufam;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
