// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #29348f;
  padding: 2px 5px;

  &.check {
    background: #29348f;
    & label {
      color: #fff;
      font-family: Kufam;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 157%;
      height: 18px;
    }
  }

  & label {
    color: #29348f;
    font-family: Kufam;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 157%; /* 21.98px */
    height: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Checkbox/styles.check.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;;EAEhB;IACE,mBAAmB;IACnB;MACE,WAAW;MACX,kBAAkB;MAClB,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,iBAAiB;MACjB,YAAY;IACd;EACF;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,YAAY;IAC/B,YAAY;EACd;AACF","sourcesContent":[".checkbox_container {\n  display: flex;\n  align-items: center;\n  border-radius: 5px;\n  border: 1px solid #29348f;\n  padding: 2px 5px;\n\n  &.check {\n    background: #29348f;\n    & label {\n      color: #fff;\n      font-family: Kufam;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 157%;\n      height: 18px;\n    }\n  }\n\n  & label {\n    color: #29348f;\n    font-family: Kufam;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 157%; /* 21.98px */\n    height: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
