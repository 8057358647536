import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ButtonBlue } from '../../components/buttons'
import { ArrowBack } from '../../components/icons/ArrowBack'

import { useStore, usePersistStore, useMeasureStorage } from '../../store'

import { PassengersItem } from './PassengersItems'
import { DetailReviewCards } from './DetailReview'

import { ErrorPage } from '../../components/Error'

import { Capacitor } from '@capacitor/core'
import { Box, useMediaQuery } from '@mui/material'
import './styles.buydetail.css'
import { localUsdRate } from '../../utils/usdRate'
import { priceOptions } from '../../hooks/useTrips'

const BuyTicketDetail = () => {
  const { ticketId } = useParams()
  const minW800 = useMediaQuery('(min-width:800px)')

  const { userData } = usePersistStore()
  const {
    passengers,
    trips,
    setUnregPassengersData,
    selectedTrip,
    setSelectedTrip,
    setOwnerTravel,
    ownerTravel,
  } = useStore()

  const navigate = useNavigate()
  const { state } = useLocation()
  const { headerHeight, mainHeight, footerHeight } = useMeasureStorage()

  const [isDisabled, setIsDisabled] = useState(true)
  const [localPassenger, setLocalPassengers] = useState(passengers.data)
  const [touchableId, setTouchableId] = useState(null)

  const [page, setPage] = useState('info')
  const [totalUsd, setTotalUsd] = useState(0)
  const [totalBs, setTotalBs] = useState(0)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm()
  const isChildSeated = watch(`seatChild${touchableId}`)

  useEffect(() => {
    const tkt = trips.find(({ subRouteId }) => subRouteId === ticketId)

    setSelectedTrip(tkt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId])

  useEffect(() => {
    const getPriceOptions = async () => {
      const { info } = await priceOptions({
        subRouteId: ticketId,
        amount: passengers?.total,
        elderly: passengers?.elder,
        underage: passengers?.child,
        pet: passengers?.pet,
        disabled: passengers?.disabled,
      })

      setTotalUsd(info?.data?.paidAmount)
    }

    getPriceOptions()
  }, [
    passengers?.child,
    passengers?.disabled,
    passengers?.elder,
    passengers?.pet,
    passengers?.total,
    ticketId,
  ])

  useEffect(() => {
    if (isChildSeated) {
      const getPriceOptions = async () => {
        try {
          const { info } = await priceOptions({
            subRouteId: ticketId,
            amount: passengers?.total,
            elderly: passengers?.elder,
            underage: isChildSeated === 'si' ? 0 : passengers?.child,
            pet: passengers?.pet,
            disabled: passengers?.disabled,
          })

          setTotalUsd(info?.data?.paidAmount)
          setSelectedTrip({ ...selectedTrip, priceOptions: info?.data })
        } catch (error) {
          console.log({ error })
        }
      }

      getPriceOptions()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [isChildSeated])

  useEffect(() => {
    const usdRate = localStorage.getItem('usdRate')
    const usdValue = usdRate ? usdRate : localUsdRate

    const totalBs = Number(usdValue) * totalUsd

    setTotalBs(totalBs)
  }, [totalUsd])

  //region FUNTIONS
  const onSubmit = (data) => {
    let unregisterPassengers = []
    const isGeneral = passengers.general > 0

    // const isExit =
    //   Number(selectedTrip?.busStationExitRate) === 0
    //     ? 0
    //     : Number(Number(selectedTrip?.busStationExitRate).toFixed(2))

    const mainGeneralPassenger = ownerTravel
      ? {
          firstName: userData.name,
          lastName: userData.lastName,
          documentId: userData.ci,
          dob: userData.bornDate,
          phoneNumber: userData.phone,
          type: 'general',
          isUnderageSeated: true,
          ticketPrice: selectedTrip?.priceOptions?.paidAmount,
          isMain: true,
        }
      : {
          firstName: data[`name${1}`],
          lastName: data[`lastname${1}`],
          documentId: `${data[`ciCode${1}`]}-${data[`ci${1}`]}`,
          dob: data[`date${1}`],
          phoneNumber: `${data[`code${1}`]}${data[`phone${1}`]}`,
          type: 'general',
          isUnderageSeated: true,
          ticketPrice: selectedTrip?.priceOptions?.paidAmount,
          isMain: localPassenger[0].isEighTeen ? true : false,
        }

    if (isGeneral) {
      unregisterPassengers.push(mainGeneralPassenger)
    }

    localPassenger.forEach((value, index) => {
      const i = index + 1

      if (i === 1 && isGeneral) return

      const firstName = data[`name${i}`]
      const lastName = data[`lastname${i}`]
      const documentId = data[`ci${i}`]
      const ciCode = data[`ciCode${i}`]
      const dob = data[`date${i}`]
      const phoneNumber = data[`phone${i}`]
      const code = data[`code${i}`]
      const isSeated = data[`seatChild${i}`]
      const type = data[`type${i}`]

      const prevMain = unregisterPassengers[index - 1]?.isMain

      const passengerItem = {
        firstName: firstName?.trim(),
        lastName: lastName?.trim(),
        documentId: documentId ? `${ciCode}-${documentId}` : undefined,
        dob: dob?.format('MM/DD/YYYY'),
        // phoneNumber: phoneNumber ? `58-${code}-${phoneNumber}` : undefined,
        phoneNumber: phoneNumber ? `${code}-${phoneNumber}` : undefined,
        isUnderageSeated: isSeated === 'no' ? false : true,
        type:
          type === 'general'
            ? 'general'
            : type === 'elder'
            ? 'senior'
            : 'underage',
        ticketPrice:
          type === 'child'
            ? isSeated === 'no'
              ? 0
              : selectedTrip?.priceOptions?.paidAmount
            : type === 'elder'
            ? selectedTrip?.priceOptions?.elderlyPrice //(selectedTrip.priceOptions?.paidAmount * 50) / 100
            : selectedTrip?.priceOptions?.paidAmount,
        isMain:
          unregisterPassengers.length === 0
            ? true
            : value.isEightTeen && prevMain === false
            ? true
            : false,
      }

      console.log({ passengerItem })
      if (isGeneral) {
        if (index > 0) {
          unregisterPassengers.push(passengerItem)
        }
      } else {
        unregisterPassengers.push(passengerItem)
      }
    })

    setUnregPassengersData(unregisterPassengers)
    setPage('detail')
  }

  const goBack = () => {
    if (page === 'info') {
      navigate('/main/buy', { state: { previousPath: state?.previousPath } })
    } else {
      setPage('info')
    }
  }

  if (!passengers?.data) {
    return <ErrorPage />
  }

  // region WEB HTML
  if (minW800) {
    return (
      <section
        className='buy-detail-page_container'
        style={{
          height: 'calc(100% - 134px)',
          minHeight: '600px',
          bottom: 0,
          borderRadius: 0,
          background: 'transparent',
        }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}>
          <Box style={{ maxWidth: '600px', width: '100%' }}>
            <div className='buy-detail-header_container'>
              <ArrowBack onClick={goBack} />

              {page === 'info' ? (
                <h2>Información de los pasajeros</h2>
              ) : (
                <h2>Detalle de pago</h2>
              )}
            </div>

            {page === 'info' ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <section
                  className='buy-detail-info-container'
                  style={{
                    height: `calc(100vh - ${
                      headerHeight +
                      mainHeight +
                      (Capacitor.getPlatform() === 'ios'
                        ? 33 + 75
                        : footerHeight === 0
                        ? 140
                        : footerHeight + 75)
                    }px)`,
                  }}>
                  {passengers?.data?.map((passenger) => {
                    return (
                      <div key={passenger.id}>
                        <PassengersItem
                          watch={watch}
                          control={control}
                          register={register}
                          setValue={setValue}
                          errors={errors}
                          setIsDisabled={setIsDisabled}
                          ownerTravel={ownerTravel}
                          setOwnerTravel={setOwnerTravel}
                          userData={userData}
                          passenger={passenger}
                          localPassengers={localPassenger}
                          setLocalPassengers={setLocalPassengers}
                        />
                      </div>
                    )
                  })}
                </section>

                <Box
                  className='button_action'
                  style={{ flexDirection: 'column' }}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      borderRadius: '12px',
                      background: '#fff',
                      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
                      padding: '10px',
                      marginBottom: '12px',
                      width: '100%',
                      maxWidth: '600px',
                    }}>
                    <p
                      style={{
                        textAlign: 'right',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                      }}>
                      Total:
                    </p>

                    <div>
                      <p
                        style={{
                          textAlign: 'right',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: 'normal',
                        }}>
                        Ref {totalUsd}
                      </p>

                      <p
                        style={{
                          textAlign: 'right',
                          fontSize: '12px',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          lineHeight: 'normal',
                        }}>
                        Bs.{' '}
                        {new Intl.NumberFormat('es-VE', {
                          currency: 'USD',
                        }).format(totalBs?.toFixed(2))}
                      </p>
                    </div>
                  </Box>

                  <ButtonBlue type='submit' disabled={isDisabled}>
                    Continuar
                  </ButtonBlue>
                </Box>
              </form>
            ) : (
              <DetailReviewCards />
            )}
          </Box>
        </Box>
      </section>
    )
  }

  // region MOBILE HTML
  return (
    <section className='buy-detail-page_container'>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}>
        <Box style={{ maxWidth: '600px', width: '100%' }}>
          <div className='buy-detail-header_container'>
            <ArrowBack onClick={goBack} />

            {page === 'info' ? (
              <h2>Información de los pasajeros</h2>
            ) : (
              <h2>Detalle de pago</h2>
            )}
          </div>

          {page === 'info' ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <section
                className='buy-detail-info-container'
                style={{
                  height: `calc(100vh - ${
                    headerHeight +
                    mainHeight +
                    (Capacitor.getPlatform() === 'ios'
                      ? 33 + 75
                      : footerHeight === 0
                      ? 140
                      : footerHeight + 35)
                  }px)`,
                }}>
                {passengers?.data?.map((passenger) => {
                  return (
                    <div key={passenger.id}>
                      <PassengersItem
                        watch={watch}
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        setIsDisabled={setIsDisabled}
                        ownerTravel={ownerTravel}
                        setOwnerTravel={setOwnerTravel}
                        userData={userData}
                        passenger={passenger}
                        localPassengers={localPassenger}
                        setLocalPassengers={setLocalPassengers}
                        setTouchableId={setTouchableId}
                      />
                    </div>
                  )
                })}
              </section>

              <Box
                className='button_action'
                style={{ flexDirection: 'column' }}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '12px',
                    background: '#fff',
                    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
                    padding: '10px',
                    marginBottom: '12px',
                    width: '100%',
                    maxWidth: '600px',
                  }}>
                  <p
                    style={{
                      textAlign: 'right',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: 'normal',
                    }}>
                    Total:
                  </p>

                  <div>
                    <p
                      style={{
                        textAlign: 'right',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                      }}>
                      Ref {totalUsd}
                    </p>

                    <p
                      style={{
                        textAlign: 'right',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                      }}>
                      Bs.{' '}
                      {new Intl.NumberFormat('es-VE', {
                        currency: 'USD',
                      }).format(totalBs?.toFixed(2))}
                    </p>
                  </div>
                </Box>

                <ButtonBlue type='submit' disabled={isDisabled}>
                  Continuar
                </ButtonBlue>
              </Box>
            </form>
          ) : (
            <DetailReviewCards />
          )}
        </Box>
      </Box>
    </section>
  )
}

export default BuyTicketDetail
