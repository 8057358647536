import { useEffect } from 'react'
import { InputMaterial } from '../index'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { contries } from '../../../utils/countries'
import './styles.inputphone.css'

const animatedComponents = makeAnimated()

const veCode = [
  { value: '0412', label: '0412' },
  { value: '0414', label: '0414' },
  { value: '0424', label: '0424' },
  { value: '0416', label: '0416' },
  { value: '0426', label: '0426' },
]

const contriesCode = contries.map((option) => {
  return {
    value: option.dial_code,
    label: `${option.dial_code}`,
  }
})

export function FormsPhoneNumber({
  error,
  registerValidation,
  registerCode,
  setValue,
  codeId = 'code',
  codeType = 'international',
}) {
  useEffect(() => {
    if (codeType === 've') {
      setValue(codeId, veCode[0].value)
    } else {
      setValue(codeId, contriesCode.find(({ value }) => value === '+58').value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, codeType])

  return (
    <section className='phone-number_container'>
      <Select
        {...registerCode}
        options={codeType === 've' ? veCode : contriesCode}
        isSearchable={codeType === 've' ? false : true}
        defaultValue={
          codeType === 've'
            ? veCode[0]
            : contriesCode.find(({ value }) => value === '+58')
        }
        components={animatedComponents}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: '25%',
            boxShadow: 'none',
            outline: 'none',
            marginTop: '19px',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            borderRadius: '0px',
            borderBottom: '1px solid #29348F',
            boxShadow: 'none',
            minHeight: '30px',
            background: 'transparent',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            height: '25px',
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none',
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: '2px',
            color: '#29348F',
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#29348F',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: '#29348F',
            background: state.isFocused ? '#a4a1a138' : 'transparent',
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#29348F',
            fontSize: '16px',
          }),
        }}
        onChange={(data) => {
          setValue(codeId, data.value)
        }}
      />

      <div style={{ width: '70%' }} className='input-field'>
        <InputMaterial
          register={registerValidation}
          label='Número de teléfono'
          type='number'
        />

        <p
          style={{
            color: 'gray',
            fontSize: '10px',
            margin: '0px',
            marginTop: '5px',
          }}>
          Ejemplo: 424 000 00 00
        </p>

        {error && <p className='error_message'>{error}</p>}
      </div>
    </section>
  )
}
