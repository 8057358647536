// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-container {
  border-radius: 20px 20px 0px 0px;
  background: #f0eeee;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 18px;
  height: calc(100% + 18px);
  overflow: scroll;
}

.all-header_container {
  display: flex;
  align-items: center;
  padding: 15px;
  padding-left: 24px;
  gap: 12px;
  & h2 {
    color: #29348f;
    font-family: Kufam;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Help/styles.help.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;EACnB,+CAA+C;EAC/C,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;EACX;AACF","sourcesContent":[".all-container {\n  border-radius: 20px 20px 0px 0px;\n  background: #f0eeee;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n  position: relative;\n  bottom: 18px;\n  height: calc(100% + 18px);\n  overflow: scroll;\n}\n\n.all-header_container {\n  display: flex;\n  align-items: center;\n  padding: 15px;\n  padding-left: 24px;\n  gap: 12px;\n  & h2 {\n    color: #29348f;\n    font-family: Kufam;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
