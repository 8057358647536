import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import { useForm } from 'react-hook-form'

import { MethodPaymentCard } from '../../components/ui/Cards/PagoMovil'
import { ButtonBlue } from '../../components/buttons'

import { SendPaymentAlert } from '../../components/bottom-panel/Alert/SendPayment'
import { ArrowBack } from '../../components/icons/ArrowBack'
// import { busiAPi } from '../../utils/urls'

// import { useStore, useAmountsStorage } from '../../store'

// import axios from 'axios'
import { useMediaQuery } from '@mui/material'
// import { Capacitor } from '@capacitor/core'
import './styles.reserve.css'

export const ReservePage = () => {
  // const [token] = useLocalStorage('token')
  const minW800 = useMediaQuery('(min-width:800px)')

  // const {
  //   unregPassengersData,
  //   selectedTrip,
  //   // perTotalUsd,
  //   ownerTravel,
  // } = useStore()

  // const { perTotalUsd, totalUsd } = useAmountsStorage()

  const navegate = useNavigate()
  const { state } = useLocation()

  const [page, setPage] = useState('confirm')
  const [isCheck, setCheck] = useState('mobile')
  const [showAlert, setShowAlert] = useState(false)
  // const [disabled, setDisabled] = useState(true)

  // const [err, setErr] = useState(undefined)
  // const [loading, setLoading] = useState(false)

  // INPUTS SETTINGS

  // const {
  //   register,
  //   handleSubmit,
  //   setValue,
  //   watch,
  //   control,
  //   formState: { errors },
  // } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  // const phoneWatch = watch('phone')
  // const banksWatch = watch('banks')
  // const ciWatch = watch('ci')
  // const refNumberWatch = watch('refNumber')
  // const codeWatch = watch('ciCode')

  // useEffect(() => {
  //   const isBanks = banksWatch !== undefined && banksWatch !== ''
  //   const isRefNumber = refNumberWatch !== undefined && refNumberWatch !== ''
  //   const isPhone = phoneWatch !== undefined && phoneWatch !== ''
  //   // const isPhoneCode = codeWatch !== undefined && codeWatch !== ''
  //   const isCi = ciWatch !== undefined && ciWatch !== ''
  //   // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

  //   if (isBanks && isRefNumber && isPhone && isCi) {
  //     setDisabled(false)
  //   }
  // }, [phoneWatch, banksWatch, ciWatch, refNumberWatch])

  // FUNCTIONS
  const goBack = () => {
    if (page === 'confirm') {
      navegate(state.previousPath)
    }
    if (page === 'info') {
      setPage('confirm')
    }
    // if (page === 'verification') {
    //   setPage('info')
    // }
  }

  const handlePage = () => {
    // if (page === 'confirm') {
    //   setPage('info')
    // }

    if (isCheck === 'mobile') {
      navegate('/main/reserve/pago_movil', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
    if (isCheck === 'bnc') {
      navegate('/main/reserve/bnc', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
    if (isCheck === 'debito') {
      navegate('/main/reserve/debito', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
    if (isCheck === 'credito') {
      navegate('/main/reserve/credito', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
  }

  // const onSubmit = async (data) => {
  //   const paymentData = {
  //     bankName: data.banks,
  //     phoneNumber: `58-${data.code.slice(1)}-${data.phone}`,
  //     documentId: data.ci,
  //     referenceNumber: data.refNumber.toString(),
  //     amount: perTotalUsd,
  //     type: 'mobile',
  //   }

  //   const fullBody = {
  //     routeId: selectedTrip.routeId,
  //     subRouteId: selectedTrip.subRouteId,
  //     isPassengerTraveling: ownerTravel,
  //     isBooking: true,
  //     unregisterPassengers: unregPassengersData,
  //     paymentData,
  //     date: selectedTrip.dateServer,
  //     currency: 'Bs',
  //     price: totalUsd,
  //     platform: Capacitor.getPlatform(),
  //   }

  //   try {
  //     setLoading(true)
  //     const { data: info } = await axios.post(
  //       `${busiAPi}/ticket`,
  //       { ...fullBody },
  //       { headers: { Authorization: localStorage.getItem('token') } },
  //     )

  //     if (info.success) {
  //       setLoading(false)
  //       setShowAlert(true)
  //     } else {
  //       setLoading(false)
  //       setErr('Tenemos un problema, intenta más tarde')
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     console.log({ error })
  //     setErr('Tenemos un problema, intenta más tarde')
  //   }
  // }

  return (
    <section
      className='reserve_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',

              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : { width: '100%' }
      }>
      <div
        onClick={goBack}
        className='reserve-header_container'
        style={{ maxWidth: 600, width: '100%' }}>
        <ArrowBack />

        <h2>Escoger método de pago</h2>
      </div>

      <form
        // onSubmit={handleSubmit(onSubmit)}
        style={{ height: 'calc(100% - 53px)', maxWidth: 600, width: '100%' }}>
        <div
          className='reserve-body_container'
          style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          <MethodPaymentCard
            type='input'
            title='Pago móvil'
            id='mobile'
            isCheck={isCheck}
            setCheck={setCheck}
          />

          {/* <MethodPaymentCard
                type='input'
                title='Pago Movil a BNC'
                id='bnc'
                isCheck={isCheck}
                setCheck={setCheck}
              /> */}

          <MethodPaymentCard
            type='input'
            title='Tarjeta de Débito'
            id='debito'
            isCheck={isCheck}
            setCheck={setCheck}
          />

          <MethodPaymentCard
            type='input'
            title='Tarjeta de Crédito'
            id='credito'
            isCheck={isCheck}
            setCheck={setCheck}
          />
          {/* {page === 'confirm' ? (
            <PagoMovilData page='reserve' />
          ) : (
            page === 'info' && (
              <>
                <PagoMovilData isReserve />

                <GetPaymentData
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  codeWatch={codeWatch}
                />

                {err && (
                  <p
                    className='error_message'
                    style={{ marginTop: '6px', marginLeft: '4px' }}>
                    {err}
                  </p>
                )}
              </>
            )
          )} */}
        </div>

        {/* {page === 'info' ? (
          <div className='action_button_container'>
            <ButtonBlue type='submit' disabled={loading || disabled}>
              Continuar
            </ButtonBlue>
          </div>
        ) : (
          <div className='action_button_container'>
            <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
          </div>
        )} */}
        <div className='action_button_container'>
          <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
        </div>
      </form>

      <SendPaymentAlert
        isShow={showAlert}
        setShow={setShowAlert}
        type='reserve'
        keepOpen
      />
    </section>
  )
}
