import { useRef, useEffect } from 'react'
import { useController } from 'react-hook-form'
import { CalendarIcon2 } from '../../icons/Calendar2'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { esES } from '@mui/x-date-pickers/locales'
import { Box, styled } from '@mui/material'

import dayjs from 'dayjs'
import 'dayjs/locale/es-mx'

import './styles.css'

const CalendarPicker = styled(MobileDatePicker)({
  '.MuiInput-root::before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiInput-root::after': {
    borderBottom: '2px solid transparent',
  },
  '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiIconButton-root': {
    display: 'none',
  },
})

export function CalendarInput({
  max,
  min,
  setValue,
  error,
  id = 'date',
  control,
  label = 'Fecha de nacimiento',
}) {
  const { field } = useController({
    control,
    name: id,
    rules: { required: 'Este campo es obligatorio' },
  })

  const goPicker = useRef(null)

  const today = dayjs()
  const eightTeenYearsOld = dayjs().subtract(18, 'years')
  const fourYearsOld = dayjs().subtract(4, 'years')
  const sixtyYearsOld = dayjs().subtract(60, 'years')

  useEffect(() => {
    setValue(
      dayjs.isDayjs(field.value)
        ? field.value
        : max === 'today'
        ? today
        : max === '18'
        ? eightTeenYearsOld
        : max === '60'
        ? sixtyYearsOld
        : max === '4' && fourYearsOld,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box className='date-input_container'>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale='es-mx'
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }>
          <CalendarPicker
            id={id}
            ref={goPicker}
            name={field.name}
            value={
              dayjs.isDayjs(field.value)
                ? field.value
                : max === 'today'
                ? today
                : max === '18'
                ? eightTeenYearsOld
                : max === '60'
                ? sixtyYearsOld
                : max === '4' && fourYearsOld
            }
            onBlur={field.onBlur}
            onChange={(newValue) => setValue(newValue)}
            label={label}
            format='DD/MM/YYYY'
            minDate={
              min === 'today'
                ? today
                : min === '18'
                ? eightTeenYearsOld
                : min === '60'
                ? sixtyYearsOld
                : min === '4'
                ? fourYearsOld
                : null
            }
            maxDate={
              max === 'today'
                ? today
                : max === '18'
                ? eightTeenYearsOld
                : max === '60'
                ? sixtyYearsOld
                : max === '4'
                ? fourYearsOld
                : null
            }
            localeText={
              esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
            slotProps={{
              openPickerIcon: {
                color: 'primary',
              },
              textField: {
                fullWidth: true,
                variant: 'standard',
                InputLabelProps: {
                  style: {
                    color: '#29348F',
                    fontFamily: 'Kufam',
                  },
                },
                InputProps: {
                  style: {
                    color: '#29348F',
                  },
                },
              },
            }}
            InputAdornmentProps={{ display: 'none' }}
          />
        </LocalizationProvider>

        <CalendarIcon2
          onClick={() => {
            goPicker.current.click()
          }}
        />
      </Box>
      {error && <p className='error_message'>{error}</p>}
    </>
  )
}
