import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

// import { isValidPhoneNumber } from 'libphonenumber-js'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'

import { ButtonBlue } from '../../components/buttons'

import FormsName from '../../components/imputs/forms-user-name'
import FormsCitizensId from '../../components/imputs/forms-citizens-id'
import FormsBirthDate from '../../components/imputs/forms-birth-date'
import { FormsPhoneNumber } from '../../components/imputs/forms-phone-number'
import { GenderRadios } from '../../components/imputs/forms-gender'
import FormsEmail from '../../components/imputs/forms-email'
import FormsPassword from '../../components/imputs/forms-password'

import { AcceptTermsCheckbox } from '../../components/TermsConditions/AcceptCheck'
import { useStore, usePersistStore } from '../../store'
import { busiAPi } from '../../utils/urls'

import axios from 'axios'
import dayjs from 'dayjs'
import './styles.signup.css'

export function SingUpPage() {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const { userData, setUserData } = usePersistStore()
  const { password, setPassword } = useStore()

  const [err, setErr] = useState(undefined)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const lastnameWatch = watch(`lastname`)
  const nameWatch = watch(`name`)
  const ciWatch = watch(`email`)
  const confirmWatch = watch(`confirm`)
  const passwordWatch = watch(`password`)
  const phoneWatch = watch(`phone`)
  const codePhoneWatch = watch(`code`)
  const ciCodeWatch = watch(`ciCode`)

  useEffect(() => {
    const dni = userData.ci

    if (dni) {
      const vector = dni.split('-')
      const ciNumber = vector[1]
      const ciType = vector[0]

      setValue('ci', ciNumber)
      setValue('ciCode', ciType)
    }

    const name = userData?.name
    const lastName = userData?.lastName
    const gender = userData?.gender
    const email = userData?.email
    const phone = userData?.phone?.slice(3)
    const bornDate = userData?.bornDate

    if (userData?.phone) {
      setValue('name', name)
      setValue('lastname', lastName)
      setValue('email', email)
      setValue('phone', phone)
      setValue('gender', gender)
      setValue('password', password)
      setValue('confirm', password)

      if (dayjs.isDayjs(bornDate)) {
        setValue('date', bornDate)
      } else {
        const dateByDay = dayjs(bornDate.slice(0, -5), 'YYYY-MM-DDTHH:mm:ss')
        setValue('date', dateByDay)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const isLastname = lastnameWatch !== undefined && lastnameWatch !== ''
    const isName = nameWatch !== undefined && nameWatch !== ''
    const isEmail = ciWatch !== undefined && ciWatch !== ''
    const isPassword = passwordWatch !== undefined && passwordWatch !== ''
    const isConfirm = confirmWatch !== undefined && confirmWatch !== ''

    const isPhone = phoneWatch !== undefined && phoneWatch !== ''

    if (
      isConfirm &&
      isEmail &&
      isLastname &&
      isName &&
      isPassword &&
      isPhone &&
      acceptTerms
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    acceptTerms,
    lastnameWatch,
    nameWatch,
    ciWatch,
    confirmWatch,
    passwordWatch,
    phoneWatch,
  ])

  const onSubmit = async (formData) => {
    setDisabled(true)
    const password = formData.password
    const confirmPassword = formData.confirm

    if (password !== confirmPassword) {
      setError('confirm', { message: 'Las contraseñas deben coincidir' })
      setDisabled(false)
      return
    }

    // const phoneNumber = `58-${formData.code.slice(1)}-${formData.phone}`
    const phoneNumber = `${formData.code}-${formData.phone}`
    const documentId = `${formData.ciCode}-${formData.ci}`

    const infoToSend = {
      email: formData.email.toLowerCase(),
      password,
      documentId,
      firstName: formData.name,
      lastName: formData.lastname,
      phoneNumber: phoneNumber.trim(),
      dob: formData.date,
      gender: formData.gender,
    }

    try {
      const { data: info } = await axios.post(
        `${busiAPi}/auth/passengers/register`,
        infoToSend,
      )

      if (info.success) {
        setUserData({
          name: formData.name,
          lastName: formData.lastname,
          ci: documentId,
          email: formData.email,
          bornDate: formData.date,
          status: info.data.status,
          gender: info.data.gender,
          phone: phoneNumber,
        })
        setPassword(password)

        navigate('/validate', {
          state: { previousPath: pathname, wantToBuy: state?.wantToBuy },
        })
      } else {
        setDisabled(false)
        if (info.error.errorCode === 1000) {
          setErr('Alguno de los datos ingresados ya pertenece a un pasajero')
          return
        }
        if (info.error.errorCode === 1006) {
          setErr('El correo electrónico ingresado ya pertenece a un pasajero')
          return
        }

        setErr('Estamos teniendo un problema, intenta más tarde')
      }
    } catch (error) {
      console.error({ error })
      const mainError = error?.response?.data?.error
      setDisabled(false)

      if (mainError?.errorCode === 1000) {
        setErr('Alguno de los datos ingresados ya pertenece a un pasajero')
        return
      }
      if (mainError?.errorCode === 1006) {
        setErr('El correo electrónico ingresado ya pertenece a un pasajero')
        return
      }

      setErr('Lo sentimos, algo salio mal, intente más tarde')
    }
  }

  const goBack = () => {
    setUserData(undefined)
    navigate('/login', { state: { wantToBuy: state?.wantToBuy } })
  }

  return (
    <section className='pages-registration'>
      <div className='pages-registration-top'>
        <i onClick={() => goBack()} className='material-icons back'>
          arrow_back
        </i>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className='page-registration-form_container'>
        <div className='pages-registration-middle'>
          <h2 className='pages-registration-middle-title'>Ingresa tus datos</h2>

          <section
            style={{
              marginTop: '0.5rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}>
            <FormsCitizensId
              readOnly
              control={control}
              setValue={setValue}
              getValue={ciCodeWatch}
            />

            <FormsName
              registerName={{
                ...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value:
                      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
                    message: 'Solo letras',
                  },
                  minLength: {
                    value: 3,
                    message: 'Mínimo 3 carácteres',
                  },
                }),
              }}
              registerLastname={{
                ...register('lastname', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value:
                      /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/,
                    message: 'Solo letras',
                  },
                  minLength: {
                    value: 3,
                    message: 'Mínimo 3 carácteres',
                  },
                }),
              }}
              errorName={errors.name?.message}
              errorLastname={errors.lastname?.message}
            />

            {/* <Calendar /> */}
            <FormsBirthDate
              registerValidation={{
                ...register('date', { required: 'Este campo es requerido' }),
              }}
              max='18'
              setValue={(value) => {
                setValue('date', value)
              }}
              error={errors.date?.message}
              control={control}
            />

            {/* <GenderRadio /> */}
            <GenderRadios
              registerValidation={{
                ...register('gender', {
                  required: 'Este campo es requerido',
                }),
              }}
              setValue={setValue}
              error={errors.gender?.message}
            />

            <FormsPhoneNumber
              registerCode={{
                ...register('code', { required: false }),
              }}
              registerValidation={{
                ...register('phone', {
                  required: 'Este campo es requerido',

                  minLength: {
                    value: 7,

                    message: 'Minimo debe tener 7 dígitos',
                  },
                  valueAsNumber: true,
                  validate: (val) => {
                    return (
                      isValidPhoneNumber(`${codePhoneWatch}${val}`) ||
                      'Número no valido'
                    )
                  },
                }),
              }}
              error={errors.phone?.message}
              setValue={setValue}
            />

            <FormsEmail
              registerValidation={{
                ...register('email', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value:
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b/i,
                    // value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                    message: 'Formato de correo inválido.',
                    // 'Formato de correo inválido. Por favor, incluye el símbolo @',
                  },

                  setValueAs: (val) => {
                    return val.toLowerCase()
                  },
                }),
              }}
              error={errors.email?.message}
            />

            <FormsPassword
              label='Contraseña'
              registerValidation={{
                ...register('password', {
                  required: 'Este campo es requerido',
                  pattern: {
                    message: 'Contraseña no valida',
                    value:
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,30}$/,
                  },
                }),
              }}
              showDetail={true}
              error={errors.password?.message}
            />

            <FormsPassword
              id='confirm'
              label='Confirmar contraseña'
              registerValidation={{
                ...register('confirm', {
                  required: 'Este campo es requerido',
                  pattern: {
                    message: 'Contraseña no valida',
                    value:
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,30}$/,
                    // value: /[A-Za-z]{3}/,
                  },
                }),
              }}
              error={errors.confirm?.message}
            />

            <AcceptTermsCheckbox
              termsPostion='up'
              getValue={(data) => setAcceptTerms(data)}
              label='He leído y acepto los terminos y condiciones'
              link='https://www.busitransporte.com/about-7'
              title='Términos y condiciones'
            />

            {err && <p className='error_message'>{err}</p>}
          </section>
        </div>

        <div className='pages-registration-bottom'>
          <ButtonBlue type='sumit' disabled={disabled}>
            Registrar
          </ButtonBlue>
        </div>
      </form>
    </section>
  )
}
