// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pages-registration {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1fr;
}

.pages-registration-top {
  margin-top: 3rem;
  /* margin-bottom: 3.86rem; */

  margin-left: 2.85rem;
  margin-right: 2.85rem;
}

.pages-registration-title {
  color: #aaa6a6;
  font-family: Kufam;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
}

.pages-registration-middle {
  margin-left: 2.85rem;
  margin-right: 2.7rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pages-registration-bottom {
  margin: auto;
  margin-bottom: 20px;
}

.actions {
  margin: auto;
}

.back {
  color: #29348f;
}

.pages-registration-middle-title {
  color: #29348f;
  font-family: Kufam;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
}

.page-registration-form_container {
  display: grid;
  align-content: stretch;
  height: 100%;
  justify-items: center;
  overflow-y: scroll;
}

@media (max-width: 320px) {
  .pages-registration-middle {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/SignUpPage/styles.signup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,4BAA4B;;EAE5B,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".pages-registration {\n  display: grid;\n  height: 100%;\n  grid-template-columns: 1fr;\n  grid-template-rows: 0.1fr 1fr;\n}\n\n.pages-registration-top {\n  margin-top: 3rem;\n  /* margin-bottom: 3.86rem; */\n\n  margin-left: 2.85rem;\n  margin-right: 2.85rem;\n}\n\n.pages-registration-title {\n  color: #aaa6a6;\n  font-family: Kufam;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 1.8px;\n}\n\n.pages-registration-middle {\n  margin-left: 2.85rem;\n  margin-right: 2.7rem;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n\n.pages-registration-bottom {\n  margin: auto;\n  margin-bottom: 20px;\n}\n\n.actions {\n  margin: auto;\n}\n\n.back {\n  color: #29348f;\n}\n\n.pages-registration-middle-title {\n  color: #29348f;\n  font-family: Kufam;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  margin-top: 0;\n}\n\n.page-registration-form_container {\n  display: grid;\n  align-content: stretch;\n  height: 100%;\n  justify-items: center;\n  overflow-y: scroll;\n}\n\n@media (max-width: 320px) {\n  .pages-registration-middle {\n    margin-left: 1rem;\n    margin-right: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
