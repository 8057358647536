// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info_terminal_text {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  font-weight: 700;
  /* text-wrap: nowrap; */
}

.amount_terminal {
  font-size: 14px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/paymentMethod/TerminalCash/styles.terminal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".info_terminal_text {\n  display: flex;\n  justify-content: space-between;\n  align-items: start;\n  width: 100%;\n  font-weight: 700;\n  /* text-wrap: nowrap; */\n}\n\n.amount_terminal {\n  font-size: 14px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
