import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ArrowBack } from '../../icons/ArrowBack'
import { ButtonBlue } from '../../buttons'
import { SendPaymentAlert } from '../../bottom-panel/Alert/SendPayment'
import { busiAPi } from '../../../utils/urls'

import {
  MethodPaymentCard,
  PagoMovilData,
  GetPaymentData,
  AllowBanks,
} from '../../ui/Cards/PagoMovil'

import { useStore, useAmountsStorage } from '../../../store'

import axios from 'axios'
import { Box, useMediaQuery } from '@mui/material'
import { Capacitor } from '@capacitor/core'
import { Card } from '../../ui/Cards'
import { ImportantIcon } from '../../icons/ImportantIcon'
import { Copy } from '../../icons/Copy'

import { ValidationCode } from '../../imputs/forms-validation-code'
import { Alert } from '../../bottom-panel/Alert'
import { ContactLine } from '../../buttons/ContactLine'
import './styles.cash.css'

export const PagoCash = () => {
  const { unregPassengersData, selectedTrip, ownerTravel } = useStore()
  const { totalUsd } = useAmountsStorage()
  const minW800 = useMediaQuery('(min-width:800px)')

  const navegate = useNavigate()
  const { state } = useLocation()
  // const { paymentType } = useParams()

  const [ticket, setTicket] = useState()

  // const isReserve = useMemo(() => paymentType === 'reserve', [paymentType])

  const [page, setPage] = useState('info')
  const [show, setShow] = useState(false)
  const [showPayment, setShowPayment] = useState(false)

  const [disabled, setDisabled] = useState(true)
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  //region INPUTS SETTINGS
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const banksWatch = watch('banks')
  const authCodeWatch = watch('authCode')

  // const codeWatch = watch('ciCode')

  useEffect(() => {
    if (page === 'info') {
      setDisabled(false)
      return
    }

    const isBanks = banksWatch !== undefined && banksWatch !== ''

    if (page === 'verification' && isBanks) {
      setDisabled(false)
      return
    }
    const isAuthCode = authCodeWatch !== undefined && authCodeWatch !== ''

    if (page === 'code' && isAuthCode) {
      setDisabled(false)
      return
    }

    setDisabled(true)
  }, [banksWatch, authCodeWatch, page])

  useEffect(() => {
    if (state?.inComingPage) {
      setPage(state?.inComingPage)
    }
  }, [state?.inComingPage])

  // region FUNCTIONS
  const goBack = () => {
    if (page === 'info') {
      navegate('/main/payment', {
        state: { previousPath: state?.previousPath },
      })
    }

    if (page === 'verification') {
      navegate(state?.previousPath || '/main/ticket')
    }

    if (page === 'code') {
      setPage('verification')
    }
  }

  // const handlePage = () => {
  //   if (page === 'info') {
  //     // setPage('verification')
  //     setShow(true)
  //   }
  //   if (page === 'verification') {
  //     setPage('code')
  //   }
  // }

  // region Submit
  const onSubmit = async (data) => {
    if (page === 'verification') {
      setPage('code')
      return
    }
    const isReportDeposit = state?.inComingPage === 'verification'

    const paymentData = {
      // bankName: data.banks,
      // phoneNumber: `58-${data.code.slice(1)}-${data.phone}`,
      // documentId: `${data.ciCode}-${data.ci}`,
      // referenceNumber: data.refNumber?.toString(),
      amount: totalUsd,
      type: 'deposit_usd',
    }

    const passengers = unregPassengersData

    const fullBody = {
      routeId: selectedTrip?.routeId,
      subRouteId: selectedTrip?.subRouteId,
      isPassengerTraveling: ownerTravel,
      unregisterPassengers: passengers,
      paymentData,
      date: selectedTrip?.dateServer,
      currency: 'Bs',
      isBooking: false,
      price: totalUsd - selectedTrip?.busStationExitRate * passengers?.length,
      paidAmount: totalUsd,
      platform: Capacitor.getPlatform(),
    }

    const fullBodyReport = {
      paymentId: state?.paymentId,
      referenceNumber: data?.authCode?.toString(),
      bankName: data?.banks?.bank_name,
    }

    try {
      setLoading(true)
      const { data: info } = await axios.post(
        isReportDeposit
          ? `${busiAPi}/ticket/report-deposit`
          : `${busiAPi}/ticket`,
        isReportDeposit ? { ...fullBodyReport } : { ...fullBody },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setLoading(false)

        setTicket(info?.data)
        if (state?.inComingPage === 'verification' && page === 'code') {
          setShowPayment(true)
        } else {
          setShow(true)
        }
      } else {
        setLoading(false)
        setErr('Tenemos un problema, intenta más tarde')
      }
    } catch (error) {
      setLoading(false)
      const errMain = error?.response?.data?.error
      console.log({ errMain })
      if (errMain?.errorCode === 1103) {
        setErr(errMain?.message)
        return
      }
      setErr('Tenemos un problema, intenta más tarde')
    }
  }

  return (
    <section
      className='payment_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }
      }>
      <div
        onClick={goBack}
        className='payment-header_container'
        style={{ maxWidth: '600px', width: '100%' }}>
        <ArrowBack />

        <h2>
          {page === 'info' ? 'Datos para el pago' : 'Datos para verificación'}
        </h2>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: 'calc(100% - 53px)',
          maxWidth: '600px',
          width: '100%',
        }}>
        <section
          className='payment-body_container'
          style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {page === 'info' ? (
            <>
              <MethodPaymentCard title={'Depósito USD'} />

              <PagoMovilData
                isCompleteBooked={state?.status === 'booked'}
                mobilePayment={false}
                isCash={true}
              />

              <Card>
                <Box
                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <ImportantIcon width={20} height={20} />
                  <h3 style={{ fontSize: 16, fontWeight: '700' }}>
                    ¡Importante!
                  </h3>
                </Box>

                <p style={{ fontSize: 12 }}>
                  Tendrás hasta 24 horas antes del viaje para enviarnos por
                  Whatsapp el comprobante del depósito.
                </p>

                <br />

                <p style={{ fontSize: 12 }}>
                  Tu boleto quedará reservado hasta este momento. Podrás ver los
                  datos bancarios en el detalle del boleto.
                </p>
              </Card>

              <AllowBanks type={'cash'} />
            </>
          ) : page === 'verification' ? (
            <>
              <MethodPaymentCard title={'Depósito USD'} />

              <Card>
                <Box
                  style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <ImportantIcon width={20} height={20} />
                  <h3 style={{ fontSize: 16, fontWeight: '700' }}>
                    ¡Importante!
                  </h3>
                </Box>

                <p style={{ fontSize: 12 }}>
                  Deberás reportar el número de referencia y enviar una foto del
                  comprobante.
                </p>
              </Card>

              <GetPaymentData
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                // codeWatch={codeWatch}
                dataType={'cash'}
              />

              {banksWatch !== undefined && (
                <Card>
                  <Box
                    component='section'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}>
                    <Box>
                      <p>Banco</p>
                      <p className='info_cash_text'>
                        {banksWatch.bank_name}
                        <Copy text={banksWatch.bank_name} />
                      </p>
                    </Box>

                    <Box>
                      <p>Titular</p>

                      <p className='info_cash_text'>
                        {banksWatch.company_name}
                        <Copy text={banksWatch.company_name} />
                      </p>
                    </Box>

                    <Box>
                      <p>Número de cuenta</p>

                      <p className='info_cash_text'>
                        {banksWatch.account_number}
                        <Copy text={banksWatch.account_number} />
                      </p>
                    </Box>

                    <Box>
                      <p>RIF</p>

                      <p className='info_cash_text'>
                        {banksWatch.document_number}
                        <Copy text={banksWatch.document_number} />
                      </p>
                    </Box>
                  </Box>
                </Card>
              )}

              {err && (
                <p
                  className='error_message'
                  style={{ marginTop: '6px', marginLeft: '4px' }}>
                  {err}
                </p>
              )}
            </>
          ) : (
            page === 'code' && (
              <>
                <MethodPaymentCard title={'Depósito USD'} />
                <Card>
                  <h4>Banco</h4>
                  <p>{banksWatch?.bank_name}</p>

                  <ValidationCode
                    label={'Número de referencia '}
                    error={errors.authCode?.message}
                    control={control}
                    nameId='authCode'
                  />
                </Card>
              </>
            )
          )}
        </section>

        {/* {page === 'info' || page === 'code' ? (
          <div className='action_button_container'>
            <ButtonBlue type='submit' disabled={loading || disabled}>
              Reservar boleto
            </ButtonBlue>
          </div>
        ) : (
          <div className='action_button_container'>
            <ButtonBlue onClick={handlePage}>Reservar boleto</ButtonBlue>
          </div>
        )} */}

        <div className='action_button_container'>
          <ButtonBlue type='submit' disabled={loading || disabled}>
            Reservar boleto
          </ButtonBlue>
        </div>
      </form>

      {state?.inComingPage !== 'verification' && (
        <SendPaymentAlert
          isShow={show}
          setShow={setShow}
          type={'cash'}
          id={ticket?.id}
          keepOpen
        />
      )}

      {state.inComingPage === 'verification' && (
        <Alert
          isShow={showPayment}
          setShow={setShowPayment}
          type='reserve'
          showButton={false}
          keepOpen>
          <h1>¡Pago registrado!</h1>
          <p style={{ fontSize: 12, textAlign: 'center' }}>
            Para finalizar el reporte de pago debes enviarnos la foto del
            comprobante del depósito al Whatsapp de Atención al pasajero: 0424
            222 9359.
          </p>
          <ContactLine text='Atención al pasajero' sendHome />
        </Alert>
      )}
    </section>
  )
}
