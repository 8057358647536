import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'

export const useCheckRateReview = (options = {}) => {
  return useQuery({
    queryKey: ['review'],
    queryFn: () => get('trip/without-review', options),
    retryDelay: 20000,
  })
}
export const priceOptions = async ({
  subRouteId,
  amount = 1,
  elderly = 0,
  underage = 0,
  pet = 0,
  disabled = 0,
}) => {
  // const { data, isLoading, isError, refetch } = useQuery({
  //   queryKey: ['priceOptions'],
  //   queryFn: () =>
  //     get(
  //       `trip/get-sub-route-price?subRouteId=${subRouteId}&amount=${amount}&elderly=${elderly}&underage=${underage}&pet=${pet}&disabled=${disabled}`,
  //       {},
  //     ),
  //   retryDelay: 20000,
  // })

  const info = await get(
    `trip/get-sub-route-price?subRouteId=${subRouteId}&amount=${amount}&elderly=${elderly}&underage=${underage}&pet=${pet}&disabled=${disabled}`,
    {},
  )

  return {
    // priceOptions: data,
    // isLoading,
    // isError,
    info,
    // refetch,
  }
}
