import { useQuery } from '@tanstack/react-query'
import { get } from '../api/axiosHelper'
import { useEffect, useState } from 'react'

export const useGetAppVersion = () => {
  return useQuery({
    queryKey: ['appVersion'],
    queryFn: () => get('/setting/app-version'),
  })
}

export const useGetExchains = (options = {}) => {
  return useQuery({
    queryKey: ['exchain'],
    queryFn: () => get('setting/exchange-rates', options),
    retryDelay: 20000,
  })
}

export const useGetTerminals = ({ terminalOrigin = undefined }) => {
  const { data: terminals } = useQuery({
    queryKey: ['terminals'],
    queryFn: () => get('/setting/cash-payment-terminals'),
  })

  const [filteredTerminals, setFilteredTerminals] = useState(undefined)

  useEffect(() => {
    if (terminalOrigin) {
      setFilteredTerminals(
        terminals?.data?.find((terminal) => terminal === terminalOrigin),
      )
    }
  }, [terminalOrigin, terminals?.data])

  return { terminals: terminals?.data, filteredTerminals }
}
