import { useNavigate } from 'react-router-dom'
// import { createPortal } from 'react-dom'
import { ButtonBlue } from '../../../buttons'
import { useStore, useAmountsStorage } from '../../../../store'
import { Box, useMediaQuery } from '@mui/material'
import { SuccessIcon, SuccessReserveIcon } from '../../../icons/AlertStatus'

// import dayjs from 'dayjs'

import { DialogStyles, SwipeableDrawerStyles, Transition } from '../../index'

import './styles.sendpay.css'

export const SendPaymentAlert = ({
  type = 'buy',
  isShow = false,
  setShow,
  keepOpen = false,
}) => {
  const {
    destination,
    selectedTrip,
    selectedTicket,
    wsLink,
    unregPassengersData,
  } = useStore()

  const {
    // totalUsdDiscount,
    // totalBsDiscount,
    totalUsd,
    totalBs,
    perTotalBs,
    perTotalUsd,
    completedBookedUsd,
    completedBookedBs,
  } = useAmountsStorage()

  const navegate = useNavigate()

  const minW990 = useMediaQuery('(min-width:990px)')

  // region WEB HTML
  if (minW990) {
    return (
      <DialogStyles
        open={isShow}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShow(keepOpen)}
        aria-describedby='alert-dialog-slide-description'>
        <section
          className='sendpay-icon_container'
          style={{ zIndex: 1, top: 'inherit', opacity: 0.2 }}>
          {type === 'reserve' ? (
            <SuccessReserveIcon width='210' height='210' />
          ) : (
            <SuccessIcon width='210' height='210' />
          )}
        </section>

        <center
          className='sendpay-body_card'
          style={{
            marginTop: 0,
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '20px',
          }}>
          <h2 className='title_pay'>
            {type === 'reserveConfirm'
              ? '¡Reserva confirmada!'
              : '¡Pago enviado!'}
          </h2>

          {type !== 'reserveConfirm' && (
            <p className='text_pay'>
              Tu pago será verificado dentro de las próximas 24 horas.
            </p>
          )}

          <div className='location_container'>
            <p>
              {destination.from} (
              {type === 'completedBooked'
                ? selectedTicket.startLocation.short
                : destination.shortFrom}
              ) - {destination.to} (
              {type === 'completedBooked'
                ? selectedTicket.endLocation.short
                : destination.shortTo}
              )
            </p>
            <p>
              Cant:{' '}
              {type === 'completedBooked'
                ? selectedTicket?.passengers?.length
                : unregPassengersData.length}
            </p>
          </div>

          <div className='line_time_container'>
            <p className='line_name'>
              {type === 'completedBooked'
                ? selectedTicket.busLine
                : selectedTrip.busLine}
            </p>
            <p className='time_text'>
              {destination?.date}, {selectedTrip?.startTime}
            </p>
          </div>

          <hr className='divider dash' />

          {type !== 'reserveConfirm' && (
            <div className='price_container'>
              <p className='total_price_text'>Total pagado</p>

              <div>
                <p className='price'>
                  Bs.{' '}
                  {type === 'reserve'
                    ? perTotalBs
                    : type === 'completedBooked'
                    ? completedBookedBs
                    : new Intl.NumberFormat('es-VE', {
                        currency: 'USD',
                      }).format(totalBs?.toFixed(2))}
                </p>

                <p className='ref_price'>
                  Ref{' '}
                  {type === 'reserve'
                    ? perTotalUsd
                    : type === 'completedBooked'
                    ? completedBookedUsd
                    : totalUsd}
                </p>
              </div>
            </div>
          )}

          <section className='footer_container' style={{ marginTop: '10px' }}>
            {/* <p className='choose_set_text'>
                Podrás escoger los asientos <br />
                <span style={{ textDecoration: 'underline' }}>
                  48 horas antes de tu viaje
                </span>
                .
              </p> */}

            <div className='action_buttons'>
              <ButtonBlue onClick={() => navegate('/main/ticket')}>
                Finalizar
              </ButtonBlue>

              <ButtonBlue design='secondary'>
                <a
                  href={wsLink}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='13'
                    height='13'
                    viewBox='0 0 13 13'
                    fill='none'>
                    <path
                      d='M11.1055 1.89153C10.5066 1.28959 9.79323 0.81232 9.007 0.48757C8.22077 0.162821 7.37741 -0.00291207 6.52613 3.87201e-05C2.9593 3.87201e-05 0.0522614 2.89253 0.0522614 6.44152C0.0522614 7.57902 0.352764 8.68401 0.914573 9.65901L0 13L3.42965 12.103C4.37688 12.6165 5.44171 12.8895 6.52613 12.8895C10.093 12.8895 13 9.99701 13 6.44802C13 4.72552 12.3271 3.10703 11.1055 1.89153ZM6.52613 11.7975C5.5593 11.7975 4.61206 11.5375 3.78241 11.05L3.58643 10.933L1.54824 11.466L2.09045 9.49001L1.9598 9.28851C1.42265 8.43504 1.13743 7.44852 1.13668 6.44152C1.13668 3.49053 3.55377 1.08554 6.5196 1.08554C7.95678 1.08554 9.30904 1.64453 10.3216 2.65853C10.823 3.15511 11.2203 3.74576 11.4906 4.39625C11.7608 5.04675 11.8986 5.74414 11.896 6.44802C11.909 9.39901 9.49196 11.7975 6.52613 11.7975ZM9.47889 7.79352C9.31558 7.71552 8.51859 7.32552 8.37487 7.26702C8.22462 7.21502 8.1201 7.18902 8.00904 7.34502C7.89799 7.50752 7.59095 7.87152 7.4995 7.97551C7.40804 8.08601 7.31005 8.09901 7.14673 8.01451C6.98342 7.93651 6.4608 7.76102 5.84673 7.21502C5.36332 6.78602 5.04322 6.25952 4.94523 6.09702C4.85377 5.93452 4.93216 5.85002 5.01709 5.76552C5.08894 5.69402 5.1804 5.57702 5.25879 5.48602C5.33719 5.39502 5.36985 5.32352 5.42211 5.21952C5.47437 5.10902 5.44824 5.01802 5.40904 4.94002C5.36985 4.86202 5.04322 4.06903 4.91256 3.74403C4.78191 3.43203 4.64472 3.47103 4.54673 3.46453H4.23317C4.12211 3.46453 3.95226 3.50353 3.80201 3.66603C3.65829 3.82853 3.2402 4.21853 3.2402 5.01152C3.2402 5.80452 3.82161 6.57152 3.9 6.67552C3.97839 6.78602 5.04322 8.41101 6.66332 9.10651C7.04874 9.27551 7.34925 9.37301 7.58442 9.44451C7.96985 9.56801 8.32261 9.54851 8.60352 9.50951C8.91709 9.46401 9.56382 9.11951 9.69447 8.74251C9.83166 8.36551 9.83166 8.04702 9.78593 7.97551C9.7402 7.90402 9.64221 7.87151 9.47889 7.79352Z'
                      fill='#29348F'
                    />
                  </svg>
                  <span>Contactar línea</span>
                </a>
              </ButtonBlue>
            </div>
          </section>
        </center>
      </DialogStyles>
    )
  }

  // region MOBILE HTML
  return (
    <SwipeableDrawerStyles
      anchor='bottom'
      open={isShow}
      onClose={() => setShow(keepOpen)}
      onOpen={() => setShow(true)}
      disableSwipeToOpen={true}
      keepMounted={false}
      transitionDuration={330}>
      <Box
        component='section'
        style={{
          fontFamily: 'Kufam',
          color: '#29348f',
          zIndex: 20,
          width: '94%',
          padding: 22,
          paddingBottom: 50,
          borderRadius: '25px 25px 0px 0px',
          backgroundColor: '#f6f6f6',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: -3 },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {/* <section className='center_panel'> */}
        {/* <div className='send-payment_container'> */}
        <section className='sendpay-icon_container'>
          {type === 'reserve' ? <SuccessReserveIcon /> : <SuccessIcon />}
        </section>

        <center className='sendpay-body_card'>
          <h2 className='title_pay'>
            {type === 'reserveConfirm'
              ? '¡Reserva confirmada!'
              : '¡Pago enviado!'}
          </h2>

          {type !== 'reserveConfirm' && (
            <p className='text_pay'>
              Tu pago será verificado dentro de las próximas 24 horas.
            </p>
          )}

          <div className='location_container'>
            <p>
              {destination.from} (
              {type === 'completedBooked'
                ? selectedTicket.startLocation.short
                : destination.shortFrom}
              ) - {destination.to} (
              {type === 'completedBooked'
                ? selectedTicket.endLocation.short
                : destination.shortTo}
              )
            </p>
            <p>
              Cant:{' '}
              {type === 'completedBooked'
                ? selectedTicket?.passengers?.length
                : unregPassengersData.length}
            </p>
          </div>

          <div className='line_time_container'>
            <p className='line_name'>
              {type === 'completedBooked'
                ? selectedTicket.busLine
                : selectedTrip.busLine}
            </p>
            <p className='time_text'>
              {destination?.date}, {selectedTrip?.startTime}
            </p>
          </div>

          <hr className='divider dash' />

          {type !== 'reserveConfirm' && (
            <div className='price_container'>
              <p className='total_price_text'>Total pagado</p>

              <div>
                <p className='price'>
                  Bs.{' '}
                  {type === 'reserve'
                    ? perTotalBs
                    : type === 'completedBooked'
                    ? completedBookedBs
                    : new Intl.NumberFormat('es-VE', {
                        currency: 'USD',
                      }).format(totalBs?.toFixed(2))}
                </p>

                <p className='ref_price'>
                  Ref{' '}
                  {type === 'reserve'
                    ? perTotalUsd
                    : type === 'completedBooked'
                    ? completedBookedUsd
                    : totalUsd}
                </p>
              </div>
            </div>
          )}

          <section className='footer_container' style={{ marginTop: '10px' }}>
            {/* <p className='choose_set_text'>
                Podrás escoger los asientos <br />
                <span style={{ textDecoration: 'underline' }}>
                  48 horas antes de tu viaje
                </span>
                .
              </p> */}

            <div className='action_buttons'>
              <ButtonBlue onClick={() => navegate('/main/ticket')}>
                Finalizar
              </ButtonBlue>

              <ButtonBlue design='secondary'>
                <a
                  href={wsLink}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='13'
                    height='13'
                    viewBox='0 0 13 13'
                    fill='none'>
                    <path
                      d='M11.1055 1.89153C10.5066 1.28959 9.79323 0.81232 9.007 0.48757C8.22077 0.162821 7.37741 -0.00291207 6.52613 3.87201e-05C2.9593 3.87201e-05 0.0522614 2.89253 0.0522614 6.44152C0.0522614 7.57902 0.352764 8.68401 0.914573 9.65901L0 13L3.42965 12.103C4.37688 12.6165 5.44171 12.8895 6.52613 12.8895C10.093 12.8895 13 9.99701 13 6.44802C13 4.72552 12.3271 3.10703 11.1055 1.89153ZM6.52613 11.7975C5.5593 11.7975 4.61206 11.5375 3.78241 11.05L3.58643 10.933L1.54824 11.466L2.09045 9.49001L1.9598 9.28851C1.42265 8.43504 1.13743 7.44852 1.13668 6.44152C1.13668 3.49053 3.55377 1.08554 6.5196 1.08554C7.95678 1.08554 9.30904 1.64453 10.3216 2.65853C10.823 3.15511 11.2203 3.74576 11.4906 4.39625C11.7608 5.04675 11.8986 5.74414 11.896 6.44802C11.909 9.39901 9.49196 11.7975 6.52613 11.7975ZM9.47889 7.79352C9.31558 7.71552 8.51859 7.32552 8.37487 7.26702C8.22462 7.21502 8.1201 7.18902 8.00904 7.34502C7.89799 7.50752 7.59095 7.87152 7.4995 7.97551C7.40804 8.08601 7.31005 8.09901 7.14673 8.01451C6.98342 7.93651 6.4608 7.76102 5.84673 7.21502C5.36332 6.78602 5.04322 6.25952 4.94523 6.09702C4.85377 5.93452 4.93216 5.85002 5.01709 5.76552C5.08894 5.69402 5.1804 5.57702 5.25879 5.48602C5.33719 5.39502 5.36985 5.32352 5.42211 5.21952C5.47437 5.10902 5.44824 5.01802 5.40904 4.94002C5.36985 4.86202 5.04322 4.06903 4.91256 3.74403C4.78191 3.43203 4.64472 3.47103 4.54673 3.46453H4.23317C4.12211 3.46453 3.95226 3.50353 3.80201 3.66603C3.65829 3.82853 3.2402 4.21853 3.2402 5.01152C3.2402 5.80452 3.82161 6.57152 3.9 6.67552C3.97839 6.78602 5.04322 8.41101 6.66332 9.10651C7.04874 9.27551 7.34925 9.37301 7.58442 9.44451C7.96985 9.56801 8.32261 9.54851 8.60352 9.50951C8.91709 9.46401 9.56382 9.11951 9.69447 8.74251C9.83166 8.36551 9.83166 8.04702 9.78593 7.97551C9.7402 7.90402 9.64221 7.87151 9.47889 7.79352Z'
                      fill='#29348F'
                    />
                  </svg>
                  <span>Contactar línea</span>
                </a>
              </ButtonBlue>
            </div>
          </section>
        </center>
        {/* </div> */}
        {/* </section> */}
      </Box>
    </SwipeableDrawerStyles>
  )
}
