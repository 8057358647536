// import { useNavigate } from 'react-router-dom'

import { SignOutIcon } from '../../icons/SignOut'
import {
  Bus,
  ErrorIcon,
  SuccessIcon,
  SuccessReserveIcon,
  WarningIcon,
  WarningOrangeIcon,
} from '../../icons/AlertStatus'

import {
  Box,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { ButtonBlue } from '../../buttons'
import { DialogStyles, SwipeableDrawerStyles, Transition } from '../index'

import './styles.alert.css'

export const Alert = ({
  title,
  children,
  type = 'success',
  isShow = false,
  setShow,
  keepOpen = false,
  showButton = true,
  showSecondButtom = true,
  buttonText = 'Continuar',
  disabledButton = false,
  onClickButton,
  onSecondClickButton,
}) => {
  const minW990 = useMediaQuery('(min-width:990px)')

  if (minW990) {
    return (
      <DialogStyles
        open={isShow}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShow(keepOpen)}
        aria-describedby='alert-dialog-slide-description'>
        {/* <section
          className={`icon-alert_container ${type}`}
          style={{ position: 'absolute' }}>
          {type === 'success' ? (
            <SuccessIcon />
          ) : type === 'error' ? (
            <ErrorIcon />
          ) : type === 'bus' ? (
            <div className='bus_container'>
              <img
                className='bus_img'
                src='/assets/images/logo_busi.png'
                alt='logo_busi'
              />
              <Bus />
            </div>
          ) : type === 'signout' ? (
            <SignOutIcon />
          ) : (
            <WarningIcon width='53' height='53' />
          )}
        </section> */}

        {type === 'warning' ? (
          <DialogTitle
            className='alert_title'
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              fontFamily: 'Kufam',
            }}>
            {title ? title : '¡Atención!'}
          </DialogTitle>
        ) : (
          <DialogTitle
            className='alert_title'
            style={{
              textAlign: 'center',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              fontFamily: 'Kufam',
            }}>
            {title}
          </DialogTitle>
        )}

        <DialogContent
          style={{
            // marginTop: '62px',
            overflowY: 'scroll',
            maxHeight: 'calc(100vh - 218px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '450px',
          }}>
          {children}

          {showButton && (
            <Stack
              component='section'
              direction='column'
              gap='12px'
              alignItems='center'>
              <ButtonBlue onClick={onClickButton} disabled={disabledButton}>
                {buttonText}
              </ButtonBlue>

              {type === 'bus' && showSecondButtom && (
                <ButtonBlue
                  design='secondary'
                  onClick={onSecondClickButton}
                  disabled={disabledButton}>
                  Después
                </ButtonBlue>
              )}
            </Stack>
          )}
        </DialogContent>
      </DialogStyles>
    )
  }

  return (
    <SwipeableDrawerStyles
      anchor='bottom'
      open={isShow}
      onClose={() => setShow(keepOpen)}
      onOpen={() => setShow(true)}
      disableSwipeToOpen={true}
      keepMounted={false}
      onTouchStart={(event) => {
        event.nativeEvent.defaultMuiPrevented = keepOpen
      }}
      transitionDuration={330}>
      <Box
        // onClick={() => setShow(true)}
        component='section'
        style={{
          fontFamily: 'Kufam',
          color: '#29348f',
          zIndex: 1400,
          width: '94%',
          padding: 22,
          paddingBottom: 'max(50px, env(safe-area-inset-bottom))',
          borderRadius: '25px 25px 0px 0px',
          backgroundColor: '#f6f6f6',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: -3 },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {/* <div className='panel_alert_container'> */}
        <section className={`icon-alert_container ${type}`}>
          {type === 'success' ? (
            <SuccessIcon />
          ) : type === 'reserve' ? (
            <SuccessReserveIcon />
          ) : type === 'error' ? (
            <ErrorIcon />
          ) : type === 'bus' ? (
            <div className='bus_container'>
              <img
                className='bus_img'
                src='/assets/images/logo_busi.png'
                alt='logo_busi'
              />
              <Bus />
            </div>
          ) : type === 'signout' ? (
            <SignOutIcon />
          ) : type === 'warning-orage' ? (
            <WarningOrangeIcon />
          ) : (
            <WarningIcon />
          )}
        </section>

        <Box
          style={{
            marginTop: '65px',
            overflowY: 'scroll',
            maxHeight: 'calc(100vh - 218px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '450px',
          }}
          // onClick={() => setShow(true)}
          className='body_card'>
          {type === 'warning' ? (
            <h2
              className='alert_title'
              style={{
                textAlign: 'center',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
              }}>
              {title ? title : '¡Atención!'}
            </h2>
          ) : (
            <h2
              className='alert_title'
              style={{
                textAlign: 'center',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
              }}>
              {title}
            </h2>
          )}

          {children}

          {showButton && (
            <Stack
              component='section'
              direction='column'
              gap='12px'
              alignItems='center'>
              <ButtonBlue onClick={onClickButton} disabled={disabledButton}>
                {buttonText}
              </ButtonBlue>

              {type === 'bus' && showSecondButtom && (
                <ButtonBlue
                  design='secondary'
                  onClick={onSecondClickButton}
                  disabled={disabledButton}>
                  Después
                </ButtonBlue>
              )}
            </Stack>
          )}
        </Box>
        {/* </div> */}
      </Box>
    </SwipeableDrawerStyles>
  )
}
