import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ArrowBack } from '../../icons/ArrowBack'
import { ButtonBlue } from '../../buttons'
// import { SendPaymentAlert } from '../../bottom-panel/Alert/SendPayment'
import { busiAPi } from '../../../utils/urls'

import {
  MethodPaymentCard,
  PagoMovilData,
  GetPaymentData,
  AllowBanks,
} from '../../ui/Cards/PagoMovil'

import { useStore, useAmountsStorage } from '../../../store'

import axios from 'axios'
import { Box, Divider, useMediaQuery } from '@mui/material'
import { Capacitor } from '@capacitor/core'

import { ErrorIcon, SuccessIcon } from '../../icons/AlertStatus'
import { ValidationDescountAmount } from '../../imputs/forms-validation-code'
import { PoweredByCredicard } from '../../icons/PoweredByCredicard'
import { Alert } from '../../bottom-panel/Alert'
// import './styles.payment.css'

export const Credito = () => {
  const { unregPassengersData, selectedTrip, ownerTravel, selectedTicket } =
    useStore()
  const minW800 = useMediaQuery('(min-width:800px)')

  const { totalUsd, completedBookedUsd, perTotalUsd } = useAmountsStorage()

  const navegate = useNavigate()
  const { state } = useLocation()
  const { paymentType } = useParams()

  const isReserve = useMemo(() => paymentType === 'reserve', [paymentType])

  const [page, setPage] = useState('info')

  const [invoice, setInvoice] = useState()
  const [ticket, setTicket] = useState()

  // const [show, setShow] = useState(false)
  const [showWarning, setShowWarning] = useState(true)

  const [disabled, setDisabled] = useState(true)
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  //region INPUTS SETTINGS
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const titular = watch('titular')
  const refTarget = watch('cardNumber')
  const cvc = watch('cvc')
  const cardDateMonth = watch('cardDateMonth')
  const cardDateYear = watch('cardDateYear')
  const account = watch('account')
  const currency = watch('currency')
  const pinKey = watch('pinKey')

  const codeWatch = watch('ciCode')
  // const banksWatch = watch('banks')
  const phoneWatch = watch('phone')
  const ciWatch = watch('ci')

  useEffect(() => {
    const isTitular = titular !== undefined && titular !== ''
    const isRefTarget = refTarget !== undefined && refTarget !== ''
    const isCvc = cvc !== undefined && cvc !== ''
    const isCardDateYear = cardDateYear !== undefined && cardDateYear !== ''
    const isCardDateMonth = cardDateMonth !== undefined && cardDateMonth !== ''
    const isCurrency = currency !== undefined && currency !== ''
    // const isBanks = banksWatch !== undefined && banksWatch !== ''

    const isCi = ciWatch !== undefined && ciWatch !== ''
    const isPhone = phoneWatch !== undefined && phoneWatch !== ''
    // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

    if (
      isTitular &&
      isRefTarget &&
      isCvc &&
      isCardDateMonth &&
      isCardDateYear &&
      isCurrency &&
      isCi &&
      // isBanks &&
      isPhone
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    account,
    cardDateYear,
    cardDateMonth,
    ciWatch,
    cvc,

    phoneWatch,
    pinKey,
    refTarget,
    titular,
    currency,
    // banksWatch,
  ])

  // region FUNCTIONS
  const goBack = () => {
    if (page === 'info') {
      navegate(isReserve ? '/main/reserve' : '/main/payment', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }

    if (page === 'verification') {
      setPage('info')
    }

    if (page === 'code') {
      setPage('verification')
    }
  }

  const handlePage = () => {
    if (page === 'info') {
      setPage('verification')
    }
    // if (page === 'verification') {
    //   setShow(true)
    // }
  }

  // region Submit
  const onSubmit = async (data) => {
    // const phoneNumber = `${data.code.slice(1)}${data.phone}`

    const currency = data.currency.value

    if (currency !== 'VED' && page === 'verification') {
      const body = {
        cardOwner: data.titular,
        cardNumber: data.cardNumber, //max 19 digitos
        documentId: `${data.ciCode}${data.ci}`,
        expirationMonth: data.cardDateMonth,
        expirationYear: data.cardDateYear,
        cvc: data.cvc,
        currency,
        cardType: 'CREDIT_INTERNATIONAL',
        phoneNumber: `${data.code.slice}-${data.phone}`,
        // bankCode: data.banks,
      }

      try {
        setLoading(true)
        const { data: info } = await axios.post(
          `${busiAPi}/ticket/credicard-otp-code`,
          { ...body },
          { headers: { Authorization: localStorage.getItem('token') } },
        )

        if (info.success) {
          setLoading(false)
          setErr(null)

          if (info?.data?.card === 'VALIDATED') {
            buyTicket(data)
          } else {
            setPage('code')
          }
        }
      } catch (error) {
        console.log(error)
      }
      return
    }

    buyTicket(data)
    // setPage('result')
    return
  }

  const buyTicket = async (data) => {
    const country = data?.currency?.label?.split(' - ')[0]
    const completedBooked = state?.status === 'booked'
    const currency = data.currency.value

    const paymentData = {
      currency, //default
      phoneNumber: `${data.code.slice(1)}-${data.phone}`,
      documentId: `${data.ciCode}-${data.ci}`,
      documentType: data.ciCode === 'J' ? 'RIF' : 'CI',
      cardNumber: data.cardNumber, //max 19 digitos
      cardOwner: data.titular,
      cardType: 'CREDIT',
      cvc: data.cvc,
      expirationMonth: data.cardDateMonth,
      expirationYear: data.cardDateYear,
      country, //default
      amount: completedBooked
        ? completedBookedUsd
        : isReserve
        ? perTotalUsd
        : totalUsd, // Siempre en Bs y es el valor pagado del ticket, si es reserva es distinto al price que es el costo total del ticket
      bank_card_validation: {
        token: data.descountAmount,
        rif: `${data.ciCode}-${data.ci}`, //same as document id
        phone: `${data.code.slice(1)}-${data.phone}`, //same as phone number
      },
    }

    const passengers = completedBooked
      ? selectedTicket.passengers
      : unregPassengersData

    const body = {
      // ticketId: selectedTicket.id,
      routeId: completedBooked ? selectedTicket.routeId : selectedTrip.routeId,
      subRouteId: completedBooked
        ? selectedTicket.subRouteId
        : selectedTrip.subRouteId,
      isPassengerTraveling: completedBooked
        ? selectedTicket.isOwnerTraveling
        : ownerTravel,
      unregisterPassengers: passengers,
      paymentData,
      isBooking: isReserve,
      date: completedBooked
        ? selectedTicket.dateByDay.format('MM/DD/YYYY')
        : selectedTrip.dateServer,
      currency: 'Bs',
      price: totalUsd - selectedTrip.busStationExitRate * passengers?.length,
      paidAmount: totalUsd,
      platform: Capacitor.getPlatform(),
    }

    try {
      setLoading(true)
      const { data: info } = await axios.post(
        `${busiAPi}/ticket`,
        { ...body },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setLoading(false)
        setErr(null)

        setInvoice(info?.data?.invoice)
        setTicket(info?.data?.ticket)
        setPage('result')
      } else {
        setLoading(false)
        setErr('Tenemos un problema, intenta más tarde')
      }
    } catch (error) {
      setLoading(false)
      const errMain = error?.response?.data?.error

      if (errMain?.message === 'NEGADA') {
        setErr(errMain?.message)
        setInvoice(null)
        setPage('result')
        return
      }

      if (errMain?.errorCode === 1103) {
        setErr(errMain?.message)
        return
      }
      setErr('Tenemos un problema, intenta más tarde')
    }
    return
  }

  return (
    <section
      className='payment_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',

              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }
      }>
      {page !== 'result' && (
        <div
          onClick={goBack}
          className='payment-header_container'
          style={{ maxWidth: '600px', width: '100%' }}>
          <ArrowBack />

          <h2>
            {page === 'choose'
              ? 'Escoger método de pago'
              : page === 'info'
              ? 'Datos para el pago'
              : 'Datos para verificación'}
          </h2>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: 'calc(100% - 53px)',
          maxWidth: '600px',
          width: '100%',
        }}>
        <section
          className='payment-body_container'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            height: 'height: calc(100% - 86px)',
          }}>
          {page === 'info' ? (
            <>
              <MethodPaymentCard title={'Tarjeta de Crédito'} />

              <PagoMovilData
                isCompleteBooked={state?.status === 'booked'}
                mobilePayment={false}
                isReserve={isReserve}
              />

              <AllowBanks type={'credito'} />
            </>
          ) : page === 'verification' ? (
            <>
              <MethodPaymentCard title={'Tarjeta de Crédito'} />

              <GetPaymentData
                register={register}
                setValue={setValue}
                errors={errors}
                control={control}
                codeWatch={codeWatch}
                dataType={'credito'}
                mobileType={currency?.value !== 'VED' ? 'international' : 've'}
              />

              {err && (
                <p
                  className='error_message'
                  style={{ marginTop: '6px', marginLeft: '4px' }}>
                  {err}
                </p>
              )}
            </>
          ) : page === 'code' ? (
            <>
              <Box
                className='payment_info_container'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}>
                <h3
                  style={{
                    fontSize: 14,
                    // fontFamily: 'Kufam',
                    // fontWeight: '700',
                    wordWrap: 'break-word',
                  }}>
                  Ingresa en tu banco y verifica el monto descontado en tu
                  cuenta
                </h3>

                <p
                  style={{
                    fontSize: 12,
                  }}>
                  Este monto se reversará a tu cuenta una vez completada la
                  transacción
                </p>

                <ValidationDescountAmount
                  label={'Monto descontado'}
                  error={errors.descountAmount?.message}
                  control={control}
                  nameId='descountAmount'
                  type='string'
                />
              </Box>
            </>
          ) : (
            page === 'result' && (
              <>
                <Box
                  className='payment_info_container'
                  style={{
                    background: '#29348F',
                    borderRadius: '34px 34px 2px 2px',
                  }}>
                  <div className='flex-center'>
                    {invoice?.reference ? (
                      <SuccessIcon width='80' height='80' />
                    ) : (
                      <ErrorIcon width='80' height='80' />
                    )}
                  </div>

                  <Divider
                    style={{ borderColor: '#8F9BFF', margin: '10px 0px' }}
                  />

                  <h2
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      fontSize: 16,
                    }}>
                    {invoice?.reference ? 'Aprobado' : 'Rechazado'}
                  </h2>
                </Box>

                <Box
                  className='payment_info_container'
                  style={{ borderRadius: '2px 2px 34px 34px' }}>
                  {err ? (
                    <>
                      <h3 style={{ textAlign: 'center' }}>{err}</h3>
                    </>
                  ) : (
                    <>
                      <Box style={{ marginBottom: 20 }}>
                        <p
                          className='result-payment_p'
                          style={{ textAlign: 'center' }}>
                          {`${invoice?.bank} (${codeWatch}${ciWatch})`}
                        </p>

                        <h2 style={{ textAlign: 'center', fontSize: 16 }}>
                          RECIBO DE COMPRA
                        </h2>
                        <h3 style={{ textAlign: 'center', fontSize: 16 }}>
                          {invoice?.cardEmitter}
                        </h3>
                      </Box>

                      <p className='result-payment_p'>Adquiriente</p>
                      {/* <p className='result-payment_p'>Dirección</p> */}
                      <p className='result-payment_p'>
                        RIF: {codeWatch} {ciWatch}
                        {/* Afiliado: J00000000 */}
                      </p>
                      <p className='result-payment_p'>
                        Terminal: {invoice?.terminal} LOTE: {invoice?.lote}
                      </p>

                      <p className='result-payment_p'>
                        <b>{invoice?.cardNumber}</b>
                      </p>

                      <p className='result-payment_p'>
                        {Intl.DateTimeFormat('es-VE', {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                        })
                          .format(new Date(invoice?.transactionDate))
                          .replaceAll('de ', '')
                          .replaceAll(', ', ', Hora: ')}
                      </p>
                      <p className='result-payment_p'>
                        Aprob. {invoice.approvalId} REF: {invoice?.reference}{' '}
                        Trace: {invoice?.trace}
                      </p>

                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: 20,
                        }}>
                        <p className='result-payment_p'>
                          <b>Monto</b>
                        </p>

                        <p className='result-payment_p'>
                          <b>{invoice?.amount}</b>
                        </p>
                      </Box>

                      <p
                        className='result-payment_p'
                        style={{ textAlign: 'center', marginTop: 10 }}>
                        No se requiere firma
                      </p>
                    </>
                  )}
                </Box>
              </>
            )
          )}
        </section>

        <div
          className='action_button_container'
          style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <PoweredByCredicard />

          {page === 'verification' || page === 'code' ? (
            <ButtonBlue type='submit' disabled={loading || disabled}>
              Continuar
            </ButtonBlue>
          ) : page === 'result' ? (
            <>
              {err ? (
                <>
                  <ButtonBlue
                    design='secondary'
                    onClick={() => setPage('code')}>
                    Atrás
                  </ButtonBlue>

                  <ButtonBlue onClick={() => navegate(`/main/ticket`)}>
                    Finalizar
                  </ButtonBlue>
                </>
              ) : (
                <ButtonBlue
                  onClick={() =>
                    navegate(`/main/ticket/detail/${ticket.id}`, {
                      state: { chooseSeats: true },
                    })
                  }>
                  Ver boleto digital
                </ButtonBlue>
              )}
            </>
          ) : (
            <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
          )}
        </div>
      </form>

      {/* <SendPaymentAlert
        isShow={show}
        setShow={setShow}
        type={state?.status === 'booked' ? 'completedBooked' : 'buy'}
        keepOpen
      /> */}

      <Alert
        isShow={showWarning}
        setShow={setShowWarning}
        type='warning'
        title='Credito Internacional'
        buttonText='Ok'
        onClickButton={() => {
          setShowWarning(false)
        }}>
        <p style={{ textAlign: 'center' }}>
          El pago con tarjeta de credito internacional está deshabilitado por el
          momento. Te pedimos disculpas por las molestias que esto pueda
          ocasionar.
        </p>
      </Alert>
    </section>
  )
}
