export const CashIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'>
      <g clipPath='url(#clip0_4734_1238)'>
        <path
          d='M27.6562 4.6875H2.34375C1.72215 4.6875 1.12601 4.93443 0.686468 5.37397C0.24693 5.81351 0 6.40965 0 7.03125L0 22.9688C0 23.5904 0.24693 24.1865 0.686468 24.626C1.12601 25.0656 1.72215 25.3125 2.34375 25.3125H27.6562C28.2779 25.3125 28.874 25.0656 29.3135 24.626C29.7531 24.1865 30 23.5904 30 22.9688V7.03125C30 6.40965 29.7531 5.81351 29.3135 5.37397C28.874 4.93443 28.2779 4.6875 27.6562 4.6875ZM2.34375 7.03125H6.09375C6.06768 7.49458 5.9494 7.94805 5.74584 8.36508C5.54228 8.78212 5.25754 9.15434 4.90829 9.45992C4.55905 9.76551 4.15233 9.99833 3.71196 10.1447C3.2716 10.2911 2.80644 10.3482 2.34375 10.3125V7.03125ZM2.34375 22.9688V19.6875C2.80644 19.6518 3.2716 19.7089 3.71196 19.8553C4.15233 20.0017 4.55905 20.2345 4.90829 20.5401C5.25754 20.8457 5.54228 21.2179 5.74584 21.6349C5.9494 22.052 6.06768 22.5054 6.09375 22.9688H2.34375ZM27.6562 22.9688H24C24.0533 22.0501 24.4675 21.1897 25.1523 20.5751C25.8372 19.9605 26.7372 19.6414 27.6562 19.6875V22.9688ZM27.6562 17.3438C26.8928 17.3187 26.132 17.4444 25.4172 17.7136C24.7024 17.9828 24.0476 18.3903 23.4904 18.9127C22.9332 19.4351 22.4844 20.0622 22.1697 20.7582C21.8549 21.4541 21.6805 22.2053 21.6562 22.9688H8.34375C8.31952 22.2053 8.14506 21.4541 7.83034 20.7582C7.51562 20.0622 7.06682 19.4351 6.50958 18.9127C5.95235 18.3903 5.29762 17.9828 4.58281 17.7136C3.86801 17.4444 3.10716 17.3187 2.34375 17.3438V12.6562C3.10716 12.6813 3.86801 12.5556 4.58281 12.2864C5.29762 12.0172 5.95235 11.6097 6.50958 11.0873C7.06682 10.5649 7.51562 9.93779 7.83034 9.24182C8.14506 8.54586 8.31952 7.79468 8.34375 7.03125H21.6562C21.6805 7.79468 21.8549 8.54586 22.1697 9.24182C22.4844 9.93779 22.9332 10.5649 23.4904 11.0873C24.0476 11.6097 24.7024 12.0172 25.4172 12.2864C26.132 12.5556 26.8928 12.6813 27.6562 12.6562V17.3438ZM27.6562 10.3125C26.7372 10.3586 25.8372 10.0395 25.1523 9.42492C24.4675 8.8103 24.0533 7.94992 24 7.03125H27.6562V10.3125Z'
          fill='#29348F'
        />
        <path
          d='M15 9.37519C14.2366 9.35017 13.4757 9.47585 12.7609 9.74506C12.0461 10.0143 11.3914 10.4217 10.8342 10.9441C10.2769 11.4665 9.82813 12.0937 9.51341 12.7896C9.19869 13.4856 9.02423 14.2368 9 15.0002C9.02423 15.7636 9.19869 16.5148 9.51341 17.2108C9.82813 17.9067 10.2769 18.5338 10.8342 19.0562C11.3914 19.5787 12.0461 19.9861 12.7609 20.2553C13.4757 20.5245 14.2366 20.6502 15 20.6252C15.7634 20.6502 16.5243 20.5245 17.2391 20.2553C17.9539 19.9861 18.6086 19.5787 19.1658 19.0562C19.7231 18.5338 20.1719 17.9067 20.4866 17.2108C20.8013 16.5148 20.9758 15.7636 21 15.0002C20.9758 14.2368 20.8013 13.4856 20.4866 12.7896C20.1719 12.0937 19.7231 11.4665 19.1658 10.9441C18.6086 10.4217 17.9539 10.0143 17.2391 9.74506C16.5243 9.47585 15.7634 9.35017 15 9.37519ZM15 18.2814C14.0809 18.3275 13.1809 18.0085 12.4961 17.3939C11.8112 16.7792 11.397 15.9189 11.3438 15.0002C11.397 14.0815 11.8112 13.2211 12.4961 12.6065C13.1809 11.9919 14.0809 11.6729 15 11.7189C15.4627 11.6833 15.9278 11.7403 16.3682 11.8867C16.8086 12.0331 17.2153 12.2659 17.5645 12.5715C17.9138 12.8771 18.1985 13.2493 18.4021 13.6664C18.6056 14.0834 18.7239 14.5369 18.75 15.0002C18.7239 15.4635 18.6056 15.917 18.4021 16.334C18.1985 16.7511 17.9138 17.1233 17.5645 17.4289C17.2153 17.7345 16.8086 17.9673 16.3682 18.1137C15.9278 18.2601 15.4627 18.3171 15 18.2814Z'
          fill='#29348F'
        />
      </g>
      <defs>
        <clipPath id='clip0_4734_1238'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
