import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ArrowBack } from '../../icons/ArrowBack'
import { ButtonBlue } from '../../buttons'
import { SendPaymentAlert } from '../../bottom-panel/Alert/SendPayment'
import { busiAPi } from '../../../utils/urls'

import {
  MethodPaymentCard,
  PagoMovilData,
  GetPaymentData,
  RemiderPaymentCard,
} from '../../ui/Cards/PagoMovil'

import { useStore, useAmountsStorage } from '../../../store'

import axios from 'axios'
import { useMediaQuery } from '@mui/material'
import { Capacitor } from '@capacitor/core'

export const PagoBNC = () => {
  const { unregPassengersData, selectedTrip, ownerTravel, selectedTicket } =
    useStore()
  const { totalUsd, completedBookedUsd } = useAmountsStorage()
  const minW800 = useMediaQuery('(min-width:800px)')

  const navegate = useNavigate()
  const { state } = useLocation()
  const { paymentType } = useParams()

  const isReserve = useMemo(() => paymentType === 'reserve', [paymentType])

  const [page, setPage] = useState('info')
  const [show, setShow] = useState(false)

  const [disabled, setDisabled] = useState(true)
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  //region INPUTS SETTINGS
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const phoneWatch = watch('phone')
  // const phoneCodeWatch = watch('code')
  const banksWatch = watch('banks')
  const ciWatch = watch('ci')
  const refNumberWatch = watch('refNumber')
  const codeWatch = watch('ciCode')

  useEffect(() => {
    const isBanks = banksWatch !== undefined && banksWatch !== ''
    const isRefNumber = refNumberWatch !== undefined && refNumberWatch !== ''
    const isPhone = phoneWatch !== undefined && phoneWatch !== ''
    // const isPhoneCode = codeWatch !== undefined && codeWatch !== ''
    const isCi = ciWatch !== undefined && ciWatch !== ''
    // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

    if (isBanks && isRefNumber && isPhone && isCi) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [phoneWatch, banksWatch, ciWatch, refNumberWatch])

  // region FUNCTIONS
  const goBack = () => {
    // if (page === 'choose') {
    //   navegate(state.previousPath)
    // }

    if (page === 'info') {
      navegate('/main/payment', {
        state: { previousPath: state?.previousPath },
      })
    }

    if (page === 'verification') {
      setPage('info')
    }

    if (page === 'code') {
      setPage('verification')
    }
  }

  const handlePage = () => {
    if (page === 'choose') {
      setPage('info')
    }

    if (page === 'info') {
      setPage('verification')
    }
    // if (page === 'verification') {
    //   setShow(true)
    // }
  }

  // region Submit
  const onSubmit = async (data) => {
    const completedBooked = state?.status === 'booked'

    // const phoneNumber = `${data.code.slice(1)}${data.phone}`

    // const isDetail = state.previousPath.includes('ticket/detail')
    const paymentData = {
      bankName: data.banks,
      phoneNumber: `58-${data.code.slice(1)}-${data.phone}`,
      documentId: `${data.ciCode}-${data.ci}`,
      referenceNumber: data.refNumber?.toString(),
      amount: completedBooked ? completedBookedUsd : totalUsd,
      type: 'mobile',
    }

    const fullBody = {
      routeId: completedBooked ? selectedTicket.routeId : selectedTrip.routeId,
      subRouteId: completedBooked
        ? selectedTicket.subRouteId
        : selectedTrip.subRouteId,
      isPassengerTraveling: completedBooked
        ? selectedTicket.isOwnerTraveling
        : ownerTravel,
      unregisterPassengers: completedBooked
        ? selectedTicket.passengers
        : unregPassengersData,
      paymentData,
      date: completedBooked
        ? selectedTicket.dateByDay.format('MM/DD/YYYY')
        : selectedTrip.dateServer,
      currency: 'Bs',
      price: totalUsd,
      platform: Capacitor.getPlatform(),
    }

    const fullBodyBooked = {
      ticketId: selectedTicket.id,
      paymentData,
      currency: 'Bs',
      platform: Capacitor.getPlatform(),
    }

    try {
      setLoading(true)
      const { data: info } = await axios.post(
        completedBooked
          ? `${busiAPi}/ticket/complete-purchase`
          : `${busiAPi}/ticket`,
        completedBooked ? { ...fullBodyBooked } : { ...fullBody },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setLoading(false)
        setShow(true)
      } else {
        setLoading(false)
        setErr('Tenemos un problema, intenta más tarde')
      }
    } catch (error) {
      setLoading(false)
      const errMain = error?.response?.data?.error
      console.log({ errMain })
      if (errMain?.errorCode === 1103) {
        setErr(errMain?.message)
        return
      }
      setErr('Tenemos un problema, intenta más tarde')
    }
  }

  return (
    <section
      className='payment_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',

              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }
      }>
      {page !== 'result' && (
        <div
          onClick={goBack}
          className='payment-header_container'
          style={{ maxWidth: '600px', width: '100%' }}>
          <ArrowBack />

          <h2>
            {page === 'info' ? 'Datos para el pago' : 'Datos para verificación'}
          </h2>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: 'calc(100% - 53px)',
          maxWidth: '600px',
          width: '100%',
        }}>
        <section
          className='payment-body_container'
          style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {
            page === 'info' && (
              <>
                <MethodPaymentCard title={'Pago móvil a BNC'} />

                <PagoMovilData
                  isCompleteBooked={state?.status === 'booked'}
                  mobilePayment={true}
                />

                <GetPaymentData
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  codeWatch={codeWatch}
                  dataType={'mobile'}
                />
                {err && (
                  <p
                    className='error_message'
                    style={{ marginTop: '6px', marginLeft: '4px' }}>
                    {err}
                  </p>
                )}
                {/* <RemiderPaymentCard /> */}
              </>
            )
            // : (
            //   page === 'verification' && (
            //     <>
            //       <MethodPaymentCard title={'Pago móvil a BNC'} />
            //     </>
            //   )
            // )
          }
        </section>

        {page === 'info' || page === 'code' ? (
          <div className='action_button_container'>
            <ButtonBlue type='submit' disabled={loading || disabled}>
              Continuar
            </ButtonBlue>
          </div>
        ) : page === 'result' ? (
          <div
            className='action_button_container'
            style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {true && (
              <ButtonBlue design='secondary' onClick={() => setPage('code')}>
                Atrás
              </ButtonBlue>
            )}
            <ButtonBlue onClick={() => navegate('/main/ticket')}>
              Ver boleto digital
            </ButtonBlue>
          </div>
        ) : (
          <div className='action_button_container'>
            <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
          </div>
        )}
      </form>

      <SendPaymentAlert
        isShow={show}
        setShow={setShow}
        type={state?.status === 'booked' ? 'completedBooked' : 'buy'}
        keepOpen
      />

      {/* <Alert
        isShow={showPaymentConfirn}
        setShow={setShowPaymentConfirn}
        type='warning'
        title='Confirmación de pago'
        buttonText='Pagar'
        onClickButton={() => {
          setPage('code')
          setShowPaymentConfirn(false)
        }}>
        <p style={{ textAlign: 'center' }}>
          La transacción tiene un costo de <b>0,30% adicional</b> sobre la
          transacción, pagando un total de <b>Bs. 1234,60</b>
        </p>

        <CheckboxUI label='¿Acepta el costo de la operación?' />
      </Alert> */}
    </section>
  )
}
