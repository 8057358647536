import React from 'react'
import { AirIcon } from '../../icons/Services/Air'
import { WifiIcon } from '../../icons/Services/Wifi'

import { PetsIcon } from '../../icons/Services/Pets'
import { BagIcon } from '../../icons/Services/Bag'
import { PerSeat } from '../../icons/Services/PerSeat'
import { OneWay, RoundTrip } from '../../icons/Services/RouteType'

import './styles.css'
import { SeatsOutLine } from '../../icons/Services/Seats'
/**
 * @param {object} props
 * @param {string} props.path - props path to service icon.
 * @param {string} props.text - props to show service text.
 * @returns {React.JSX.Element}
 */
export default function Service({ path = '', text = '' }) {
  return (
    <div className='ticket-service-details'>
      {path === 'air' ? (
        <AirIcon />
      ) : path === 'wifi' ? (
        <WifiIcon />
      ) : path === 'pet' ? (
        <PetsIcon />
      ) : path === 'perSeat' ? (
        <PerSeat />
      ) : path === 'bag' ? (
        <BagIcon />
      ) : path === 'one_way' ? (
        <OneWay />
      ) : path === 'two_way' ? (
        <RoundTrip />
      ) : path === 'seats' ? (
        <SeatsOutLine />
      ) : (
        <img
          className='ticket-service-details-image'
          src={`/assets\\${path}.svg`}
          alt='ticket-service-details'
        />
      )}

      <p className='ticket-service-details-text'>{text}</p>
    </div>
  )
}
