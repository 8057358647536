import React from 'react'

export const HelpIcon = ({ width = '13', height = '21', fill = '#FFC600' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 13 21'
      fill='none'>
      <path
        d='M5.81482 15.7534C5.39853 15.7534 5.06187 15.4167 5.06187 15.0005C5.06187 14.0156 5.20156 13.1649 5.47955 12.4496C5.68351 11.9104 6.01459 11.367 6.46999 10.818C6.80526 10.4185 7.40595 9.83453 8.27485 9.069C9.14375 8.30208 9.70812 7.69161 9.96935 7.2362C10.2306 6.7808 10.3591 6.28348 10.3591 5.74426C10.3591 4.76779 9.97773 3.91146 9.2164 3.17247C8.45506 2.43349 7.5205 2.06469 6.41411 2.06469C5.34545 2.06469 4.4528 2.39996 3.73756 3.0691C3.2151 3.55803 2.82256 4.24813 2.56272 5.13938C2.38391 5.75125 1.78741 6.14239 1.1546 6.06696C0.352747 5.97196 -0.1753 5.17849 0.0537996 4.40319C0.405831 3.21159 0.995344 2.25188 1.82094 1.52268C2.96784 0.507093 4.48493 0 6.37221 0C8.36984 0 9.96377 0.543413 11.154 1.63164C12.3442 2.71986 12.9379 4.03439 12.9379 5.57663C12.9379 6.46928 12.7283 7.29208 12.3106 8.04364C11.893 8.7966 11.0743 9.7116 9.8576 10.79C9.04038 11.5151 8.50535 12.0501 8.2539 12.3937C8.00245 12.7374 7.81665 13.1327 7.69651 13.5784C7.60711 13.9094 7.54564 14.3956 7.51212 15.034C7.49116 15.4363 7.15729 15.752 6.75497 15.752H5.81482V15.7534ZM4.92217 19.3575C4.92217 18.5683 5.56197 17.9285 6.35125 17.9285C7.14053 17.9285 7.78033 18.5683 7.78033 19.3575C7.78033 20.1468 7.14053 20.7866 6.35125 20.7866C5.56197 20.7866 4.92217 20.1468 4.92217 19.3575Z'
        fill={fill}
      />
    </svg>
  )
}
