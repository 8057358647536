// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgotten-password_container {
  display: flex;
  align-items: center;
  justify-content: center;
  .forgotten-password {
    color: #3db3e3;
    font-family: Kufam;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    text-decoration: underline;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-forgotten-password/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,0BAA0B;EAC5B;AACF","sourcesContent":[".forgotten-password_container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .forgotten-password {\n    color: #3db3e3;\n    font-family: Kufam;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    margin: 0;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
