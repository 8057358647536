// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-next-date_container {
  margin-bottom: 15px;
  .subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin: 10px 0px;
  }

  .date_card_container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .date_card {
      background: #e7e9f3;
      display: flex;
      align-items: center;
      border-radius: 10px;
      padding: 15px;
      gap: 15px;
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        /* height: 15px; */
        margin: 0;
      }
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/bottom-panel/Alert/NextDate/styles.nextdate.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,QAAQ;;IAER;MACE,mBAAmB;MACnB,aAAa;MACb,mBAAmB;MACnB,mBAAmB;MACnB,aAAa;MACb,SAAS;MACT;QACE,eAAe;QACf,gBAAgB;QAChB,iBAAiB;QACjB,mBAAmB;QACnB,gBAAgB;QAChB,kBAAkB;QAClB,SAAS;MACX;IACF;EACF;AACF","sourcesContent":[".select-next-date_container {\n  margin-bottom: 15px;\n  .subtitle {\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 21px;\n    letter-spacing: 0em;\n    text-align: center;\n    margin: 10px 0px;\n  }\n\n  .date_card_container {\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n\n    .date_card {\n      background: #e7e9f3;\n      display: flex;\n      align-items: center;\n      border-radius: 10px;\n      padding: 15px;\n      gap: 15px;\n      p {\n        font-size: 13px;\n        font-weight: 400;\n        line-height: 13px;\n        letter-spacing: 0em;\n        text-align: left;\n        /* height: 15px; */\n        margin: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
