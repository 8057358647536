import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

// import { Share } from '@capacitor/share'
// import { Filesystem, Directory } from '@capacitor/filesystem'
// import { Screenshot } from 'capacitor-screenshot'

import { Box, Divider, Slide, useMediaQuery } from '@mui/material'
// import ShareIcon from '@mui/icons-material/Share'

import { Info } from '../../components/Tickets/Info'
import { TicketContainer } from '../../components/Tickets/Container'
import { Status } from '../../components/Tickets/Status'
import { ButtonBlue } from '../../components/buttons'
import { CodeQR } from '../../components/CodeQR'
import { ArrowBack } from '../../components/icons/ArrowBack'
import { Loader } from '../../components/ui/Loader'
import { ErrorPage } from '../../components/Error'
import { WarningModal } from '../../components/bottom-panel/Alert/WarningInfo'

import { useAmountsStorage } from '../../store'

import { useGetExchains } from '../../hooks/useExchain'

// import { Baby, Elder } from '../../components/icons/Passengers'
import { InternationalYellowCard } from '../../components/ui/Cards/Info'
import { Alert } from '../../components/bottom-panel/Alert'

import { useGetSelectedTicket } from '../../hooks/useGetUserTickets'
import { ContactLine } from '../../components/buttons/ContactLine'
import { useGetPaymentRemaining } from '../../hooks/usePaymentRemain'
import { SuccessIcon } from '../../components/icons/AlertStatus'
import './styles.detail.css'
import { localUsdRate } from '../../utils/usdRate'

const TicketDetailPage = () => {
  const { data: res } = useGetExchains({
    Authorization: localStorage.getItem('token'),
  })
  const minW800 = useMediaQuery('(min-width:800px)')

  const { state, pathname } = useLocation()
  const { ticketId } = useParams()
  const navegate = useNavigate()

  const [isWarning, setIsWarning] = useState(false)
  const [showIntertional, setShowInternational] = useState(false)

  const [totalPrice, setTotalPrice] = useState('0')
  const [exitRate, setExitRate] = useState(0)
  const [totalPriceBs, setTotalPriceBs] = useState(0)
  const [exitRateBs, setExitRateBs] = useState(0)

  // const [totalPriceDes, setTotalPriceDes] = useState('0')

  const { selectedTicket, setSelectedTickets, setDestination } =
    useGetSelectedTicket({ id: ticketId, retry: state?.chooseSeats })
  const { dolarRate, setDolarRate } = useAmountsStorage()

  const { data: remainData } = useGetPaymentRemaining(selectedTicket?.id)

  // #region EFFECTS

  useEffect(() => {
    if (!selectedTicket?.passengers) return

    const isExit =
      Number(selectedTicket?.exitRate) === 0
        ? 0
        : Number(Number(selectedTicket?.exitRate).toFixed(2))

    const totalExitRate = isExit * seatForPassengers(selectedTicket?.passengers)

    const totalUsd = selectedTicket?.paidAmount

    // const totalUsd = selectedTicket.passengers
    //   .map((value) => {
    //     if (
    //       Object.keys(value).includes('isUnderageSeated') &&
    //       !value.isUnderageSeated
    //     ) {
    //       return 0
    //     }

    //     if (value.type === 'senior') {
    //       return selectedTicket.elderlyPrice + isExit ?? 0
    //     }

    //     return selectedTicket.priceUsd + isExit
    //   })
    //   .reduce((a, b) => a + b, 0)

    // const totalUsdDes = selectedTicket.passengers
    //   .map(({ ticketPrice }) => ticketPrice)
    //   .reduce((a, b) => a + b, 0)

    // setTotalPriceDes(totalUsdDes)

    setTotalPrice(totalUsd)
    setExitRate(totalExitRate)

    if (res?.success && res?.data.length > 0) {
      const usdBcv = res?.data?.find(({ key }) => key === 'EXCHANGE_RATE_BS')
      if (usdBcv) {
        const dolarValue = Number(usdBcv?.value)
        setDolarRate(dolarValue?.toFixed(2))

        const bsVal = dolarValue * totalUsd
        setTotalPriceBs(bsVal)
        setExitRateBs(dolarValue * totalExitRate)
      } else {
        const usdRate = localStorage.getItem('usdRate')
        const usdValue = usdRate ? usdRate : localUsdRate

        const dolarValue = Number(usdValue)
        setDolarRate(dolarValue?.toFixed(2))

        const bsVale = dolarValue * totalUsd
        setTotalPriceBs(bsVale)
        setExitRateBs(dolarValue * totalExitRate)
      }
    }
  }, [selectedTicket, res, setDolarRate])

  // #region FUNCTIONS
  const handleBooked = () => {
    setSelectedTickets({
      ...selectedTicket,
      dolarRate: dolarRate,
    })

    navegate('/main/payment', {
      state: { previousPath: pathname, status: selectedTicket.status },
    })
  }

  const seatForPassengers = (passengers = []) => {
    const passengersSeats = passengers.filter(
      (pass) => pass.isUnderageSeated === true,
    )

    return passengersSeats.length
  }

  const fullName = (fullName = '') => {
    const fullNameSplit = fullName.split(' ')

    if (fullNameSplit.length > 2) {
      const name = fullName.split(' ')[0]
      const lastName = fullName.split(' ')[3]

      return lastName === undefined ? `${name}` : `${name} ${lastName}`
    }

    return fullName
  }

  // const handleShared = async () => {
  //   // Take the screenshot
  //   try {
  //     const ret = await Screenshot.take()
  //     // const capturedImage = `data:image/png;base64,${ret.base64}`

  //     const data = await Filesystem.writeFile({
  //       path: 'ticket.png',
  //       data: ret.base64,
  //       directory: Directory.Cache,
  //     })

  //     // Compartir la imagen capturada como URL
  //     await Share.share({
  //       title: 'Compartir pantalla',
  //       text: 'Echa un vistazo a esta imagen capturada',
  //       url: data.uri,
  //       dialogTitle: 'Compartir imagen',
  //     })

  //     await Filesystem.deleteFile({
  //       path: 'ticket.png',
  //       directory: Directory.Cache,
  //     })
  //   } catch (error) {
  //     console.error('Error al capturar y compartir pantalla:', error)
  //   }
  // }

  // console.log({ selectedTicket, state })
  if (Object.keys(selectedTicket).length === 0) {
    return <Loader />
  }

  if (!selectedTicket || !selectedTicket?.passengers) {
    return <ErrorPage />
  }

  // #region MOBILE HTML
  return (
    <>
      <Slide direction='up' in={true} timeout={200}>
        <section
          className='ticket-detail_container'
          style={
            minW800
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'calc(100% - 134px)',
                  minHeight: '650px',
                  bottom: 0,
                  borderRadius: 0,
                  background: 'transparent',
                }
              : {
                  display: 'flex',
                  justifyContent: 'center',
                }
          }>
          <Box
            style={{
              maxWidth: '600px',
              height: '100%',
              width: '100%',
              // overflowY: minW800 ? 'scroll' : 'auto',
            }}>
            <Box
              className='ticket-detail_header_container'
              onClick={() => navegate(state?.previousPath || '/main/ticket')}>
              <ArrowBack />

              <h2>Detalle del boleto</h2>
            </Box>

            <section className='container_tickets'>
              <TicketContainer type='head'>
                <div
                  className='ticket-detail_header'
                  style={{ flexDirection: 'row', justifyContent: 'normal' }}>
                  <section className='ticket-detail_header_info'>
                    <div onClick={() => setIsWarning(true)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'>
                        <path
                          d='M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM9.99 8C10.548 8 11 8.452 11 9.01V14.134C11.1906 14.2441 11.3396 14.414 11.4238 14.6173C11.5081 14.8207 11.5229 15.0462 11.4659 15.2588C11.4089 15.4714 11.2834 15.6593 11.1087 15.7933C10.9341 15.9273 10.7201 16 10.5 16H10.01C9.87737 16 9.74603 15.9739 9.62349 15.9231C9.50095 15.8724 9.38961 15.798 9.29582 15.7042C9.20203 15.6104 9.12764 15.499 9.07688 15.3765C9.02612 15.254 9 15.1226 9 14.99V10C8.73478 10 8.48043 9.89464 8.29289 9.70711C8.10536 9.51957 8 9.26522 8 9C8 8.73478 8.10536 8.48043 8.29289 8.29289C8.48043 8.10536 8.73478 8 9 8H9.99ZM10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73478 7 9.48043 6.89464 9.29289 6.70711C9.10536 6.51957 9 6.26522 9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5Z'
                          fill='#29348F'
                        />
                      </svg>
                    </div>

                    {/* <div>
                      <h3 className='ticket-detail_price'>Ref {totalPrice}</h3>
                      <h3 className='ticket-detail_ref'>
                        Bs.{' '}
                        {new Intl.NumberFormat('es-VE', {
                          currency: 'USD',
                        }).format(totalPriceBs?.toFixed(2))}
                      </h3>

                      {totalPriceDes !== totalPrice && (
                      <p className='ticket-detail_ref discount'>
                        Ref {totalPrice}
                      </p>
                    )}
                    </div> */}
                  </section>

                  <h1 className='ticket-detail_title'>
                    {selectedTicket.busLine}
                  </h1>
                </div>

                <Info
                  date={selectedTicket.date}
                  startTime={selectedTicket.startTime}
                  endTime={selectedTicket.endTime}
                  startLocation={selectedTicket.startLocation}
                  endLocation={selectedTicket.endLocation}
                  routeType={selectedTicket?.routeType}
                  type='detail'
                />

                {selectedTicket?.children?.length > 0 && (
                  <section
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <div
                      style={{
                        width: '75%',
                      }}
                      onClick={() => setShowInternational(true)}>
                      <InternationalYellowCard
                        title='Más sobre conexiones internacionales'
                        noMargin={true}
                      />
                    </div>
                  </section>
                )}
              </TicketContainer>

              <TicketContainer type='body'>
                <section className='passenger_container'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <h3 className='passenger_title'>Pasajeros</h3>
                    <h3 className='passenger_title'>Documentos</h3>
                  </div>

                  <section
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                      marginTop: '8px',
                    }}>
                    {selectedTicket?.passengers?.map(
                      ({ firstName, lastName, type, documentId }, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              justifyContent: 'space-between',
                            }}>
                            <Box>
                              {type === 'underage' ? (
                                <p
                                  style={{
                                    fontSize: '10px',
                                    paddingBottom: '2px',
                                  }}>
                                  Menor de 4
                                </p>
                              ) : type === 'senior' ? (
                                <p
                                  style={{
                                    fontSize: '10px',
                                    paddingBottom: '2px',
                                  }}>
                                  3ra edad
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: '10px',
                                    paddingBottom: '2px',
                                  }}>
                                  General
                                </p>
                              )}
                              <p className='passenger_text'>
                                {fullName(`${firstName} ${lastName}`)}
                              </p>
                            </Box>
                            <p className='passenger_text'>
                              {documentId ?? '-'}
                            </p>
                          </div>
                        )
                      },
                    )}
                  </section>
                </section>

                <section className='flex-center'>
                  <div className='passenger-detail_disclaimer'>
                    <p>
                      <span>IMPORTANTE:</span> Debes estar al menos 1 hora{' '}
                      <br />
                      antes de la hora de salida en el terminal.
                    </p>
                  </div>
                </section>
              </TicketContainer>

              {/* #region CHOOSESEATS */}

              {selectedTicket?.canChooseSeats && (
                <TicketContainer type='body'>
                  <p className='detail-suport-text'>
                    <span style={{ textDecoration: 'underline' }}>
                      48 horas antes de tu viaje
                    </span>{' '}
                    podrás <br /> seleccionar tus puestos.
                  </p>

                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '10px 0px',
                    }}>
                    <ButtonBlue
                      onClick={() => {
                        setDestination({
                          to: selectedTicket?.endLocation?.long,
                          from: selectedTicket?.startLocation?.long,
                          date: selectedTicket.date,
                          shortTo: selectedTicket?.endLocation?.short,
                          shortFrom: selectedTicket?.startLocation?.short,
                        })
                        navegate(
                          `/seat?ticketsNumber=${seatForPassengers(
                            selectedTicket?.passengers,
                          )}`,
                        )
                      }}>
                      Escoger asientos
                    </ButtonBlue>
                  </Box>
                </TicketContainer>
              )}

              {/* #region SEATS */}

              {selectedTicket?.seat && (
                <TicketContainer type='body'>
                  <Box
                    component='section'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <h2>Asientos</h2>
                    {selectedTicket.seat.map((number) => {
                      return (
                        <Box
                          key={number}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='11'
                            height='13'
                            viewBox='0 0 11 14'
                            fill='none'>
                            <path
                              d='M10.708 0.354844C10.4998 0.126052 10.2127 0 9.89955 0H9.03883C8.4045 0 7.7991 0.521106 7.68946 1.16168L7.63518 1.47847C7.58061 1.79724 7.65405 2.09917 7.84202 2.32866C8.02998 2.55815 8.3073 2.6845 8.62299 2.6845H9.73483C9.88909 2.6845 10.0383 2.65291 10.1769 2.59685L10.0588 3.63189C9.94502 3.58288 9.82014 3.55678 9.6889 3.55678H8.42036C7.87692 3.55678 7.34314 4.0002 7.23037 4.5452L6.10881 9.96647L0.817112 9.5766C0.531153 9.55562 0.292427 9.65498 0.144417 9.85009C0.0057855 10.0328 -0.034741 10.278 0.0302265 10.5405L0.645457 13.0241C0.778745 13.5622 1.32776 14 1.86921 14H6.30547H6.37317H8.63135C9.18002 14 9.67355 13.5448 9.73142 12.9854L10.5995 4.59468C10.6044 4.54671 10.605 4.49945 10.6034 4.45266L10.9005 1.85074C10.9424 1.73867 10.9691 1.61956 10.9775 1.49601L10.9971 1.20638C11.0189 0.886091 10.9162 0.583695 10.708 0.354844Z'
                              fill='#29348F'
                            />
                          </svg>
                          <p style={{ fontSize: '14px', height: '14px' }}>
                            {number}
                          </p>
                        </Box>
                      )
                    })}
                  </Box>
                </TicketContainer>
              )}

              {/*  */}
              {selectedTicket?.isPerSeat && (
                <TicketContainer type='body'>
                  <Box
                    component='section'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 10,
                      padding: 10,
                    }}>
                    <h2 style={{ fontSize: 14, textAlign: 'center' }}>
                      ¿Desde dónde tomo el carro por puesto?
                    </h2>
                    <p style={{ fontSize: 12, textAlign: 'center' }}>
                      Estos viajes <b>NO SALEN DESDE TERMINALES</b>. Coordina
                      con Atención al pasajero el punto de salida y llegada.
                    </p>
                    <div className='flex-center'>
                      <ContactLine
                        link={selectedTicket.wsLink}
                        text='Atención al pasajero'
                      />
                    </div>
                  </Box>
                </TicketContainer>
              )}

              {/*  */}
              {selectedTicket?.isFullBus && (
                <TicketContainer type='body'>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 5,
                      padding: '10px 5px',
                    }}>
                    <h3
                      style={{
                        fontSize: 14,
                        textAlign: 'center',
                      }}>
                      ¿Desde dónde tomo mi unidad completa?
                    </h3>

                    <p style={{ fontSize: 12, textAlign: 'center' }}>
                      Estos viajes <b>NO SALEN DESDE TERMINALES</b>. Coordina
                      con Atención al pasajero el punto de salida y llegada.
                    </p>

                    <div className='flex-center mt-2'>
                      <ContactLine
                        link={selectedTicket.wsLink}
                        text='Atención al pasajero'
                      />
                    </div>
                  </Box>
                </TicketContainer>
              )}

              <TicketContainer type='footer'>
                <div className='center mb-2'>
                  <Status status={selectedTicket.status} />
                </div>

                {selectedTicket.status === 'waiting_verification' &&
                // selectedTicket.paymentStatus === 'pending_verification' ? (
                //   <section className='center' style={{ gap: '13px' }}>
                //     <p className='detail-verify-text'>
                //       Tu pago será verificado dentro de las <br /> primeras 24
                //       horas después de tu compra.
                //     </p>

                //     <section className='mt-2'>
                //       <p className='detail-suport-text'>
                //         Si tienes alguna duda, puedes escribir a soporte.
                //       </p>

                //       <div className='flex-center mt-2'>
                //         <ContactLine link={selectedTicket.wsLink} />
                //       </div>
                //     </section>
                //   </section>
                // ) :
                selectedTicket.status === 'waiting_seats' ? (
                  <>
                    <p className='detail-suport-text'>
                      <span style={{ textDecoration: 'underline' }}>
                        48 horas antes de tu viaje
                      </span>{' '}
                      podrás <br /> seleccionar tus puestos.
                    </p>
                  </>
                ) : selectedTicket.status === 'booked' ? (
                  <>
                    <p className='detail-verify-text'>
                      Puedes pagar el restante de tus boletos en taquilla o en
                      la app través de pago móvil.
                    </p>

                    <Box
                      style={{
                        margin: '15px 0px',
                        marginBottom: '6px',
                        borderRadius: '12px',
                        background: '#F0EEEE',
                        padding: '10px',
                      }}>
                      <p className='number_booked_text'>
                        Número de comprobante de reserva:
                      </p>

                      <p
                        className='number_booked_value'
                        style={{ marginTop: '3px' }}>
                        {selectedTicket.voucherNumber}
                      </p>
                    </Box>

                    <Box
                      component='section'
                      style={{
                        margin: '15px 0px',
                        marginTop: '6px',
                        borderRadius: '12px',
                        background: '#F0EEEE',
                        padding: '10px 15px',
                      }}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Box>
                          <p
                            className='number_booked_text'
                            style={{ textAlign: 'start' }}>
                            Abono realizado
                          </p>

                          <p
                            className='number_booked_value'
                            style={{ marginTop: '3px' }}>
                            Bs. {remainData?.data?.totalPaidBs?.toFixed(2)}
                          </p>
                        </Box>

                        <SuccessIcon width={30} height={30} />
                      </Box>

                      <Divider style={{ margin: '8px 0px' }} />

                      <p
                        className='number_booked_text'
                        style={{ color: '#D31E4A', textAlign: 'left' }}>
                        Pendiente por pagar
                      </p>

                      <p
                        className='number_booked_value'
                        style={{
                          marginTop: '3px',
                          color: '#D31E4A',
                          textAlign: 'left',
                        }}>
                        Bs. {remainData?.data?.remainingBs?.toFixed(2)}
                      </p>
                    </Box>

                    {/* <div className='center'>
                      <ContactLine link={selectedTicket.wsLink} />
                    </div> */}

                    {selectedTicket.paymentStatus === 'paid' && (
                      <div className='flex-center'>
                        <ButtonBlue buttonStyle={{ marginTop: '15px' }}>
                          <div
                            onClick={handleBooked}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '5px',
                            }}>
                            <svg
                              width='10'
                              height='14'
                              viewBox='0 0 10 14'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M8.57143 11.4545H1.42857V2.54545H8.57143M8.57143 0H1.42857C0.635714 0 0 0.566364 0 1.27273V12.7273C0 13.0648 0.15051 13.3885 0.418419 13.6272C0.686328 13.8659 1.04969 14 1.42857 14H8.57143C8.95031 14 9.31367 13.8659 9.58158 13.6272C9.84949 13.3885 10 13.0648 10 12.7273V1.27273C10 0.935179 9.84949 0.611456 9.58158 0.372773C9.31367 0.134091 8.95031 0 8.57143 0Z'
                                fill='white'
                              />
                            </svg>
                            <span style={{ color: 'white' }}>
                              Completar pago
                            </span>
                          </div>
                        </ButtonBlue>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {selectedTicket.status !== 'waiting_verification' && (
                      <div className='center mt-2'>
                        <CodeQR ticketId={selectedTicket.id} />
                      </div>
                    )}

                    <div className='center'>
                      <p className='passenger-code'>
                        {selectedTicket.voucherNumber}
                      </p>
                    </div>

                    <Box
                      component='section'
                      style={{
                        margin: '15px 0px',
                        marginTop: '6px',
                        borderRadius: '12px',
                        background: '#F0EEEE',
                        padding: '10px 15px',
                      }}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Box>
                          <p
                            className='number_booked_text'
                            style={{ textAlign: 'left' }}>
                            Monto Pagado
                          </p>

                          <p
                            className='number_booked_value'
                            style={{ marginTop: '3px', textAlign: 'left' }}>
                            Ref. {totalPrice}
                          </p>
                        </Box>

                        <SuccessIcon width={30} height={30} />
                      </Box>

                      <Divider style={{ margin: '8px 0px' }} />

                      <p
                        className='number_booked_text'
                        style={{ textAlign: 'left' }}>
                        Monto en Bs
                      </p>

                      <p
                        className='number_booked_value'
                        style={{
                          marginTop: '3px',
                          textAlign: 'left',
                        }}>
                        Bs.
                        {new Intl.NumberFormat('es-VE', {
                          currency: 'USD',
                        }).format(totalPriceBs?.toFixed(2))}
                      </p>
                    </Box>

                    {/* <Box className='center mt-2'>
                      <ContactLine link={selectedTicket.wsLink} />
                    </Box> */}

                    {/* <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant='contained'
                      style={{
                        borderRadius: '50%',
                        width: 36,
                        height: 36,
                        minWidth: 0,
                      }}
                      onClick={() => handleShared()}>
                      <ShareIcon />
                    </Button>
                  </Box> */}
                  </>
                )}
              </TicketContainer>

              {selectedTicket?.exitRate && exitRate > 0 && (
                <Box
                  style={{
                    borderRadius: '20px',
                    background: '#FFF',
                    padding: '16px',
                    paddingTop: '5px',
                    margin: '0 10px',
                  }}>
                  <h2 style={{ fontSize: '16px', textAlign: 'center' }}>
                    COMPROBANTE DE PAGO
                  </h2>
                  <h2 style={{ fontSize: '16px', textAlign: 'center' }}>
                    TASA DE SALIDA
                  </h2>

                  <Box
                    style={{
                      borderRadius: '12px',
                      background: '#F0EEEE',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px',
                    }}>
                    <div>
                      <p style={{ fontSize: '12px' }}>Monto pagado</p>
                      <p style={{ fontSize: '14px' }}>
                        <b>Ref. {exitRate}</b>
                      </p>
                      <p style={{ fontSize: '12px' }}>Monto en Bs</p>
                      <p style={{ fontSize: '14px' }}>
                        <b>
                          Bs.{' '}
                          {new Intl.NumberFormat('es-VE', {
                            currency: 'USD',
                          }).format(exitRateBs?.toFixed(2))}
                        </b>
                      </p>
                    </div>

                    <SuccessIcon width={30} height={30} />
                  </Box>
                </Box>
              )}
            </section>

            {selectedTicket?.invoice?.cardEmitter && (
              <section style={{ padding: '20px', paddingTop: '0px' }}>
                <Box
                  className='payment_info_container'
                  style={{ borderRadius: '12px' }}>
                  <Box style={{ marginBottom: 20 }}>
                    <p
                      className='result-payment_p'
                      style={{ textAlign: 'center' }}>
                      {selectedTicket.invoice.bank}
                    </p>

                    <h2 style={{ textAlign: 'center', fontSize: 16 }}>
                      RECIBO DE COMPRA
                    </h2>
                    <h3 style={{ textAlign: 'center', fontSize: 16 }}>
                      {selectedTicket.invoice.cardEmitter}
                    </h3>
                  </Box>

                  <p className='result-payment_p'>Adquiriente</p>
                  {/* <p className='result-payment_p' style={{ color: '#D31E4A' }}>
                    Dirección
                  </p> */}
                  {/* <p className='result-payment_p'>
                    RIF: J000000000 Afiliado: J00000000
                  </p> */}
                  <p className='result-payment_p'>
                    Terminal: {selectedTicket.invoice.terminal} LOTE:{' '}
                    {selectedTicket.invoice.lote}
                  </p>
                  <p className='result-payment_p'>
                    <b>{selectedTicket.invoice.cardNumber}</b>
                  </p>
                  <p className='result-payment_p'>
                    {Intl.DateTimeFormat('es-VE', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    })
                      .format(
                        new Date(selectedTicket?.invoice?.transactionDate),
                      )
                      .replaceAll('de ', '')
                      .replaceAll(', ', ', Hora: ')}
                  </p>
                  <p className='result-payment_p'>
                    {true
                      ? `Aprob. ${selectedTicket.invoice.approvalId} REF: ${selectedTicket.invoice.reference} Trace: ${selectedTicket.invoice.trace}`
                      : 'Rechazado'}
                  </p>

                  {true && (
                    <>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: 20,
                        }}>
                        <p className='result-payment_p'>
                          <b>Monto</b>
                        </p>

                        <p className='result-payment_p'>
                          <b>{selectedTicket.invoice.amount}</b>
                        </p>
                      </Box>

                      <p
                        className='result-payment_p'
                        style={{ textAlign: 'center', marginTop: 10 }}>
                        No se requiere firma
                      </p>
                    </>
                  )}
                </Box>
              </section>
            )}
          </Box>
        </section>
      </Slide>

      <WarningModal
        isWarning={isWarning}
        setIsWarning={setIsWarning}
        showCheck={false}
        onClick={() => setIsWarning(false)}
      />

      <Alert
        type='warning'
        isShow={showIntertional}
        setShow={setShowInternational}
        onClickButton={() => setShowInternational(false)}
        title='Boleto con conexión'>
        <p style={{ textAlign: 'center' }}>
          Al comprar un boleto con conexión deberás abordar dos autobuses para
          llegar hasta tu destino final.
        </p>
        <p style={{ textAlign: 'center' }}>
          El costo del boleto incluye ambos traslados.
        </p>
      </Alert>
    </>
  )
}

export default TicketDetailPage
