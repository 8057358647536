import { Box } from '@mui/material'
import './status.css'

/**
 *
 * @param {object} props
 * @param {('active'| 'booked' | 'used' | 'boarded' | "paid" | "checked_in" |"waiting_verification" | "expired" | "cancelled" | "reassigned" | "booked_expired" | "deposit_usd" | "terminal_cash")} props.status - props to select status.
 * @returns {React.JSX.Element}
 */
export const Status = ({ status = 'active' }) => {
  const statusList = {
    active: 'Activo',
    booked: 'Reservado',
    used: 'Canjeado',
    expired: 'Expirado',
    booked_expired: 'Reserva Expirada',
    waiting_verification: 'Pago por confirmar',
    payment_rejected: 'Pago Rechazado',
    boarded: 'Abordo',
    checked_in: 'Verificado',
    cancelled: 'Cancelado',
    paid: 'Pagado',
    reassigned: 'Reasignado',
    deposit_usd: 'Pendiente por depositar',
    terminal_cash: 'Pendiente por efectivo',
  }

  const displayText = statusList[status]

  const isGreen =
    status === 'active' || status === 'paid' || status === 'checked_in'
  const isOrange =
    status === 'booked' ||
    status === 'deposit_usd' ||
    status === 'terminal_cash'
  const isYellow = status === 'waiting_verification'
  const isPurple = status === 'reassigned'
  const isRed =
    status === 'used' ||
    status === 'expired' ||
    status === 'cancelled' ||
    status === 'booked_expired'
  // const gray = status === 'boarded'

  const ticketStatus = () => {
    return {
      maxHeight: '25px',
      borderRadius: '10px',
      padding: '2px 8px',
      background: isGreen
        ? 'rgba(59, 212, 174, 0.2)'
        : isOrange
        ? 'rgb(255 157 120 / 20%)'
        : isYellow
        ? 'rgba(255, 198, 0, 0.2)'
        : isRed
        ? 'rgba(211, 30, 74, 0.2)'
        : isPurple
        ? '#9774de73'
        : '#5b629c',
    }
  }
  const ticketStatusParr = () => {
    return {
      textAlign: 'center',
      fontFamily: 'Kufam',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      margin: '0',
      height: '14px',
      textWrap: 'nowrap',
      color: isGreen
        ? '#3bd4ae'
        : isOrange
        ? '#f08b22'
        : isYellow
        ? '#dab226'
        : isRed
        ? '#d31e4a'
        : isPurple
        ? '#5d3199'
        : '#fff',
    }
  }

  return (
    <Box style={ticketStatus()}>
      <p style={ticketStatusParr()}>{displayText}</p>
    </Box>
  )
}
