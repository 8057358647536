import React from 'react'

export const CardPayIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='26'
      viewBox='0 0 36 26'
      fill='none'>
      <path
        d='M3.76659 0C1.69892 0 0 1.69882 0 3.76648V21.362C0 23.4297 1.69892 25.1408 3.76659 25.1408H31.4218C33.4895 25.1408 35.1982 23.4297 35.1982 21.362V3.76648C35.1982 1.69882 33.4895 0 31.4218 0H3.76659ZM3.76659 2.50451H31.4218C32.14 2.50451 32.6839 3.04825 32.6839 3.76648V7.53558H2.51433V3.76648C2.51433 3.04825 3.04837 2.50451 3.76659 2.50451ZM2.51433 10.0499H32.6839V21.362C32.6839 22.0803 32.14 22.624 31.4218 22.624H3.76659C3.04837 22.624 2.51433 22.0803 2.51433 21.362V10.0499ZM23.756 16.3431C23.4278 16.3662 23.1217 16.5168 22.9032 16.7628C22.6847 17.0087 22.5712 17.3304 22.5869 17.659C22.6026 17.9877 22.7464 18.2971 22.9874 18.5211C23.2284 18.7451 23.5474 18.8659 23.8763 18.8575H28.9075C29.0749 18.8612 29.2414 18.8314 29.3972 18.7699C29.5529 18.7084 29.6949 18.6163 29.8146 18.4992C29.9343 18.3821 30.0295 18.2423 30.0944 18.088C30.1594 17.9336 30.1928 17.7678 30.1928 17.6003C30.1928 17.4328 30.1594 17.267 30.0944 17.1127C30.0295 16.9583 29.9343 16.8184 29.8146 16.7013C29.6949 16.5842 29.5529 16.4922 29.3972 16.4307C29.2414 16.3692 29.0749 16.3394 28.9075 16.3431H23.8763C23.8363 16.3412 23.7961 16.3412 23.756 16.3431Z'
        fill='#29348F'
      />
    </svg>
  )
}
