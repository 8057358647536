// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar_item_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
  padding: 10px;
  height: 45px;
  border-radius: 9px;
  background: #e7e9f3;

  /* color: #29348f;
  font-family: Kufam;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */

  .icon-divider_container {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .search-date_input {
    width: 100%;
    height: 30px;
    background: transparent;
    color: #29348f;

    margin: 0 !important;
    border: 0 !important;
    border-radius: 0;

    font-family: Kufam;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}

.unstyled {
  -webkit-appearance: none;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Calendar/style.calendar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,QAAQ;EACR,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;;EAEnB;;;;;wBAKsB;;EAEtB;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,cAAc;;IAEd,oBAAoB;IACpB,oBAAoB;IACpB,gBAAgB;;IAEhB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;EAC9B;AACF;;AAEA;EACE,wBAAwB;AAC1B;AACA;;EAEE,aAAa;EACb,wBAAwB;AAC1B","sourcesContent":[".calendar_item_container {\n  display: flex;\n  align-items: center;\n  justify-content: space-evenly;\n  gap: 5px;\n  padding: 10px;\n  height: 45px;\n  border-radius: 9px;\n  background: #e7e9f3;\n\n  /* color: #29348f;\n  font-family: Kufam;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal; */\n\n  .icon-divider_container {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n  }\n\n  .search-date_input {\n    width: 100%;\n    height: 30px;\n    background: transparent;\n    color: #29348f;\n\n    margin: 0 !important;\n    border: 0 !important;\n    border-radius: 0;\n\n    font-family: Kufam;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px; /* 200% */\n  }\n}\n\n.unstyled {\n  -webkit-appearance: none;\n}\ninput[type='date']::-webkit-inner-spin-button,\ninput[type='date']::-webkit-calendar-picker-indicator {\n  display: none;\n  -webkit-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
