import React, { useEffect, useMemo } from 'react'
import Select from 'react-select'

import { Copy } from '../../../icons/Copy'
import { CardPayIcon } from '../../../icons/Payment/card'
import { MobileIcon } from '../../../icons/Payment/mobile'

import { Box, Divider } from '@mui/material'

import { InputMaterial } from '../../../imputs'
import FormsCitizensId from '../../../imputs/forms-citizens-id'
import { BanksSelect, SelectBanksStyles } from '../../../imputs/banks'
import { FormsPhoneNumber } from '../../../imputs/forms-phone-number'
import {
  ValidationCode,
  ValidationDescountAmount,
} from '../../../imputs/forms-validation-code'

import { useStore, useAmountsStorage } from '../../../../store'
import { useGetBanksAcounts } from '../../../../hooks/useBanksAccount'
import { useGetPaymentRemaining } from '../../../../hooks/usePaymentRemain'

import './styles.movil.css'
import FormsPassword from '../../../imputs/forms-password'
import { CurrencySelect } from '../../../imputs/select'
import { CashIcon } from '../../../icons/Payment/cash'
import { ImportantIcon } from '../../../icons/ImportantIcon'

import { CalendarInput } from '../../../imputs/forms-birth-date'

// region MethodPaymentCard
export const MethodPaymentCard = ({
  type = 'normal',
  title = 'Pago móvil',
  isCheck = 'mobile',
  setCheck,
  id = 'mobile',
}) => {
  return type === 'input' ? (
    <>
      <div className='card_radio' onClick={() => setCheck(id)}>
        <section className='icon_text_container' style={{ width: '90%' }}>
          {title.toLocaleLowerCase().includes('tarjeta') ? (
            <CardPayIcon />
          ) : title.toLocaleLowerCase().includes('depósito') ||
            title.toLocaleLowerCase().includes('terminal') ? (
            <CashIcon />
          ) : (
            <MobileIcon />
          )}

          <label>{title}</label>
        </section>

        <input
          name='pago'
          type='radio'
          value={id}
          checked={isCheck === id}
          onChange={() => setCheck(id)}
        />
      </div>
    </>
  ) : (
    <section className='card-movil_container'>
      <div className='icon_movil_container'>
        {title.toLocaleLowerCase().includes('tarjeta') ? (
          <CardPayIcon />
        ) : title.toLocaleLowerCase().includes('depósito') ||
          title.toLocaleLowerCase().includes('terminal') ? (
          <CashIcon />
        ) : (
          <MobileIcon />
        )}

        <p className='movil_text'>{title}</p>
      </div>
    </section>
  )
}

// region PagoMovilData
export const PagoMovilData = ({
  page = 'payment',
  mobilePayment = true,
  isReserve = false,
  isCompleteBooked = false,
  isCash = false,
  setValue,
}) => {
  const { selectedTrip, selectedTicket, setWsLink } = useStore()

  const {
    totalBs,
    totalUsd,
    // totalUsdDiscount,
    // totalBsDiscount,
    dolarRate,
    //
    perTotalBs,
    perTotalUsd,
    setPerTotalBs,
    setPerTotalUsd,
    completedBookedUsd,
    completedBookedBs,
    setCompletedBookedUsd,
    setCompletedBookedBs,
  } = useAmountsStorage()

  const { data: info } = useGetBanksAcounts({
    requestHeaders: {},
    requestParams: {
      busLine: selectedTrip?.busLine ?? selectedTicket.busLine,
      busStation:
        selectedTrip.originName ?? selectedTicket?.startLocation?.long,
    },
  })

  const { data: remainData } = useGetPaymentRemaining(selectedTicket.id)

  // #region EFFECTS
  // useEffect(() => {
  //   if (setValue) {
  //     setValue(
  //       'amount',
  //       isReserve
  //         ? perTotalBs
  //         : isCompleteBooked
  //         ? completedBookedBs
  //         : totalBs?.toFixed(2),
  //     )
  //   }
  // }, [
  //   completedBookedBs,
  //   isCompleteBooked,
  //   isReserve,
  //   perTotalBs,
  //   setValue,
  //   totalBs,
  // ])

  useEffect(() => {
    let percentInBs = (totalBs * selectedTrip.bookingPercentage) / 100

    percentInBs = new Intl.NumberFormat('es-VE', {
      currency: 'USD',
    }).format(percentInBs?.toFixed(2))

    setPerTotalBs(percentInBs)

    const percentInUsd = (totalUsd * selectedTrip.bookingPercentage) / 100

    setPerTotalUsd(percentInUsd)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrip, totalBs, totalUsd])

  useEffect(() => {
    if (remainData && remainData?.data?.remaining > 0) {
      const priceRef = remainData?.data?.remaining
      setCompletedBookedUsd(priceRef)

      if (dolarRate) {
        const priceBs = dolarRate * priceRef

        setCompletedBookedBs(
          new Intl.NumberFormat('es-VE', {
            currency: 'USD',
          }).format(priceBs?.toFixed(2)),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainData, selectedTicket])

  useEffect(() => {
    setWsLink(info?.data?.wsLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info?.data?.wsLink])

  return (
    <section className='payment_info_container'>
      {page === 'reserve' ? (
        <>
          <div style={{ marginBottom: '8px' }}>
            <h2 className='regular_title'>Monto de reserva</h2>
            <p className='pay_percentage'>{selectedTrip?.bookingPercentage}%</p>
          </div>

          <div>
            <h2 className='regular_title'>Monto a pagar</h2>
            <p className='pay_price'>Bs. {perTotalBs}</p>
            <p className='pay_ref_price'>Ref {perTotalUsd}</p>
          </div>
        </>
      ) : (
        <>
          <h3 className='regular_title'>Monto a pagar</h3>
          <Box
            component='section'
            style={{
              display: 'flex',
              gap: '15px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Box>
              {!isCash && (
                <h4 className='pay_price'>
                  Bs.{' '}
                  {isReserve
                    ? perTotalBs
                    : isCompleteBooked
                    ? completedBookedBs
                    : new Intl.NumberFormat('es-VE', {
                        currency: 'USD',
                      }).format(totalBs?.toFixed(2))}
                </h4>
              )}

              <p
                className='pay_ref_price'
                style={{
                  fontWeight: isCash ? 700 : 500,
                  fontSize: isCash ? 20 : 16,
                }}>
                Ref{' '}
                {isReserve
                  ? perTotalUsd
                  : isCompleteBooked
                  ? completedBookedUsd
                  : totalUsd}
              </p>
            </Box>

            {!isCash && (
              <Box
                className='bcv_container'
                style={{ marginBottom: !mobilePayment ? '0px' : '15px' }}>
                <p className='bcv_title'>Tasa BCV del día</p>
                <Box className='tasa_day_container'>
                  <p>Bs {dolarRate ?? selectedTicket?.dolarRate}</p>
                </Box>
              </Box>
            )}
          </Box>

          {mobilePayment && (
            <>
              <div className='pago_movil_title_container'>
                <p className='regular_title'>
                  Por favor realiza el pago móvil a:
                </p>
              </div>

              <Box
                component='section'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                  <p>Banco</p>
                  <p className='info_pay_text' style={{ marginRight: '15px' }}>
                    {info?.data?.bankName}
                  </p>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    width: '100%',
                  }}>
                  <p>Documento</p>

                  <p className='info_pay_text'>
                    {info?.data?.ownerId}{' '}
                    <Copy text={info?.data?.ownerId?.slice(2)} />
                  </p>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    width: '100%',
                  }}>
                  <p>Número de teléfono</p>
                  <p className='info_pay_text'>
                    {info?.data?.phoneNumber}{' '}
                    <Copy text={info?.data?.phoneNumber} />
                  </p>
                </Box>
              </Box>
            </>
          )}

          {/* <Box style={{ marginTop: '15px' }}>
            <h3 className='regular_title'>Instrucciones de pago:</h3>
            <ol
              style={{
                margin: 0,
                marginTop: '5px',
                padding: 0,
                paddingLeft: '16px',
                color: '#29348f',
                fontSize: '12px',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}>
              <li>
                <p>
                  Ingresa tu cédula de identidad al realizar la transacción, de
                  esta forma detectaremos tu pago en nuestra cuenta.{' '}
                </p>
              </li>
              <li>
                <p>
                  Deberás reportar tu pago una vez realizado. Asegúrate que los
                  datos coincidan exactamente.{' '}
                </p>
              </li>
              <li>
                <p>
                  El monto a transferir debe ser igual o mayor a Bs.{' '}
                  {isReserve
                    ? perTotalBs
                    : isCompleteBooked
                    ? completedBookedBs
                    : new Intl.NumberFormat('es-VE', {
                        currency: 'USD',
                      }).format(totalBs?.toFixed(2))}
                  .{' '}
                </p>
              </li>
            </ol>
          </Box> */}
        </>
      )}
    </section>
  )
}

// region GetPaymentData
const options = [
  { value: 'Corriente', label: 'Corriente' },
  { value: 'Ahorro', label: 'Ahorro' },
]

export const GetPaymentData = ({
  register,
  setValue,
  errors,
  control,
  codeWatch,
  dataType = 'mobile',
  mobileType = 've',
}) => {
  return (
    <section className='payment_info_container'>
      <h3 className='title_form'>
        {dataType !== 'mobile'
          ? 'Ingresa los datos solicitados'
          : dataType === 'cash'
          ? 'Ingresa los datos de tu pago en efectivo'
          : 'Ingresa los datos de tu pago móvil'}
      </h3>

      <p className='text_form'>
        Con esta información podremos verificar tu pago
      </p>

      {dataType === 'cash' && (
        <BanksSelect
          registerValidation={{
            ...register('banks', { required: false }),
          }}
          setValue={setValue}
          type={dataType}
        />
      )}

      {dataType === 'mobile' && (
        <section className='from_section'>
          <BanksSelect
            registerValidation={{ ...register('banks', { required: false }) }}
            setValue={setValue}
          />

          <FormsPhoneNumber
            registerValidation={{
              ...register(`phone`, {
                required: 'Este campo es requerido',
                maxLength: { value: 7, message: 'Deben ser 7 digitos' },
                minLength: { value: 7, message: 'Deben ser 7 digitos' },
                valueAsNumber: true,
              }),
            }}
            registerCode={{
              ...register(`code`, { required: false }),
            }}
            error={errors.phone?.message}
            setValue={setValue}
            codeType='ve'
          />

          <FormsCitizensId
            error={errors.ci?.message}
            setValue={setValue}
            control={control}
            getValue={codeWatch}
            // getValues={getValues}
          />

          {/* <ValidationDescountAmount
            label='Monto pagado en Bolivares'
            nameId='amount'
            error={errors.amount?.message}
            control={control}
            // type='string'
          /> */}

          {/* <CalendarInput
            max='today'
            setValue={(value) => {
              setValue('date', value)
            }}
            error={errors.date?.message}
            control={control}
            label='Fecha de pago'
          /> */}

          <ValidationCode
            label='Número de referencia'
            error={errors.refNumber?.message}
            control={control}
          />
        </section>
      )}

      {dataType !== 'mobile' && dataType !== 'cash' && (
        <section className='from_section'>
          {dataType === 'credito' && (
            <CurrencySelect register={register} setValue={setValue} />
          )}

          <InputMaterial
            label='Titular de la tarjeta'
            error={errors.refNumber?.message}
            register={{ ...register('titular', { required: true }) }}
          />

          <ValidationCode
            label='Número de tarjeta'
            error={errors.cardNumber?.message}
            control={control}
            nameId='cardNumber'
            maxValueLength={16}
          />

          <FormsCitizensId
            error={errors.ci?.message}
            setValue={setValue}
            control={control}
            getValue={codeWatch}
            max={12}
          />

          <FormsPhoneNumber
            registerValidation={{
              ...register(`phone`, {
                required: 'Este campo es requerido',
                maxLength: { value: 7, message: 'Deben ser 7 digitos' },
                minLength: { value: 7, message: 'Deben ser 7 digitos' },
                valueAsNumber: true,
              }),
            }}
            registerCode={{
              ...register(`code`, { required: false }),
            }}
            error={errors.phone?.message}
            setValue={setValue}
            codeType={mobileType}
          />

          <section style={{ display: 'flex', gap: 15 }}>
            <div style={{ width: '40%', margin: 0 }} className='input-field'>
              <FormsPassword
                label='CVC'
                registerValidation={{
                  ...register('cvc', {
                    required: 'Este campo es requerido',
                    minLength: {
                      value: 3,
                      message: 'Minimo 3',
                    },
                    maxLength: {
                      value: 3,
                      message: 'Maximo 3',
                    },
                  }),
                }}
                error={errors.cvc?.message}
              />
            </div>

            <div style={{ width: '30%', margin: 0 }} className='input-field'>
              <InputMaterial
                register={{
                  ...register('cardDateMonth', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]\d*(\d+)?$/i,
                      message: 'Solo deben ser numeros',
                    },
                    minLength: {
                      value: 2,
                      message: 'Minimo 2',
                    },
                    maxLength: {
                      value: 2,
                      message: 'Maximo 2',
                    },
                  }),
                }}
                label='Mes'
              />

              {errors?.cardDateMonth && (
                <p className='error_message'>{errors.cardDateMonth.message}</p>
              )}
            </div>

            <div style={{ width: '30%', margin: 0 }} className='input-field'>
              <InputMaterial
                register={{
                  ...register('cardDateYear', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[0-9]\d*(\d+)?$/i,
                      message: 'Solo deben ser numeros',
                    },
                    minLength: {
                      value: 2,
                      message: 'Minimo 2',
                    },
                    maxLength: {
                      value: 2,
                      message: 'Maximo 2',
                    },
                  }),
                }}
                label='Año'
              />

              {errors?.cardDateYear && (
                <p className='error_message'>{errors.cardDateYear.message}</p>
              )}
            </div>
          </section>

          {dataType === 'debito' && (
            <>
              <BanksSelect
                registerValidation={{
                  ...register('banks', { required: false }),
                }}
                setValue={setValue}
                sendCode={true}
                type={dataType}
              />

              <Select
                {...register('account', {
                  required: false,
                })}
                placeholder='Tipo de cuenta'
                options={options}
                styles={SelectBanksStyles}
                onChange={(data) => {
                  setValue('account', data.value)
                }}
              />

              <FormsPassword
                label='Pin clave'
                registerValidation={{
                  ...register('pinKey', {
                    required: 'Este campo es requerido',
                    // pattern: {
                    //   message: 'Contraseña no valida',
                    //   value:
                    //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,30}$/,
                    // },
                  }),
                }}
                error={errors.pinKey?.message}
              />
            </>
          )}
        </section>
      )}
    </section>
  )
}

// region RemiderPaymentCard
export const RemiderPaymentCard = () => {
  return (
    <section className='reminder-card_container'>
      <div className='icon-reminder-card_container'>
        <ImportantIcon />
      </div>

      <div className='text-reminder-card_container'>
        <h2 className='reminder_title'>
          ¡Recuerda hacer una captura <br /> del comprobante del pago!
        </h2>
      </div>
    </section>
  )
}

// region AllowBanks
export const AllowBanks = ({ type }) => {
  const banks = [
    'Banco de Venezuela',
    // 'Bancaribe',
    // 'Mi banco',
    'Banco del Tesoro',
    'Banco Bicentenario',
    'Bancrecer',
    'Banfanb',
    'Bancamiga',
  ]

  const cards = ['Visa', 'Mastercard', 'AMEX', 'Discover']

  const cash = ['Banco Nacional de Crédito', 'Banplus', 'Bancamiga']

  const iterator = useMemo(
    () => (type === 'debito' ? banks : type === 'cash' ? cash : cards),
    [type],
  )
  // const iterator = type === 'debito' ? banks: cards
  return (
    <Box component='section' className='payment_info_container'>
      <h2
        style={{
          fontSize: 16,
          fontWeight: '700',
          wordWrap: 'break-word',
          marginBottom: 3,
        }}>
        {type === 'debito' || type === 'cash'
          ? 'Bancos permitidos'
          : 'Tarjetas permitidas'}
      </h2>

      {iterator.map((name, i) => {
        return (
          <div key={i}>
            <p
              key={i}
              style={{
                fontSize: 12,
                fontWeight: '500',
                wordWrap: 'break-word',
              }}>
              {name}
            </p>

            {iterator.length - 1 !== i && (
              <Divider style={{ margin: '3px 0px' }} />
            )}
          </div>
        )
      })}
    </Box>
  )
}

export const GetPaymentDataTerminalCash = ({
  register,
  setValue,
  errors,
  control,
}) => {
  return (
    <section className='payment_info_container'>
      <h3 className='title_form'>¿Cuánto vas a pagar?</h3>

      <p className='text_form'>Esto nos permitirá saber si necesitas vuelto.</p>

      <CurrencySelect register={register} setValue={setValue} type='terminal' />

      <ValidationDescountAmount
        label='Ingresa el monto'
        nameId='amount'
        error={errors.amount?.message}
        control={control}
        // type='string'
      />
    </section>
  )
}
