// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-factor-auth {
  display: flex;
  gap: 16px;

  input[type='tel']:not(.browser-default) {
    /* border-bottom: 1px solid #29348f; */
    text-align: center;
    color: #29348f;
    font-family: Kufam;
  }
}

/* input:not([type]):not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #29348f;
  box-shadow: 0 1px 0 0 #29348f;
} */
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-2fa/styles.2fa.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;;EAET;IACE,sCAAsC;IACtC,kBAAkB;IAClB,cAAc;IACd,kBAAkB;EACpB;AACF;;AAEA;;;;;GAKG","sourcesContent":[".two-factor-auth {\n  display: flex;\n  gap: 16px;\n\n  input[type='tel']:not(.browser-default) {\n    /* border-bottom: 1px solid #29348f; */\n    text-align: center;\n    color: #29348f;\n    font-family: Kufam;\n  }\n}\n\n/* input:not([type]):not(.browser-default):focus:not([readonly]),\ninput[type='tel']:not(.browser-default):focus:not([readonly]),\ntextarea.materialize-textarea:focus:not([readonly]) {\n  border-bottom: 1px solid #29348f;\n  box-shadow: 0 1px 0 0 #29348f;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
