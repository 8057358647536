import React, { useState } from 'react'
// import { Check } from '../../icons/Check'

import './styles.check.css'
import { Checkbox, FormControlLabel, styled } from '@mui/material'

const FormWithLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    fontFamily: 'Kufam',
    textAlign: 'left',
  },
})

export const CheckboxButton = ({
  label,
  id,
  registerValidation,
  setValue,
  check = false,
}) => {
  const [isChecked, setIsChecked] = useState(check)

  return (
    <div className={`checkbox_container ${isChecked ? 'check' : ''}`}>
      <label htmlFor={id}>{label}</label>

      <input
        {...registerValidation}
        style={{ opacity: 0, visibility: 'hidden', display: 'none' }}
        type='checkbox'
        id={id}
        onChange={(e) => {
          setIsChecked(!isChecked)
          setValue(id, e.target.checked)
        }}
      />
      {/* <Check fill={isChecked ? 'white' : '#29348F'} /> */}
    </div>
  )
}

export const CheckboxUI = ({
  label,
  id,
  registerValidation,
  setValue,
  check = false,
}) => {
  const [isChecked, setIsChecked] = useState(check)

  return (
    <FormWithLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
        />
      }
      labelPlacement='end'
      label={label}
    />
  )
}
