export function BusIcon() {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.90002 4.6C1.90002 2.90305 1.90002 2.05435 2.42742 1.5274C2.95437 1 3.80307 1 5.50002 1C7.19697 1 8.04567 1 8.57262 1.5274C9.10002 2.05435 9.10002 2.90305 9.10002 4.6V5.5C9.10002 7.19695 9.10002 8.04565 8.57262 8.5726C8.04567 9.1 7.19697 9.1 5.50002 9.1C3.80307 9.1 2.95437 9.1 2.42742 8.5726C1.90002 8.04565 1.90002 7.19695 1.90002 5.5V4.6Z'
        stroke='#29348F'
        strokeWidth='0.6'
      />
      <path
        d='M7.075 7.30002H7.75M3.25 7.30002H3.925M2.8 8.87502V9.55002C2.8 9.66937 2.84741 9.78383 2.9318 9.86822C3.01619 9.95261 3.13065 10 3.25 10H3.925C4.04435 10 4.15881 9.95261 4.2432 9.86822C4.32759 9.78383 4.375 9.66937 4.375 9.55002V9.10002M8.2 8.87502V9.55002C8.2 9.66937 8.15259 9.78383 8.0682 9.86822C7.98381 9.95261 7.86935 10 7.75 10H7.075C6.95565 10 6.84119 9.95261 6.7568 9.86822C6.67241 9.78383 6.625 9.66937 6.625 9.55002V9.10002M9.1 4.15002H9.55C9.66935 4.15002 9.78381 4.19744 9.8682 4.28183C9.95259 4.36622 10 4.48068 10 4.60002V5.05002C10 5.11988 9.98373 5.18879 9.95249 5.25127C9.92125 5.31376 9.87589 5.36811 9.82 5.41002L9.1 5.95002H1.9L1.18 5.41002C1.12411 5.36811 1.07875 5.31376 1.04751 5.25127C1.01627 5.18879 1 5.11988 1 5.05002V4.60002C1 4.48068 1.04741 4.36622 1.1318 4.28183C1.21619 4.19744 1.33065 4.15002 1.45 4.15002H1.9'
        stroke='#29348F'
        strokeWidth='0.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.875 2.34998H2.125'
        stroke='#29348F'
        strokeWidth='0.6'
        strokeLinecap='round'
      />
    </svg>
  )
}
