import { useEffect, useState } from 'react'
import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'
import { useStore } from '../store'
import dayjs from 'dayjs'

// /**
//  *
//  * @returns {(data: any, isLoading: boolean, isError: boolean, error: any)}
//  */
// export const useGetUserTickets = (options = {}) => {
//   return useQuery({
//     queryKey: ['passenger-tickets'],
//     queryFn: () => get('passenger/tickets', options),
//     retryDelay: 20000,
//   })
// }

export const useGetTickets = (options = {}, enabled = true) => {
  const {
    data: res,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['passenger-tickets'],
    queryFn: () => get('passenger/tickets', options),
    retryDelay: 20000,
    enabled: enabled,
  })

  const { tickets, setTickets } = useStore(({ tickets, setTickets }) => ({
    tickets,
    setTickets,
  }))

  const [nextTicket, setNextTicket] = useState()

  useEffect(() => {
    if (res) {
      setTickets(res)
      setNextTicket(res.data[0])

      let data = res.data.map((ticket) => {
        const dateByDay = dayjs(ticket.date.slice(0, -5), 'YYYY-MM-DDTHH:mm:ss')

        const matchDate = Intl.DateTimeFormat('es-VE', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        })
          .format(dateByDay.toDate())
          .replaceAll('de ', '')

        //region SERVICES ARRAY
        let busServices = []

        if (ticket.petFriendly) {
          busServices.push({ path: 'pet', text: 'Mascotas' })
        }

        if (tickets.busType === 'Buscama') {
          busServices.push({ path: 'bus', text: 'Buscama' })
        }
        if (tickets.busType === 'Encava') {
          busServices.push({ path: 'bus', text: 'Encava' })
        }

        if (ticket.wifi) {
          busServices.push({ path: 'wifi', text: 'Wifi' })
        }
        if (ticket.ac) {
          busServices.push({ path: 'air', text: 'Aire' })
        }
        if (ticket.luggageWeight) {
          busServices.push({ path: 'bag', text: `${ticket.luggageWeight}kg` })
        }
        if (ticket.isPerSeat) {
          busServices.push({ path: 'perSeat', text: 'Por puesto' })
        }

        //region CHANGE HOUR
        const arrivalTime = ticket.startTime
          ? ticket.startTime
          : Intl.DateTimeFormat('es-VE', {
              hour: 'numeric',
              minute: 'numeric',
            })
              .format(
                dayjs(
                  `${dateByDay.format('YYYY-MM-DD')}:${ticket.arrivalETA}`,
                  'YYYY-MM-DD:HH:mm:ss',
                ).toDate(),
              )
              .toLocaleUpperCase()

        const departureTime = ticket.endTime
          ? ticket.endTime
          : Intl.DateTimeFormat('es-VE', {
              hour: 'numeric',
              minute: 'numeric',
            })
              .format(
                dayjs(
                  `${dateByDay.format('YYYY-MM-DD')}:${ticket.departureETA}`,
                  'YYYY-MM-DD:HH:mm:ss',
                ).toDate(),
              )
              .toLocaleUpperCase()

        // CHANCE PRICE

        const {
          originNameshort,
          destinyNameShort,
          originName,
          destinyName,
          ac,
          wifi,
          arrivalETA,
          departureETA,
          destinyGeolocation,
          originGeolocation,
          ...item
        } = ticket

        return {
          ...item,
          date: matchDate,
          dateByDay,
          services: busServices,
          startTime: departureTime,
          endTime: arrivalTime,
          startLocation: {
            short: originNameshort,
            long: originName,
            geo: originGeolocation,
          },
          endLocation: {
            short: destinyNameShort,
            long: destinyName,
            geo: destinyGeolocation,
          },
        }
      })

      data = data.sort((a, b) => a.dateByDay - b.dateByDay)

      const next = data.filter(({ status, dateByDay }) => {
        return (
          dateByDay.isSameOrAfter(dayjs()) &&
          (status === 'active' ||
            status === 'booked' ||
            status === 'checked_in' ||
            status === 'waiting_verification')
        )
      })

      setNextTicket(next[0])
      setTickets(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res])

  return { tickets, isLoading, isError, nextTicket }
}

export const useGetSelectedTicket = ({ id, retry = false }) => {
  const { tickets } = useGetTickets({}, retry)

  const { selectedTicket, setSelectedTickets, setDestination } = useStore(
    ({ selectedTicket, setSelectedTickets, setDestination }) => ({
      selectedTicket,
      setSelectedTickets,
      setDestination,
    }),
  )

  useEffect(() => {
    if (tickets && retry) {
      const ticket = tickets.find((ticket) => {
        return ticket.id === id
      })
      if (ticket) {
        setSelectedTickets(ticket)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, id, tickets])

  // console.log({ tickets, retry, id })
  return { selectedTicket, setSelectedTickets, setDestination }
}
