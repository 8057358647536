// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/assets/header.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pages-main {
  height: 100vh;
  position: relative;
  background: #f0eeee;
}

.pages-main-top {
  background-color: #29348f;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 30px 28px;
  padding-top: max(30px, env(safe-area-inset-top));
}

.pages-main-title {
  margin: 0px;
  color: #fff;
  font-family: Kufam;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pages-main-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #29348f;
  padding-bottom: max(0px, env(safe-area-inset-bottom));
}

.footer_title_text {
  color: #ffc600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer_regular_text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,yDAA0D;EAC1D,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gDAAgD;AAClD;;AAEA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,qDAAqD;AACvD;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".pages-main {\n  height: 100vh;\n  position: relative;\n  background: #f0eeee;\n}\n\n.pages-main-top {\n  background-color: #29348f;\n  background-image: url('../../../public/assets/header.svg');\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: center;\n  padding: 30px 28px;\n  padding-top: max(30px, env(safe-area-inset-top));\n}\n\n.pages-main-title {\n  margin: 0px;\n  color: #fff;\n  font-family: Kufam;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n\n.pages-main-bottom {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  background-color: #29348f;\n  padding-bottom: max(0px, env(safe-area-inset-bottom));\n}\n\n.footer_title_text {\n  color: #ffc600;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n}\n\n.footer_regular_text {\n  color: #fff;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
