import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Box, Slide } from '@mui/material'

import { ButtonBlue } from '../../components/buttons'
import { ButtomLink } from '../../components/buttons/ContactLine'
import { Card } from '../../components/ui/Cards'
import { InputMaterial } from '../../components/imputs'
import { Alert } from '../../components/bottom-panel/Alert'
import axios from 'axios'
import { busiAPi } from '../../utils/urls'
import './styles.help.css'

export const HelpPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const [showAlert, setShowAlert] = useState(false)

  const onSubmit = async (data) => {
    if (data.suggestions) {
      const { data: info } = await axios.post(
        `${busiAPi}/passenger/suggestion-box`,
        { suggestion: data.suggestions },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setShowAlert(true)
        reset()
      }
    }
  }

  return (
    <Slide direction='up' in={true} timeout={200}>
      <Box
        component='section'
        style={{
          borderRadius: '20px 20px 0px 0px',
          background: '#f0eeee',
          boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          bottom: '18px',
          height: 'calc(100% + 18px)',
          // overflow: 'scroll',
          display: 'flex',
          justifyContent: 'center',
        }}
        className='all-container'>
        <Box
          style={{
            maxWidth: '600px',
            height: '100%',
            width: '100%',
            padding: '25px',
            overflowY: 'scroll',
          }}>
          <h1 style={{ fontSize: '18px' }}>
            ¿Necesitas ayuda para comprar tu boleto?
          </h1>
          <p style={{ fontSize: '14px', textAlign: 'start' }}>
            Consulta los siguientes recursos de ayuda:
          </p>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              marginTop: '12px',
            }}>
            <Card>
              <ButtomLink link='https://youtu.be/wxze8XkerZo'>
                Ver video tutorial
              </ButtomLink>

              <p
                style={{
                  fontSize: '12px',
                  marginTop: '12px',
                  textAlign: 'center',
                }}>
                Visualiza un video paso a paso sobre cómo adquirir tu boleto
                fácilmente
              </p>
            </Card>

            <Card>
              <ButtomLink link='https://www.busitransporte.com/preguntas-frecuentes'>
                Preguntas frecuentes
              </ButtomLink>

              <p
                style={{
                  fontSize: '12px',
                  marginTop: '12px',
                  textAlign: 'center',
                }}>
                Consulta respuestas a las dudas más comunes sobre la compra de
                boletos
              </p>
            </Card>

            <Card>
              <h2 style={{ fontSize: '16px' }}>Buzón de sugerencias</h2>
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '12px',
                }}>
                ¿Qué debería tener Busi Boletos para ayudarte a planificar mejor
                tu viaje en autobús?
              </p>

              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  marginTop: '12px',
                }}>
                <InputMaterial
                  label='Escribe tu sugerencia'
                  register={{
                    ...register('suggestions', {
                      required: 'Este campo es requerido',
                    }),
                  }}
                />
                <p className='error_message'>{errors.suggestions?.message}</p>

                <ButtonBlue type='submit'>Enviar sugerencia</ButtonBlue>
              </form>
            </Card>
          </Box>
        </Box>

        <Alert
          isShow={showAlert}
          setShow={setShowAlert}
          onClickButton={() => setShowAlert(false)}
          type='success'
          title='Gracias por tu sugerencia'
        />
      </Box>
    </Slide>
  )
}
