import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'

import { SelectBanksStyles } from '../banks'
import { get } from '../../../api/axiosHelper'
import { busiAPi } from '../../../utils/urls'

import './styles.selector.css'

const options = [
  { value: 'occidente', label: 'Expresos Occidente' },
  { value: 'flamingo', label: 'Expresos Flamingo' },
  { value: 'los andes', label: 'Expresos Los Andes' },
]

const animatedComponents = makeAnimated()

export const NormalSelect = ({ registerValidation, setValue }) => {
  return (
    <Select
      {...registerValidation}
      placeholder='Línea'
      options={options}
      components={animatedComponents}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width: '100%',
          boxShadow: 'none',
          outline: 'none',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: 'none',
          borderRadius: '0px',
          borderBottom: '1px solid #29348F',
          boxShadow: 'none',
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: 0,
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          padding: 0,
          margin: 0,
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),

        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          padding: '2px',
          color: '#29348F',
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: '#29348F',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: '#29348F',
        }),
      }}
      onChange={(data) => {
        setValue('line', data.value)
      }}
    />
  )
}

export const CurrencySelect = ({ setValue, register, type = 'normal' }) => {
  const getCurrencies = async () => {
    try {
      const info = await get(`${busiAPi}/setting/currencies`)

      const data = info?.data
        .filter(({ value }) =>
          type === 'terminal'
            ? value === 'VED' || value === 'COP' || value === 'USD'
            : value === 'USD',
        )
        .map(({ value, country, name }) => {
          return {
            value,
            label:
              type === 'terminal'
                ? `${value === 'VED' ? 'BS' : value} - ${name}`
                : `${country} - ${value}`,
          }
        })

      if (data.length > 0) {
        localStorage.setItem('currencies', JSON.stringify(data))
        return data
      } else {
        let currencies = localStorage.getItem('currencies')
        currencies = currencies ? JSON.parse(currencies) : []
        console.log({ currencies })
        return currencies ?? []
      }
    } catch (error) {
      //   let currencies = localStorage.getItem('currencies')
      //   currencies = currencies ? JSON.parse(currencies) : []
      //   console.log({ currencies })
      //   return currencies.length !== 0
      //     ? currencies
      //     : bum.data.map(({ value, name }) => {
      //         return { value, label: name }
      //       })
    }
  }
  return (
    <AsyncSelect
      {...register('currency', {
        required: false,
      })}
      placeholder='Moneda'
      isSearchable={true}
      cacheOptions
      defaultOptions
      loadOptions={getCurrencies}
      components={animatedComponents}
      options={options}
      styles={SelectBanksStyles}
      onChange={(data) => {
        setValue('currency', data)
      }}
    />
  )
}
