import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// COMPONENTS
import { Ticket } from '../../components/Tickets/index'

import { TicketIcon, TicketGrayIcon } from '../../components/icons/Ticket'

import { Bus } from '../../components/ui/Loader/Bus'
import { Box, Button, CardActionArea, useMediaQuery } from '@mui/material'

// HOOKS
import { useStore } from '../../store'
import { useGetTickets, useIsTerminalCash } from '../../hooks/useGetUserTickets'

import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

// STYLES
import { useCheckRateReview } from '../../hooks/useTrips'
import './styles.ticket.css'

dayjs.extend(isSameOrAfter)

export const TicketsPage = () => {
  const minW800 = useMediaQuery('(min-width:800px)')
  // const { data: res, isLoading, isError } = useGetUserTickets()
  const { tickets, nextTicket, isLoading, isError } = useGetTickets()
  const { data: isReviewData } = useCheckRateReview()
  const isTerminalCash = useIsTerminalCash(nextTicket)

  const { pathname } = useLocation()
  const navegate = useNavigate()

  const { setSelectedTickets } = useStore(({ setSelectedTickets }) => ({
    setSelectedTickets,
  }))

  // const [nextTicket, setNextTicket] = useState()

  useEffect(() => {
    let timeId
    if (isReviewData?.success && isReviewData.data.length > 0) {
      timeId = setTimeout(() => {
        navegate('/qualification', {
          state: { tripId: isReviewData.data[0].id, previousPath: pathname },
        })
      }, 200)
    }

    return () => clearTimeout(timeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewData])

  //region PARSIN DATE
  // useEffect(() => {
  //   if (res) {
  //     setTickets(res)
  //     setNextTicket(res.data[0])

  //     let data = res.data.map((ticket) => {
  //       const dateByDay = dayjs(ticket.date.slice(0, -5), 'YYYY-MM-DDTHH:mm:ss')

  //       const matchDate = Intl.DateTimeFormat('es-VE', {
  //         year: 'numeric',
  //         month: 'long',
  //         day: '2-digit',
  //       })
  //         .format(dateByDay.toDate())
  //         .replaceAll('de ', '')

  //       //region SERVICES ARRAY
  //       let busServices = []

  //       if (ticket.petFriendly) {
  //         busServices.push({ path: 'pet', text: 'Mascotas' })
  //       }

  //       if (tickets.busType === 'Buscama') {
  //         busServices.push({ path: 'bus', text: 'Buscama' })
  //       }
  //       if (tickets.busType === 'Encava') {
  //         busServices.push({ path: 'bus', text: 'Encava' })
  //       }

  //       if (ticket.wifi) {
  //         busServices.push({ path: 'wifi', text: 'Wifi' })
  //       }
  //       if (ticket.ac) {
  //         busServices.push({ path: 'air', text: 'Aire' })
  //       }
  //       if (ticket.luggageWeight) {
  //         busServices.push({ path: 'bag', text: `${ticket.luggageWeight}kg` })
  //       }

  //       //region CHANGE HOUR
  //       const arrivalTime = ticket.startTime
  //         ? ticket.startTime
  //         : Intl.DateTimeFormat('es-VE', {
  //             hour: 'numeric',
  //             minute: 'numeric',
  //           })
  //             .format(
  //               dayjs(
  //                 `${dateByDay.format('YYYY-MM-DD')}:${ticket.arrivalETA}`,
  //                 'YYYY-MM-DD:HH:mm:ss',
  //               ).toDate(),
  //             )
  //             .toLocaleUpperCase()

  //       const departureTime = ticket.endTime
  //         ? ticket.endTime
  //         : Intl.DateTimeFormat('es-VE', {
  //             hour: 'numeric',
  //             minute: 'numeric',
  //           })
  //             .format(
  //               dayjs(
  //                 `${dateByDay.format('YYYY-MM-DD')}:${ticket.departureETA}`,
  //                 'YYYY-MM-DD:HH:mm:ss',
  //               ).toDate(),
  //             )
  //             .toLocaleUpperCase()

  //       // CHANCE PRICE

  //       const {
  //         originNameshort,
  //         destinyNameShort,
  //         originName,
  //         destinyName,
  //         ac,
  //         wifi,
  //         arrivalETA,
  //         departureETA,
  //         ...item
  //       } = ticket

  //       return {
  //         ...item,
  //         date: matchDate,
  //         dateByDay,
  //         services: busServices,
  //         startTime: departureTime,
  //         endTime: arrivalTime,
  //         startLocation: {
  //           short: originNameshort,
  //           long: originName,
  //         },
  //         endLocation: {
  //           short: destinyNameShort,
  //           long: destinyName,
  //         },
  //       }
  //     })

  //     data = data.sort((a, b) => a.dateByDay - b.dateByDay)

  //     const next = data.filter(({ status, dateByDay }) => {
  //       return (
  //         dateByDay.isSameOrAfter(dayjs()) &&
  //         (status === 'active' ||
  //           status === 'booked' ||
  //           status === 'checked_in' ||
  //           status === 'waiting_verification')
  //       )
  //     })

  //     setNextTicket(next[0])
  //     setTickets(data)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [res])

  const handleNavegate = (ticket) => {
    setSelectedTickets(ticket)

    setTimeout(() => {
      navegate(`/main/ticket/detail/${ticket.id}`, {
        state: { previousPath: pathname },
      })
    }, 550)
  }

  if (isLoading) {
    return <Bus />
  }

  if (isError && nextTicket === undefined) {
    return (
      <section
        style={{
          width: '100%',
          height: 'calc(100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        // className='ticket-container_error'
      >
        <TicketGrayIcon />
        <p
          style={{
            color: 'rgba(108, 108, 108, 0.2)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
          className='no_data_text'>
          Tenemos un problema intenta más tarde
        </p>
      </section>
    )
  }

  if (minW800) {
    return (
      <Box
        component='section'
        // className='pages-tickets_body'
        style={{
          height: 'calc(100% - 134px)',
          minHeight: '600px',
          bottom: 0,
          borderRadius: 0,
          background: 'transparent',
          paddingTop: '22px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box style={{ maxWidth: '600px', width: '100%' }}>
          {tickets.filter(({ status }) => status === 'boarded').length !==
            0 && (
            <>
              <div className='titles_container'>
                <h3 style={{ fontSize: '16px' }}>Boleto en curso</h3>
              </div>
              <hr className='divider' />
            </>
          )}

          {tickets.filter(({ status }) => status === 'boarded').length !==
            0 && (
            <section className='tickets_container boarded'>
              {tickets
                .filter(({ status }) => status === 'boarded')
                .map((ticket, i) => {
                  return (
                    <div key={i} onClick={() => handleNavegate(ticket)}>
                      <Ticket
                        status={ticket.status}
                        title={ticket.busLine}
                        date={ticket.date}
                        startTime={ticket.startTime}
                        // endTime={ticket.endTime}
                        startLocation={ticket.startLocation}
                        endLocation={ticket.endLocation}
                        // availability={ticket.availability}
                        services={ticket.services}
                      />
                    </div>
                  )
                })}
            </section>
          )}
        </Box>

        <Box style={{ maxWidth: '600px', height: '100%', width: '100%' }}>
          <div className='titles_container'>
            <h4
              style={{
                color: '#29348f',
                fontFamily: 'Kufam',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                margin: '0',
              }}>
              Próximo boleto
            </h4>
          </div>

          <hr className='divider' />

          <div
            className={`big_container ${
              tickets.filter(({ status }) => status === 'boarded').length !== 0
                ? 'next_ticket'
                : ''
            }`}>
            <section
              className={`tickets_container ${
                nextTicket === undefined ? 'no_data' : ''
              }`}>
              {nextTicket === undefined ? (
                <section
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                  // className='center'
                >
                  <TicketGrayIcon />
                  <p
                    style={{
                      color: 'rgba(108, 108, 108, 0.2)',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                    }}
                    className='no_data_text'>
                    No tienes próximos boletos activos
                  </p>
                </section>
              ) : (
                <CardActionArea
                  onClick={() => handleNavegate(nextTicket)}
                  color='primary'>
                  <Ticket
                    status={nextTicket.status}
                    logoUrl={nextTicket.logoUrl}
                    title={nextTicket.busLine}
                    date={nextTicket.date}
                    startTime={nextTicket.startTime}
                    // endTime={nextTicket.endTime}
                    startLocation={nextTicket.startLocation}
                    endLocation={nextTicket.endLocation}
                    // availability={nextTicket.availability}
                    services={nextTicket.services}
                  />
                </CardActionArea>
              )}
            </section>

            <section className='tickets_footer'>
              <hr className='divider' />

              <Button
                variant='outlined'
                // className='ticket-page_check_all'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '10px 30px',
                  alignItems: 'center',
                  gap: '5px',
                  borderRadius: '10px',
                  border: '1px solid #29348f',
                  width: '95%',
                  boxShadow: '1px 3px 4px 0px #244a7a70',
                }}
                onClick={() => {
                  setTimeout(() => {
                    navegate('/main/all', { state: { previousPath: pathname } })
                  }, 580)
                }}
                // onClick={() =>
                //   navegate('/qualification', {
                //     state: { previousPath: pathname,  tripId: "56d44cb8-1c97-43ef-bdaa-214b54a0c4bb"},
                //   })
                // }
              >
                <TicketIcon />
                <p
                  style={{
                    color: '#29348f',
                    fontFamily: 'Kufam',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                    margin: '0',
                    height: '15px',
                    textTransform: 'none',
                  }}>
                  Ver todos mis boletos
                </p>
              </Button>
            </section>
          </div>
        </Box>

        {/* <Footer /> */}
      </Box>
    )
  }

  return (
    <Box
      component='section'
      // className='pages-tickets_body'
      style={{
        borderRadius: '20px 20px 0px 0px',
        background: '#f0eeee',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        bottom: '18px',
        padding: '0px 12px',
        paddingTop: '22px',
        height: 'calc(100% + 18px)',
        overflow: 'scroll',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box style={{ maxWidth: '600px', width: '100%' }}>
        {tickets.filter(({ status }) => status === 'boarded').length !== 0 && (
          <>
            <div className='titles_container'>
              <h3 style={{ fontSize: '16px' }}>Boleto en curso</h3>
            </div>
            <hr className='divider' />
          </>
        )}

        {tickets.filter(({ status }) => status === 'boarded').length !== 0 && (
          <section className='tickets_container boarded'>
            {tickets
              .filter(({ status }) => status === 'boarded')
              .map((ticket, i) => {
                return (
                  <div key={i} onClick={() => handleNavegate(ticket)}>
                    <Ticket
                      status={ticket.status}
                      title={ticket.busLine}
                      date={ticket.date}
                      startTime={ticket.startTime}
                      // endTime={ticket.endTime}
                      startLocation={ticket.startLocation}
                      endLocation={ticket.endLocation}
                      // availability={ticket.availability}
                      services={ticket.services}
                    />
                  </div>
                )
              })}
          </section>
        )}
      </Box>

      <Box style={{ maxWidth: '600px', height: '100%', width: '100%' }}>
        <div className='titles_container'>
          <h4
            style={{
              color: '#29348f',
              fontFamily: 'Kufam',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              margin: '0',
            }}>
            Próximo boleto
          </h4>
        </div>

        <hr className='divider' />

        <div
          className={`big_container ${
            tickets.filter(({ status }) => status === 'boarded').length !== 0
              ? 'next_ticket'
              : ''
          }`}>
          <section
            className={`tickets_container ${
              nextTicket === undefined ? 'no_data' : ''
            }`}>
            {nextTicket === undefined ? (
              <section
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '5px',
                }}
                // className='center'
              >
                <TicketGrayIcon />
                <p
                  style={{
                    color: 'rgba(108, 108, 108, 0.2)',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                  }}
                  className='no_data_text'>
                  No tienes próximos boletos activos
                </p>
              </section>
            ) : (
              <CardActionArea
                onClick={() => handleNavegate(nextTicket)}
                color='primary'>
                <Ticket
                  status={
                    nextTicket.paymentMethod === 'deposit_usd' &&
                    nextTicket.paymentStatus === 'pending_payment_data'
                      ? nextTicket.paymentMethod
                      : isTerminalCash
                      ? 'terminal_cash'
                      : nextTicket.status
                  }
                  logoUrl={nextTicket.logoUrl}
                  title={nextTicket.busLine}
                  date={nextTicket.date}
                  startTime={nextTicket.startTime}
                  // endTime={nextTicket.endTime}
                  startLocation={nextTicket.startLocation}
                  endLocation={nextTicket.endLocation}
                  // availability={nextTicket.availability}
                  services={nextTicket.services}
                />
              </CardActionArea>
            )}
          </section>

          <section className='tickets_footer'>
            <hr className='divider' />

            <Button
              variant='outlined'
              // className='ticket-page_check_all'
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '10px 30px',
                alignItems: 'center',
                gap: '5px',
                borderRadius: '10px',
                border: '1px solid #29348f',
                width: '95%',
                boxShadow: '1px 3px 4px 0px #244a7a70',
              }}
              onClick={() => {
                setTimeout(() => {
                  navegate('/main/all', {
                    state: { previousPath: pathname },
                  })
                }, 580)
              }}
              // onClick={() =>
              //   navegate('/qualification', {
              //     state: { previousPath: pathname,  tripId: "56d44cb8-1c97-43ef-bdaa-214b54a0c4bb"},
              //   })
              // }
            >
              <TicketIcon />
              <p
                style={{
                  color: '#29348f',
                  fontFamily: 'Kufam',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  margin: '0',
                  height: '15px',
                  textTransform: 'none',
                }}>
                Ver todos mis boletos
              </p>
            </Button>
          </section>
        </div>
      </Box>
    </Box>
  )
}
