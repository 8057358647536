import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { Alert } from '../bottom-panel/Alert'
import { NextDateAlert } from '../bottom-panel/Alert/NextDate'
import { ButtonBlue } from '../buttons'
import { Calendar } from '../ui/Calendar'

// import { CaretUpIcon } from '../icons/CaretUp'
import { CaretDownIcon } from '../icons/CaretDown'
import { SearchIcon } from '../icons/Search'

import { useStore } from '../../store'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Divider,
  Popover,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { busiAPi } from '../../utils/urls'
import { get } from '../../api/axiosHelper'

import './searchBar.css'

const animatedComponents = makeAnimated()

const AccordionMain = styled(Accordion)({
  border: 'none !important',
  boxShadow: 'none',
})

const AccordionSummaryMain = styled(AccordionSummary)({
  minHeight: '30px',

  '.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '40px',
  },
  '.MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: 0,
    height: '30px',
  },

  '.Mui-expanded': {
    minHeight: '30px',
    // marginTop: '0px !important',
  },
})

const AccordionDetailsMain = styled(AccordionDetails)({
  paddingTop: '0px',
})

const stylesToSelect = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
    minWidth: '160px',
    borderColor: 'transparent',
    boxShadow: 'none',
    outline: 'none',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    background: 'transparent',
    border: 'none',
    borderColor: 'transparent',
    boxShadow: 'none',
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: '#29348F',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: '#29348F',
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    color: '#29348f',
    fontFamily: 'Kufam',
    fontWeight: '500',
  }),
}

export const SearchBar = ({
  title = 'Buscar...',
  openSearch = undefined,
  setOpenSearch = () => {},
  values = undefined,
  busLineId = undefined,
}) => {
  const maxW990 = useMediaQuery('(max-width:990px)')
  const minW1440 = useMediaQuery('(min-width:1440px)')

  const navegate = useNavigate()
  const { pathname } = useLocation()

  const { setPassengers, setDestination, setTrips, setOwnerTravel } = useStore(
    ({ setPassengers, setDestination, setTrips, setOwnerTravel }) => ({
      setPassengers,
      setTrips,
      setDestination,
      setOwnerTravel,
    }),
  )
  // const { headerHeight } = useMeasureStorage()

  const [expanded, setExpanded] = useState(false)

  const [general, setGeneral] = useState(0)
  const [child, setChild] = useState(0)
  const [elder, setElder] = useState(0)

  const [passErr, setPassErr] = useState(undefined)
  const [showLess18, setShowLess18] = useState(false)
  const [showNextDate, setShowNextDate] = useState(false)
  const [showNotFound, setShowNotFound] = useState(false)

  const [nextDates, setNextDates] = useState([])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const [citiesFrom, setCitiesFrom] = useState()
  const [keyTo, setKeyTo] = useState(0)
  // const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [keyFrom, setKeyFrom] = useState(0)
  // const [age, setAge] = useState('')

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm()

  // #region EFFECTS
  useEffect(() => {
    if (openSearch) {
      setExpanded(openSearch)
    }
  }, [openSearch])

  useEffect(() => {
    if (expanded) {
      setKeyFrom((keyFrom === undefined ? 0 : keyFrom) + 1)
    }
  }, [expanded])

  useEffect(() => {
    if (values === undefined) return
    setValue('fromLocation', values.from.value)
    setValue('toLocation', values.to.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  // #region FUNCTIONS
  const getDataFrom = async (text) => {
    const info = await get(
      busLineId
        ? `${busiAPi}/setting/origin-cities?busLineId=${busLineId}`
        : `${busiAPi}/setting/origin-cities`,
    )

    const data = info.data
      .map(({ city, state }) => {
        return { value: city, label: `${city}, ${state}` }
      })
      .sort((a, b) => a.label.localeCompare(b.label))

    if (data.length > 0) {
      localStorage.setItem('originCities', JSON.stringify(data))

      return text !== ''
        ? data.filter(({ value }) =>
            value.toLowerCase().includes(text.toLowerCase()),
          )
        : data
    } else {
      let originCities = localStorage.getItem('originCities')
      originCities = originCities ? JSON.parse(originCities) : []
      console.log({ originCities })
      return originCities ?? []
    }
  }

  const getDataTo = async (text) => {
    if (!citiesFrom) return

    try {
      const info = await get(
        busLineId
          ? `${busiAPi}/setting/destiny-cities?origin=${citiesFrom.value}&busLineId=${busLineId}`
          : `${busiAPi}/setting/destiny-cities?origin=${citiesFrom.value}`,
      )

      const data = info.data
        .map(({ city, state }) => {
          return { value: city, label: `${city}, ${state}` }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

      if (data.length > 0) {
        localStorage.setItem('destinyCities', JSON.stringify(data))

        return text !== ''
          ? data.filter(({ value }) =>
              value.toLowerCase().includes(text.toLowerCase()),
            )
          : data
      } else {
        let destinyCities = localStorage.getItem('destinyCities')
        destinyCities = destinyCities ? JSON.parse(destinyCities) : []
        console.log({ destinyCities })
        return destinyCities ?? []
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleTypePassengers = () => {
    const data = []

    if (general > 0) {
      for (let i = 0; i < general; i++) {
        data.push({
          type: 'general',
        })
      }
    }

    if (child > 0) {
      for (let i = 0; i < child; i++) {
        data.push({
          type: 'child',
        })
      }
    }

    if (elder > 0) {
      for (let i = 0; i < elder; i++) {
        data.push({
          type: 'elder',
        })
      }
    }

    return data
  }

  const handleTrigger = async () => {
    handleSubmit(onSubmit)()
  }

  const closeSearchBar = (expanded) => {
    setLoading(false)

    setExpanded(expanded)
    setOpenSearch(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // region OnSubmit
  const onSubmit = async (data) => {
    dayjs.extend(customParseFormat)

    const date = dayjs(data.calendar, 'YYYY-MM-DD').format('DD/MM/YYYY')
    const dateServer = dayjs(data.calendar, 'YYYY-MM-DD').format('MM-DD-YYYY')

    const total = general + child + elder

    if (total > 10) {
      setPassErr('Llegaste al máximo de boletos')
      return
    }
    if (general === 0 && child === 0 && elder === 0) {
      setPassErr('Selecciona por lo menos 1 pasajero')
      return
    }

    if (child !== 0 && general === 0 && elder === 0) {
      setShowLess18(true)
      return
    }

    setLoading(true)

    try {
      const info = await get(
        `${busiAPi}/trip`,
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
        {
          origin: data.fromLocation,
          destiny: data.toLocation,
          date: dateServer,
          amount: total,
          elderly: elder,
          busLineId: busLineId,
        },
      )

      if (info.data?.length === 0 && info.nextDates?.length > 0) {
        setError()
        setLoading(false)
        setShowNextDate(true)

        const dateUser = info.nextDates.map((date) => {
          return {
            dateUser: Intl.DateTimeFormat('es-VE', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(dayjs(date, 'YYYY-MM-DD').toDate()),
            dateServer: dayjs(date, 'YYYY-MM-DD'),
          }
        })

        setNextDates(dateUser)
        return
      }

      if (info.data?.length === 0 && info.nextDates?.length === 0) {
        setError()
        setLoading(false)
        setShowNotFound(true)
        return
      }

      let typePassengers = handleTypePassengers()
      typePassengers = typePassengers.map((passenger, i) => ({
        ...passenger,
        title:
          passenger.type === 'general'
            ? `Pasajero #${i + 1}: General`
            : passenger.type === 'child'
            ? `Pasajero #${i + 1}: Niño`
            : `Pasajero #${i + 1}: 3ra Edad`,
        id: i + 1,
        firstname: '',
        lastname: '',
        dob: '',
        typeDocument: 'V-',
        document: '',
        codePhone: '0412',
        phone: '',
      }))

      setLoading(false)

      if (general === 0) {
        setOwnerTravel(false)
      } else {
        setOwnerTravel(true)
      }

      setPassengers({
        general,
        child,
        elder,
        total,
        data: typePassengers,
      })

      setDestination({
        from: data.fromLocation,
        shortFrom: info.data[0].originNameshort,
        to: data.toLocation,
        shortTo: info.data[0].destinyNameShort,
        isConnection: info.isConnection,
        date,
      })
      setTrips(info.data)
      setError()

      navegate('/main/buy', { state: { previousPath: pathname } })
    } catch (error) {
      setLoading(false)
      setError('Estamos teniendo un problema, intente más tarde')
      console.log({ error })
    }
  }

  if (!maxW990) {
    return (
      <section
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}>
        <Box
          style={{
            maxWidth: minW1440 ? '1100px' : 'fit-content',
            borderRadius: '0px 0px 20px 20px',
            position: 'relative',
            background: '#29348f',
            padding: '10px',
            // marginTop: '10px',
          }}>
          <form
            // className='search-bar-selector_container'
            style={{
              borderRadius: '16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'transparent',
              boxShadow: 'none',
            }}
            onSubmit={handleSubmit(onSubmit)}>
            <Box
              style={{
                display: 'flex',
                gap: '10px',
                minHeight: '60px',
                borderRadius: '25px 0px 0px 25px',
                boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.25)',
                background: 'white',
                alignItems: 'center',
              }}>
              <>
                <section
                  className='search-select_container'
                  style={{
                    display: 'flex',
                    gap: '10px',
                    minHeight: '55px',
                    background: 'transparent',
                    padding: '0px',
                    paddingLeft: '30px',
                    alignItems: 'center',
                  }}>
                  <div
                    style={{
                      width: '100%',
                      height: '25px',
                      display: 'grid',
                      gridTemplateColumns: '0.5fr 0.1fr 2.2fr',
                      alignItems: 'center',
                      alignContent: 'center',
                      gap: '8px',
                    }}>
                    <p>Desde</p>

                    <Divider
                      orientation='vertical'
                      style={{
                        width: 0,
                        borderColor: '#29348f',
                        height: '52px',
                      }}
                    />

                    <AsyncSelect
                      {...register('fromLocation', {
                        required: 'Este campo es obligatorio',
                      })}
                      placeholder='Seleccionar'
                      isSearchable={true}
                      cacheOptions
                      defaultOptions
                      loadOptions={getDataFrom}
                      value={values?.from}
                      components={animatedComponents}
                      styles={stylesToSelect}
                      onChange={(data) => {
                        setCitiesFrom(data)
                        setKeyTo(keyTo + 1)
                        setValue('fromLocation', data.value)
                      }}
                    />
                  </div>

                  <Divider
                    orientation='vertical'
                    style={{
                      width: 0,
                      borderColor: '#29348f',
                      height: '52px',
                    }}
                  />

                  <div
                    style={{
                      width: '100%',
                      height: '25px',
                      display: 'grid',
                      gridTemplateColumns: '0.5fr 0.1fr 2.2fr',
                      alignItems: 'center',
                      alignContent: 'center',
                      gap: '5px',
                    }}>
                    <p>Hasta</p>

                    <Divider
                      orientation='vertical'
                      style={{ width: 0, borderColor: '#29348f' }}
                    />

                    <AsyncSelect
                      {...register('toLocation', {
                        required: 'Este campo es obligatorio',
                      })}
                      key={keyTo}
                      placeholder='Seleccionar'
                      isSearchable={true}
                      cacheOptions
                      defaultOptions
                      loadOptions={getDataTo}
                      value={values?.to}
                      components={animatedComponents}
                      styles={stylesToSelect}
                      onChange={(data) => {
                        setValue('toLocation', data.value)
                      }}
                    />
                  </div>
                </section>

                {(errors.fromLocation || errors.toLocation) && (
                  <p className='error_message'>
                    {errors.fromLocation
                      ? errors.fromLocation.message
                      : errors.toLocation.message}
                  </p>
                )}
              </>

              <Divider
                orientation='vertical'
                style={{ width: 0, borderColor: '#29348f', height: '52px' }}
              />

              <section
                style={{
                  gap: '15px',
                  height: '100%',
                }}>
                <div style={{ height: '100%' }}>
                  <Calendar
                    setValue={setValue}
                    min='today'
                    control={control}
                    iconPosition='left'
                    containerStyle={{
                      height: 'calc(100% - 8px)',
                      minHeight: '60px',
                      borderRadius: '0px',
                      background: 'transparent',
                    }}
                  />
                </div>

                {errors.calendar && (
                  <p className='error_message'>{errors.calendar.message}</p>
                )}
              </section>

              <Divider
                orientation='vertical'
                style={{ width: 0, borderColor: '#29348f', height: '52px' }}
              />

              <>
                <Button
                  aria-describedby={id}
                  onClick={handleClick}
                  style={{ padding: '0px' }}>
                  <section
                    className='search-select_container'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      minHeight: '60px',
                      borderRadius: '0px',
                      background: 'transparent',
                      padding: '0px 18px',
                      minWidth: '140px',
                    }}>
                    <p style={{ textTransform: 'capitalize' }}>Pasajeros</p>
                    <Divider
                      orientation='vertical'
                      style={{
                        width: 0,
                        height: '38px',
                        borderColor: '#29348f',
                      }}
                    />
                    <span>{general + child + elder}</span>
                  </section>
                </Button>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}>
                  <div
                    className='search-bar-passenger_container'
                    style={{
                      marginTop: '0px',
                      padding: '10px',
                      borderRadius: '15px',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}>
                      <SelectPassangersCard
                        title='General'
                        description='(4 años en adelante)'
                        amount={general}
                        setAmount={setGeneral}
                      />
                      <SelectPassangersCard
                        title='Menores de 4 años'
                        description=''
                        amount={child}
                        setAmount={setChild}
                      />
                      <SelectPassangersCard
                        title='Tercera edad'
                        description='(A partir de 60 años)'
                        amount={elder}
                        setAmount={setElder}
                      />
                    </div>
                    {passErr && <p className='error_message'>{passErr}</p>}
                  </div>
                </Popover>
              </>
            </Box>

            <Button
              style={{
                background: '#FFC60B',
                borderRadius: '0px 25px 25px 0px',
                textTransform: 'capitalize',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                padding: '0px 60px',
                minHeight: '60px',
                boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.25)',
                // marginTop: '16px',
              }}
              type='submit'
              disabled={loading}>
              Buscar
            </Button>
          </form>

          <Alert
            type='warning'
            isShow={showLess18}
            buttonText='Entendido'
            setShow={setShowLess18}
            onClickButton={() => setShowLess18(false)}>
            <p style={{ textWrap: 'balance' }}>
              Para comprar un boleto para un menor de edad debes comprar al
              menos 1 boleto de adulto
            </p>
          </Alert>

          {showNextDate && (
            <NextDateAlert
              isShow={showNextDate}
              setShowNotFound={setShowNextDate}
              nextDates={nextDates}
              setValue={setValue}
              handleTrigger={handleTrigger}
            />
          )}

          <Alert
            type='error'
            isShow={showNotFound}
            setShow={setShowNotFound}
            title='Boletos no encontrados'
            buttonText='Reintentar'
            onClickButton={() => setShowNotFound(false)}>
            <p style={{ textAlign: 'center' }}>
              No existen pasajes que <br />
              coincidan con tu búsqueda
            </p>
          </Alert>
        </Box>
      </section>
    )
  }

  // #region HTML
  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}>
      <Box
        style={{ top: '5px', maxWidth: '600px' }}
        // style={{
        //   top: headerHeight ? `calc(${headerHeight}px - 78px)` : '5px',
        // }}
        className='search-bar-container'>
        {/* OVERLAY */}
        {expanded && (
          <div
            className='search-bar-overlay'
            onClick={() => {
              closeSearchBar(false)
              setCitiesFrom(undefined)
              setKeyTo(undefined)
              setKeyFrom(undefined)
            }}></div>
        )}

        <form
          className='search-bar-selector_container'
          onSubmit={handleSubmit(onSubmit)}>
          <AccordionMain
            expanded={expanded}
            onChange={() => {
              closeSearchBar(!expanded)
            }}>
            <AccordionSummaryMain
              id='search-header'
              aria-controls='search-content'
              expandIcon={<CaretDownIcon />}>
              <SearchIcon fill='#222B79' />

              <p
                style={{
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  margin: '0',
                }}>
                {title}
              </p>
            </AccordionSummaryMain>

            <AccordionDetailsMain
              style={{
                overflowY: 'scroll',
                height: 'calc(100vh - 206px)',
                // maxHeight: title === 'Buscar boleto' ? '506px' : '540px',
                maxHeight: '458px',
              }}>
              <section>
                <div>
                  <p className='search-select-title' style={{ margin: 0 }}>
                    Destino
                  </p>

                  <section className='search-select_container'>
                    <div
                      style={{
                        width: '100%',
                        height: '25px',
                        display: 'grid',
                        gridTemplateColumns: '0.5fr 0.1fr 2.2fr',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}>
                      <p>Desde</p>

                      {/* <hr className='divider horizontal' /> */}
                      <Divider
                        orientation='vertical'
                        style={{ width: 0, borderColor: '#29348f' }}
                      />

                      <AsyncSelect
                        {...register('fromLocation', {
                          required: 'Este campo es obligatorio',
                        })}
                        key={keyFrom}
                        placeholder='Seleccionar'
                        isSearchable={true}
                        cacheOptions
                        defaultOptions
                        loadOptions={getDataFrom}
                        components={animatedComponents}
                        styles={stylesToSelect}
                        value={values?.from}
                        onChange={(data) => {
                          setCitiesFrom(data)
                          setKeyTo((keyTo === undefined ? 0 : keyTo) + 1)
                          setValue('fromLocation', data.value)
                        }}
                      />
                    </div>

                    <Divider
                      style={{ margin: '10px 0', borderColor: '#29348f' }}
                    />

                    <div
                      style={{
                        width: '100%',
                        height: '25px',
                        display: 'grid',
                        gridTemplateColumns: '0.5fr 0.1fr 2.2fr',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}>
                      <p>Hasta</p>

                      <Divider
                        orientation='vertical'
                        style={{ width: 0, borderColor: '#29348f' }}
                      />

                      <AsyncSelect
                        {...register('toLocation', {
                          required: 'Este campo es obligatorio',
                        })}
                        key={keyTo}
                        placeholder='Seleccionar'
                        isSearchable={true}
                        cacheOptions
                        defaultOptions
                        loadOptions={getDataTo}
                        components={animatedComponents}
                        styles={stylesToSelect}
                        value={values?.to}
                        onChange={(data) => {
                          setValue('toLocation', data.value)
                          // setKeyFrom(keyFrom + 1)
                        }}
                      />
                    </div>
                  </section>

                  {(errors.fromLocation || errors.toLocation) && (
                    <p className='error_message'>
                      {errors.fromLocation
                        ? errors.fromLocation.message
                        : errors.toLocation.message}
                    </p>
                  )}

                  <section
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      gap: '15px',
                      marginTop: '10px',
                    }}
                    className={`calendars_container ${
                      expanded === true ? 'one' : ''
                    }`}>
                    <div>
                      <p className='search-select-title'>Fecha</p>

                      <Calendar
                        setValue={setValue}
                        min='today'
                        control={control}
                      />
                    </div>
                  </section>

                  {errors.calendar && (
                    <p className='error_message'>{errors.calendar.message}</p>
                  )}
                </div>

                <div
                  className='search-bar-passenger_container'
                  style={{ marginTop: '10px' }}>
                  <p className='search-select-title'>Pasajeros</p>

                  <SelectPassangersCard
                    title='General'
                    description='(4 años en adelante)'
                    amount={general}
                    setAmount={setGeneral}
                  />
                  <SelectPassangersCard
                    title='Menores de 4 años'
                    description=''
                    amount={child}
                    setAmount={setChild}
                  />
                  <SelectPassangersCard
                    title='Tercera edad'
                    description='(A partir de 60 años)'
                    amount={elder}
                    setAmount={setElder}
                  />
                  {passErr && <p className='error_message'>{passErr}</p>}
                </div>

                {error && (
                  <p className='error_message' style={{ marginTop: '5px' }}>
                    {error}
                  </p>
                )}

                <div className='center mt-3'>
                  <ButtonBlue type='submit' disabled={loading}>
                    Buscar
                  </ButtonBlue>
                </div>
              </section>
            </AccordionDetailsMain>
          </AccordionMain>
        </form>

        <Alert
          type='warning'
          isShow={showLess18}
          buttonText='Entendido'
          setShow={setShowLess18}
          onClickButton={() => setShowLess18(false)}>
          <p style={{ textWrap: 'balance' }}>
            Para comprar un boleto para un menor de edad debes comprar al menos
            1 boleto de adulto
          </p>
        </Alert>

        {showNextDate && (
          <NextDateAlert
            isShow={showNextDate}
            setShowNotFound={setShowNextDate}
            nextDates={nextDates}
            setValue={setValue}
            handleTrigger={handleTrigger}
          />
        )}

        <Alert
          type='error'
          isShow={showNotFound}
          setShow={setShowNotFound}
          title='Boletos no encontrados'
          buttonText='Reintentar'
          onClickButton={() => setShowNotFound(false)}>
          <p style={{ textAlign: 'center' }}>
            No existen pasajes que <br />
            coincidan con tu búsqueda
          </p>
        </Alert>
      </Box>
    </section>
  )
}

// #region SelectPassangersCard
const SelectPassangersCard = ({
  title = 'Adultos',
  description = '(4 años en adelante)',
  amount,
  setAmount,
}) => {
  // const [amount, setAmount] = useState(0)

  const handleAdd = () => {
    setAmount(amount + 1)
  }
  const handleMinus = () => {
    if (amount > 0) {
      setAmount(amount - 1)
    }
  }

  return (
    <section
      // className='search-bar-passenger_item_container'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '9px',
        background: '#e7e9f3',
        padding: '10px 20px',
      }}>
      <div>
        <p
          style={{
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            margin: '0',
          }}>
          {title}
        </p>
        <p
          style={{
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            margin: '0',
          }}>
          {description}
        </p>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Button
          variant='contained'
          color='primary'
          style={{
            width: '24px',
            height: '22px',
            minWidth: '24px',
            padding: '12px',
          }}
          onClick={handleMinus}>
          <span style={{ height: '24px' }}>-</span>
        </Button>

        <p
          style={{
            textAlign: 'center',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
          }}>
          {amount}
        </p>

        <Button
          variant='contained'
          color='primary'
          style={{
            width: '24px',
            height: '22px',
            minWidth: '24px',
            padding: '12px',
          }}
          onClick={handleAdd}>
          <span>+</span>
        </Button>
      </div>
    </section>
  )
}
