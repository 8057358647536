import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import { TicketIcon } from '../../icons/Ticket'
import { ButtonBlue } from '../../buttons'

import { DialogStyles, Transition } from '..'
import './styles.panel.css'

export const BuyingMethod = ({ setShow, selectedTrip, setReserveModal }) => {
  const [isCheck, setCheck] = useState('buy')
  const minW990 = useMediaQuery('(min-width:990px)')

  const navegate = useNavigate()
  const { pathname } = useLocation()

  const handleChange = (e) => {
    setCheck(e.target.value)
  }

  const goTo = () => {
    if (isCheck === 'buy') {
      navegate('/main/payment', { state: { previousPath: pathname } })
    }

    if (isCheck === 'reserve') {
      setReserveModal(true)
      setShow(false)
      // navegate('/main/reserve', { state: { previousPath: pathname } })
    }
  }

  if (minW990) {
    return (
      <DialogStyles
        open={isCheck}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShow(false)}>
        <section
          // className='panel_radio_container'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px',
            gap: '20px',
            minWidth: '400px',
            width: '100%',
          }}>
          <h2 className='title'>¿Qué deseas hacer?</h2>

          {selectedTrip.isBookingAllow && (
            <section
              className='card_radio'
              style={{ maxWidth: '500px', width: '100%' }}
              onClick={() => setCheck('reserve')}>
              <div className='icon_text_container'>
                <TicketIcon
                  width='18'
                  height='16'
                  fill='#F08B22'
                  stroke='#F08B22'
                />

                <label>Reservar</label>
              </div>

              <input
                name='pago'
                id='bum'
                type='radio'
                value='reserve'
                checked={isCheck === 'reserve'}
                onChange={handleChange}
              />
            </section>
          )}

          {selectedTrip.isPurchaseAllow && (
            <section
              className='card_radio'
              onClick={() => setCheck('buy')}
              style={{ maxWidth: '500px', width: '100%' }}>
              <div className='icon_text_container'>
                <TicketIcon
                  width='18'
                  height='16'
                  fill='#3BD4AE'
                  stroke='#3BD4AE'
                />

                <label>Comprar</label>
              </div>

              <input
                name='pago'
                id='fum'
                type='radio'
                value='buy'
                checked={isCheck === 'buy'}
                onChange={handleChange}
              />
            </section>
          )}

          <div className='center' style={{ marginTop: '10px', width: '100%' }}>
            <ButtonBlue onClick={goTo}>Continuar</ButtonBlue>
          </div>
        </section>
      </DialogStyles>
    )
  }

  return (
    <>
      <div className='overlay' onClick={() => setShow(false)}></div>

      <section
        className='panel_radio_container'
        style={{ alignItems: 'center' }}>
        <h2 className='title'>¿Qué deseas hacer?</h2>

        {selectedTrip.isBookingAllow && (
          <section
            className='card_radio'
            // style={{ maxWidth: '500px', width: '100%' }}
            onClick={() => setCheck('reserve')}>
            <div className='icon_text_container'>
              <TicketIcon
                width='18'
                height='16'
                fill='#F08B22'
                stroke='#F08B22'
              />

              <label>Reservar</label>
            </div>

            <input
              name='pago'
              id='bum'
              type='radio'
              value='reserve'
              checked={isCheck === 'reserve'}
              onChange={handleChange}
            />
          </section>
        )}

        {selectedTrip.isPurchaseAllow && (
          <section
            className='card_radio'
            onClick={() => setCheck('buy')}
            // style={{ maxWidth: '500px', width: '100%' }}
          >
            <div className='icon_text_container'>
              <TicketIcon
                width='18'
                height='16'
                fill='#3BD4AE'
                stroke='#3BD4AE'
              />

              <label>Comprar</label>
            </div>

            <input
              name='pago'
              id='fum'
              type='radio'
              value='buy'
              checked={isCheck === 'buy'}
              onChange={handleChange}
            />
          </section>
        )}

        <div className='center' style={{ marginTop: '10px', width: '100%' }}>
          <ButtonBlue onClick={goTo}>Continuar</ButtonBlue>
        </div>
      </section>
    </>
  )
}
