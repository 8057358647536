import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ArrowBack } from '../../icons/ArrowBack'
import { ButtonBlue } from '../../buttons'
import { SendPaymentAlert } from '../../bottom-panel/Alert/SendPayment'
import { busiAPi } from '../../../utils/urls'

import {
  MethodPaymentCard,
  PagoMovilData,
  GetPaymentData,
} from '../../ui/Cards/PagoMovil'

import { useStore, useAmountsStorage } from '../../../store'

import axios from 'axios'
import { useMediaQuery } from '@mui/material'
import { Capacitor } from '@capacitor/core'
import media_audio from '../../../utils/audio/merry_christmas.mp3'
import { Alert } from '../../bottom-panel/Alert'
import { ContactLine } from '../../buttons/ContactLine'

// import './styles.payment.css'

export const PagoMovil = () => {
  const { unregPassengersData, selectedTrip, ownerTravel, selectedTicket } =
    useStore()
  const { totalUsd, totalBs, completedBookedUsd, perTotalUsd } =
    useAmountsStorage()
  const minW800 = useMediaQuery('(min-width:800px)')

  const navegate = useNavigate()
  const { state } = useLocation()
  const { paymentType } = useParams()

  const isReserve = useMemo(() => paymentType === 'reserve', [paymentType])

  const [page, setPage] = useState(isReserve ? 'confirm' : 'info')

  const [show, setShow] = useState(false)
  const [showPaymentConfirn, setShowPaymentConfirn] = useState(false)

  const [disabled, setDisabled] = useState(true)
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const audio = new Audio(media_audio)

  //region INPUTS SETTINGS
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    setError,
    formState: { errors },
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const phoneWatch = watch('phone')
  // const amountWatch = watch('amount')
  const banksWatch = watch('banks')
  const ciWatch = watch('ci')
  const refNumberWatch = watch('refNumber')
  const codeWatch = watch('ciCode')
  // const dateWatch = watch('date')

  useEffect(() => {
    if (!phoneWatch) return

    if (phoneWatch?.toString()?.length > 7) {
      setError('phone', { message: 'Minimo 7' })
      // return
    }

    if (phoneWatch?.toString()?.length < 7) {
      setError('phone', { message: 'Maximo 7' })
      // return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneWatch])

  useEffect(() => {
    const isBanks = banksWatch !== undefined && banksWatch !== ''
    const isRefNumber = refNumberWatch !== undefined && refNumberWatch !== ''
    const isPhone = phoneWatch !== undefined && phoneWatch !== ''
    const isCi = ciWatch !== undefined && ciWatch !== ''
    // const isAmount = amountWatch !== undefined && amountWatch !== ''
    // const isDate = dateWatch !== undefined && dateWatch !== ''

    if (
      isBanks &&
      isRefNumber &&
      isPhone &&
      isCi
      // isAmount &&
      // Number(amountWatch) >= Number(totalBs) &&
      // isDate
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    phoneWatch,
    banksWatch,
    ciWatch,
    refNumberWatch,
    // amountWatch,
    // dateWatch,
    // totalBs,
  ])

  // region DONT ALLOW AMOUNT LESS THAN BS TOTAL
  // useEffect(() => {
  //   let timeout = undefined
  //   if (Number(amountWatch) < Number(totalBs)) {
  //     setErr('El monto no puede ser menor que el total')
  //     setError('amount', {
  //       message: 'El monto no puede ser menor que el total',
  //     })
  //     // timeout = setTimeout(() => {
  //     //   setValue('amount', totalBs)
  //     // }, 4000)
  //   } else {
  //     setErr(undefined)
  //     clearTimeout(timeout)
  //   }

  //   return () => clearTimeout(timeout)
  // }, [amountWatch, setError, setValue, totalBs])

  // region FUNCTIONS
  const goBack = () => {
    if (page === 'confirm' && isReserve) {
      navegate('/main/reserve', {
        state: { previousPath: state?.previousPath },
      })
    }

    if (page === 'info') {
      if (isReserve) {
        setPage('confirm')
      } else {
        navegate('/main/payment', {
          state: { previousPath: state?.previousPath },
        })
      }
    }

    if (page === 'verification') {
      setPage('info')
    }

    if (page === 'code') {
      setPage('verification')
    }
  }

  const handlePage = () => {
    if (page === 'confirm') {
      setPage('info')
    }
  }

  // region Submit
  const onSubmit = async (data) => {
    const completedBooked = state?.status === 'booked'

    // const phoneNumber = `${data.code.slice(1)}${data.phone}`

    // const isDetail = state.previousPath.includes('ticket/detail')
    const paymentData = {
      bankName: data.banks,
      phoneNumber: `58-${data.code.slice(1)}-${data.phone}`,
      documentId: `${data.ciCode}-${data.ci}`,
      referenceNumber: data.refNumber?.toString(),
      amount: completedBooked
        ? completedBookedUsd
        : isReserve
        ? perTotalUsd
        : totalUsd,
      reportedAmount: 0,
      // reportedAmount: data.amount,
      paymentDate: selectedTrip.dateServer,
      // paymentDate: data.date.format('MM-DD-YYYY'),
      type: 'mobile',
    }

    const passengers = completedBooked
      ? selectedTicket.passengers
      : unregPassengersData

    const fullBody = {
      routeId: completedBooked ? selectedTicket.routeId : selectedTrip.routeId,
      subRouteId: completedBooked
        ? selectedTicket.subRouteId
        : selectedTrip.subRouteId,
      isPassengerTraveling: completedBooked
        ? selectedTicket.isOwnerTraveling
        : ownerTravel,
      unregisterPassengers: passengers,
      paymentData,
      date: completedBooked
        ? selectedTicket.dateByDay.format('MM-DD-YYYY')
        : // : data.date.format('MM-DD-YYYY'),
          selectedTrip.dateServer,
      currency: 'Bs',
      isBooking: isReserve,
      price: selectedTrip?.priceOptions?.ticketPrice,
      paidAmount: selectedTrip?.priceOptions?.paidAmount,
      platform: Capacitor.getPlatform(),
    }

    const fullBodyBooked = {
      ticketId: selectedTicket.id,
      paymentData,
      currency: 'Bs',
      platform: Capacitor.getPlatform(),
    }

    try {
      setLoading(true)
      const { data: info } = await axios.post(
        completedBooked
          ? `${busiAPi}/ticket/complete-purchase`
          : `${busiAPi}/ticket`,
        completedBooked ? { ...fullBodyBooked } : { ...fullBody },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      // if (
      //   info.data.code === 1202 &&
      //   info.data.status === 'Pago no encontrado' &&
      //   info.success
      // ) {
      //   setLoading(false)
      //   setShowPaymentConfirn(true)
      //   return
      // }

      if (info.success) {
        setLoading(false)
        audio.play()
        setShow(true)
      } else {
        setLoading(false)
        setErr('Tenemos un problema, intenta más tarde')
      }
    } catch (error) {
      setLoading(false)
      const errMain = error?.response?.data?.error
      console.log({ errMain })
      if (errMain?.errorCode === 1103) {
        setErr(errMain?.message)
        return
      }
      setErr('Tenemos un problema, intenta más tarde')
    }
  }

  // region HTML MOBILE
  return (
    <section
      className='payment_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',

              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }
      }>
      {page !== 'result' && (
        <div
          onClick={goBack}
          className='payment-header_container'
          style={{ maxWidth: '600px', width: '100%' }}>
          <ArrowBack />

          <h2>
            {page === 'choose'
              ? 'Escoger método de pago'
              : page === 'info'
              ? 'Datos para el pago'
              : 'Datos para verificación'}
          </h2>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: 'calc(100% - 53px)',
          maxWidth: '600px',
          width: '100%',
        }}>
        <section
          className='payment-body_container'
          style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <MethodPaymentCard />
          {page === 'confirm' ? (
            <PagoMovilData page={isReserve ? 'reserve' : 'payment'} />
          ) : (
            page === 'info' && (
              <>
                <PagoMovilData
                  isCompleteBooked={state?.status === 'booked'}
                  isReserve={isReserve}
                  setValue={setValue}
                />

                {/* <RemiderPaymentCard /> */}
                <GetPaymentData
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  codeWatch={codeWatch}
                  dataType={'mobile'}
                />

                {err && (
                  <p
                    className='error_message'
                    style={{ marginTop: '6px', marginLeft: '4px' }}>
                    {err}
                  </p>
                )}
              </>
            )
          )}
        </section>

        {page === 'info' || page === 'code' ? (
          <div className='action_button_container'>
            <ButtonBlue type='submit' disabled={loading || disabled}>
              Continuar
            </ButtonBlue>
          </div>
        ) : page === 'result' ? (
          <div
            className='action_button_container'
            style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {true && (
              <ButtonBlue design='secondary' onClick={() => setPage('code')}>
                Atrás
              </ButtonBlue>
            )}
            <ButtonBlue onClick={() => navegate('/main/ticket')}>
              Ver boleto digital
            </ButtonBlue>
          </div>
        ) : (
          <div className='action_button_container'>
            <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
          </div>
        )}
      </form>

      <SendPaymentAlert
        isShow={show}
        setShow={setShow}
        type={
          state?.status === 'booked'
            ? 'completedBooked'
            : isReserve
            ? 'reserve'
            : 'buy'
        }
        keepOpen
      />

      <Alert
        keepOpen
        isShow={showPaymentConfirn}
        setShow={setShowPaymentConfirn}
        type='warning-orage'
        title='Pago por confirmar'
        showButton={false}>
        <p style={{ textAlign: 'center' }}>
          Tu pago está pendiente por confirmar. Envianos la captura de tu pago
          por whatsapp para ayudarte.
        </p>

        <ContactLine sendHome text='Contactar a Atención al pasajero' />

        {/* <ButtonBlue onClick={() => navegate('/main/ticket')}>
          Finalizar
        </ButtonBlue> */}
      </Alert>
    </section>
  )
}
