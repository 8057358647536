import React from 'react'

import { TicketServices } from './Services'
import { TicketContainer } from './Container'
import { Info } from './Info'
import { Status } from './Status'

import { Box } from '@mui/material'

import './styles.css'
import { SeatsFill } from '../icons/Services/Seats'

const Availability = ({ count, isFullBus = false }) => {
  return (
    <Box
      // className='ticket-availability'
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#dadce8',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '8px',
        gap: '3px',
      }}>
      <SeatsFill />

      <p
        style={{
          color: '#29348f',
          fontFamily: 'Kufam',
          fontSize: '10px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          margin: '0',
        }}>
        {isFullBus
          ? 'Unidad completa'
          : count > 0
          ? `Disponibles`
          : 'No Disponibles'}{' '}
      </p>
    </Box>
  )
}

/**
 *
 * @param {object} props
 * @param {('active' | 'confirmed' | 'reserved' |  'used', 'boarded')} props.status - props to show ticket status.
 * @param {date} props.date - props to show ticket date.
 * @param {string} props.logoUrl - props to url to the image.
 * @param {string} props.startTime - props to url to the image.
 * @param {string} props.endTime - props to url to the image.
 * @param {object} props.startLocation - props to url to the image.
 * @param {object} props.startLocation - props to url to the image.
 * @param {object} props.endLocation - props to url to the image.
 * @param {Array} props.services - props to url to the image.
 * @returns {React.JSX.Element}
 */
export function Ticket({
  status = 'active',
  title = '',
  date = '',
  startTime = '',
  endTime = '',
  startLocation = { short: '', long: '' },
  endLocation = { short: '', long: '' },
  price = null,
  priceRef = null,
  availability = '',
  services = [],
  isFullBus = false,
}) {
  const isStatusBorded = status === 'boarded'
  const isStatusExpired = status === 'used'

  return (
    <TicketContainer type={isStatusBorded ? 'boarded' : 'middle'}>
      {isStatusBorded ? null : (
        <Box
          // className='ticket-header'
          style={{
            width: '100%',
            maxHeight: '25%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
          {/* <img
              className={`ticket-brand ${isStatusExpired ? 'used' : ''}`}
              src={logoUrl}
              alt='Ticket Brand'
            /> */}
          <h1
            // className={`ticket-title ${isStatusExpired ? 'used' : ''}`}
            style={{
              color: isStatusExpired ? '#898989' : '#29348f',
              fontFamily: 'Kufam',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              margin: '0',
              maxWidth: '185px',
            }}>
            {title}
          </h1>

          {price || priceRef ? (
            <section>
              <p className='ticket-price'>
                Ref {priceRef} {!isFullBus && '(p/p)'}
              </p>

              <p className='ticket-price_ref'>Bs {price}</p>
              {/* {priceRef && price >= 25 && (
                <p className='ticket-price_ref'>Ref {priceRef}</p>
              )} */}
            </section>
          ) : (
            <Status status={status} />
          )}
        </Box>
      )}

      <Box
        // className='test-borded'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Info
          date={date}
          startTime={startTime}
          endTime={endTime}
          startLocation={startLocation}
          endLocation={endLocation}
          isStatusBorded={isStatusBorded}
          isStatusExpired={isStatusExpired}
        />

        {isStatusBorded ? <Status status={status} /> : null}
      </Box>

      {isStatusBorded ? null : (
        <Box
          className={`ticket-bus-services ${status}`}
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            minWidth: '50px',
            width: '100%',
            height: 'fit-content',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'flex-end',
          }}>
          <TicketServices services={services} />

          {availability ? (
            <Availability count={availability} isFullBus={isFullBus} />
          ) : null}
        </Box>
      )}
    </TicketContainer>
  )
}
