import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { ArrowBack } from '../../components/icons/ArrowBack'
import { ButtonBlue } from '../../components/buttons'

import { MethodPaymentCard } from '../../components/ui/Cards/PagoMovil'

import { useMediaQuery } from '@mui/material'

import './styles.payment.css'

export const PaymentPage = () => {
  const minW800 = useMediaQuery('(min-width:800px)')

  const navegate = useNavigate()
  const { state } = useLocation()

  const [isCheck, setCheck] = useState('mobile')

  //region INPUTS SETTINGS

  // useEffect(() => {
  //   if (isCheck === 'mobile') {
  //     const isBanks = banksWatch !== undefined && banksWatch !== ''
  //     const isRefNumber = refNumberWatch !== undefined && refNumberWatch !== ''
  //     const isPhone = phoneWatch !== undefined && phoneWatch !== ''
  //     // const isPhoneCode = codeWatch !== undefined && codeWatch !== ''
  //     const isCi = ciWatch !== undefined && ciWatch !== ''
  //     // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

  //     if (isBanks && isRefNumber && isPhone && isCi) {
  //       setDisabled(false)
  //     } else {
  //       setDisabled(true)
  //     }
  //   }
  // }, [phoneWatch, banksWatch, ciWatch, refNumberWatch, isCheck])

  // useEffect(() => {
  //   if (isCheck === 'debito') {
  //     const isTitular = titular !== undefined && titular !== ''
  //     const isRefTarget = refTarget !== undefined && refTarget !== ''
  //     const isCvc = cvc !== undefined && cvc !== ''
  //     const isCardDate = cardDate !== undefined && cardDate !== ''
  //     const isAccount = account !== undefined && account !== ''
  //     const isPinKey = pinKey !== undefined && pinKey !== ''
  //     const isBanks = banksWatch !== undefined && banksWatch !== ''

  //     const isCi = ciWatch !== undefined && ciWatch !== ''
  //     const isPhone = phoneWatch !== undefined && phoneWatch !== ''
  //     // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

  //     if (
  //       isTitular &&
  //       isRefTarget &&
  //       isCvc &&
  //       isCardDate &&
  //       isAccount &&
  //       isPinKey &&
  //       isCi &&
  //       isBanks &&
  //       isPhone
  //     ) {
  //       setDisabled(false)
  //     } else {
  //       setDisabled(true)
  //     }
  //   }

  //   if (isCheck === 'credito') {
  //     const isTitular = titular !== undefined && titular !== ''
  //     const isRefTarget = refTarget !== undefined && refTarget !== ''
  //     const isCvc = cvc !== undefined && cvc !== ''
  //     const isCardDate = cardDate !== undefined && cardDate !== ''
  //     const isCurrency = currency !== undefined && currency !== ''
  //     const isBanks = banksWatch !== undefined && banksWatch !== ''

  //     const isCi = ciWatch !== undefined && ciWatch !== ''
  //     const isPhone = phoneWatch !== undefined && phoneWatch !== ''
  //     // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

  //     if (
  //       isTitular &&
  //       isRefTarget &&
  //       isCvc &&
  //       isCardDate &&
  //       isCurrency &&
  //       isCi &&
  //       isBanks &&
  //       isPhone
  //     ) {
  //       setDisabled(false)
  //     } else {
  //       setDisabled(true)
  //     }
  //   }
  // }, [
  //   account,
  //   cardDate,
  //   ciWatch,
  //   cvc,
  //   isCheck,
  //   phoneWatch,
  //   pinKey,
  //   refTarget,
  //   titular,
  //   currency,
  //   banksWatch,
  // ])

  // region FUNCTIONS
  const goBack = () => {
    navegate(state?.previousPath)
  }

  const handlePage = () => {
    if (isCheck === 'mobile') {
      navegate('/main/payment/pago_movil', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
    if (isCheck === 'bnc') {
      navegate('/main/payment/bnc', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
    if (isCheck === 'debito') {
      navegate('/main/payment/debito', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
    if (isCheck === 'credito') {
      navegate('/main/payment/credito', {
        state: { previousPath: state?.previousPath, status: state?.status },
      })
    }
  }

  return (
    <section
      className='payment_container'
      style={
        minW800
          ? {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',

              height: 'calc(100% - 134px)',
              minHeight: '600px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
            }
      }>
      <div
        onClick={goBack}
        className='payment-header_container'
        style={{ maxWidth: '600px', width: '100%' }}>
        <ArrowBack />

        <h2>Escoger método de pago</h2>
      </div>

      <form
        style={{
          height: 'calc(100% - 53px)',
          maxWidth: '600px',
          width: '100%',
        }}>
        <section
          className='payment-body_container'
          style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <>
            <MethodPaymentCard
              type='input'
              title='Pago móvil'
              id='mobile'
              isCheck={isCheck}
              setCheck={setCheck}
            />

            {/* <MethodPaymentCard
                type='input'
                title='Pago Movil a BNC'
                id='bnc'
                isCheck={isCheck}
                setCheck={setCheck}
              /> */}

            <MethodPaymentCard
              type='input'
              title='Tarjeta de Débito'
              id='debito'
              isCheck={isCheck}
              setCheck={setCheck}
            />

            <MethodPaymentCard
              type='input'
              title='Tarjeta de Crédito'
              id='credito'
              isCheck={isCheck}
              setCheck={setCheck}
            />
          </>
        </section>

        <div className='action_button_container'>
          <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
        </div>
      </form>

      {/* <SendPaymentAlert
        isShow={show}
        setShow={setShow}
        type={state?.status === 'booked' ? 'completedBooked' : 'buy'}
        keepOpen
      /> */}

      {/* <Alert
        isShow={showPaymentConfirn}
        setShow={setShowPaymentConfirn}
        type='warning'
        title='Confirmación de pago'
        buttonText='Pagar'
        onClickButton={() => {
          setPage('code')
          setShowPaymentConfirn(false)
        }}>
        <p style={{ textAlign: 'center' }}>
          La transacción tiene un costo de <b>0,30% adicional</b> sobre la
          transacción, pagando un total de <b>Bs. 1234,60</b>
        </p>

        <CheckboxUI label='¿Acepta el costo de la operación?' />
      </Alert> */}
    </section>
  )
}
